import {
  Notification as KendoNotification,
  NotificationGroup as KendoNotificationGroup
} from "@progress/kendo-react-notification";

import { Fade } from "@progress/kendo-react-animation";
const SuccessNotifications = ({
  success,
  hideSuccessNotification,
}: {
  success: string;
  hideSuccessNotification: () => void;
}) => {
  return (
    <KendoNotificationGroup className="kendo-notification-group">
      <Fade enter={true} exit={true}>
        <KendoNotification
          type={{ style: "success", icon: true }}
          closable={true}
          onClose={hideSuccessNotification}
        >
          <p>{success}</p>
        </KendoNotification>
      </Fade>
    </KendoNotificationGroup>
  );
};

export default SuccessNotifications;
