import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Expander,
  Flexbox,
  SelectionCheckbox,
  Sizes,
  TBody,
  TD as TData,
  THead,
  TR as TRow,
  Table,
  TH as Thead
} from "@sede-x/shell-ds-react-framework";
import {
  ChevronDown,
  ChevronRight
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  CellContext,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import CarbonIQEmptyTable from "carbonIQ/emptyTable";
import GlobalHeader from "global/sections/header";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import LoadingPanel from "../../../shared/components/LoadingPanel";
import "./subscriptions.css";
export interface INotificationSubscription {
  notifierId: number;
  serviceName: string;
  entityName: string;
  eventType: string;
  description: string;
  subscriptionId: number;
  subscribed: boolean;
  subscribedDate: string;
  version: number;
}
const GET_SUBSCRIPTIONS = loader("../graphql/query-notification-subscriptions.graphql");

const UPDATE_SUBSCRIPTIONS = loader("../graphql/mutation-update-subscriptions.graphql");
interface SubscriptionData {
  subscriptions: INotificationSubscription[];
}
interface ITreeViewDataItem {
  serviceName: string;
  entityName: string;
  eventType: string;
  description: string;
  subscribedDate: string;
  id: string;
}

const buildTreeData = (data: INotificationSubscription[] | undefined) => {
  return data
    ?.map((elm: INotificationSubscription) => {
      return {
        serviceName: elm.serviceName,
        entityName: elm.entityName,
        eventType: elm.eventType,
        description: elm.description,
        subscribedDate: elm.subscribedDate,
        id: String(elm.notifierId)
      };
    })
    .sort((a: ITreeViewDataItem, b: ITreeViewDataItem) => {
      // replaced ternary operation with if-else as suggested by sonarQube
      if (a.serviceName.toLowerCase() > b.serviceName.toLowerCase()) {
        return 1;
      } else if (a.serviceName.toLowerCase() < b.serviceName.toLowerCase()) {
        return -1;
      } else {
        return 0;
      }
    });
};

const gridColumns: ColumnDef<ITreeViewDataItem>[] = [
  {
    header: "Event",
    accessorKey: "eventType"
  },
  {
    header: "Description",
    accessorKey: "description"
  },
  {
    header: "Last Subscribed At",
    accessorKey: "subscribedDate"
  }
];

const gridColumnsGenerate = (
  clickcallback: (arg: ITreeViewDataItem[]) => void,
  allSelectedKeys: ITreeViewDataItem[] | undefined
) => {
  const additionalGridColumn = {
    header: "Entity",
    accessorKey: "entityName",
    cell: (args: CellContext<ITreeViewDataItem, unknown>) => (
      <Expander row={args.row}>
        <SelectionCheckbox
          className="individual-checkbox"
          checked={allSelectedKeys
            ?.map(e => e.id)
            .includes(String(args.row.original.id as number | string))}
          onChange={event => {
            let tempSelectedKeys = Array.from(allSelectedKeys ?? []);
            if (event.target.checked) {
              tempSelectedKeys?.push(args.row.original as ITreeViewDataItem);
            } else {
              tempSelectedKeys =
                allSelectedKeys?.filter(
                  item => item.id !== (args.row.original.id as number | string)
                ) ?? [];
            }
            clickcallback(tempSelectedKeys);
          }}
        />
        {args.getValue() as string}
      </Expander>
    )
  };

  return [additionalGridColumn, ...gridColumns];
};

const Subscription = () => {
  const { loading, data: subscriptionData } = useQuery<SubscriptionData>(GET_SUBSCRIPTIONS, {
    fetchPolicy: "no-cache"
  });
  const [updateSubscriptions, { loading: updateLoading }] = useMutation(UPDATE_SUBSCRIPTIONS);

  const [treeData, setTreeData] = useState<ITreeViewDataItem[]>();
  const [allSelectedKeys, setAllSelectedKeys] = useState<ITreeViewDataItem[] | undefined>([]);

  useEffect(() => {
    setTreeData(buildTreeData(subscriptionData?.subscriptions));
    setAllSelectedKeys(
      buildTreeData(
        subscriptionData?.subscriptions.filter(
          (item: INotificationSubscription) => !!item.subscribed
        ) || []
      )
    );
  }, [subscriptionData]);

  const onSave = () => {
    const subscriptions = treeData?.map((elm: ITreeViewDataItem) => ({
      notifierId: parseInt(elm.id, 10),
      subscribe: allSelectedKeys?.map(e => e.id).includes(elm.id)
    }));
    updateSubscriptions({ variables: { subscriptions } });
  };

  const columns = gridColumnsGenerate(arg => {
    setAllSelectedKeys(arg);
  }, allSelectedKeys);

  const table = useReactTable({
    data: treeData || [],
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const setSiblingsToggle = (toggleElementClassName: string) => {
    [...document.getElementsByClassName(toggleElementClassName)].forEach(function (e) {
      e.classList.toggle("hidden");
    });
  };

  return (
    <>
      <GlobalHeader
        pageName="Managed Notifications"
        buttonContent={[
          <Button onClick={onSave} size={Sizes.Small} key={1}>
            Save
          </Button>
        ]}
      />

      {subscriptionData ? (
        <div>
          <Table className="table-wrapper" style={{ width: "100%" }}>
            <THead>
              {table.getHeaderGroups().map(headerGroup => (
                <TRow key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <Thead
                      key={header.id}
                      colSpan={header.colSpan}
                      className={index === 0 ? "first-th-elm" : ""}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </Thead>
                  ))}
                </TRow>
              ))}
            </THead>
            <TBody>
              {(() => {
                let startCutoff = 0;
                return [
                  ...new Set(
                    treeData?.map((e: ITreeViewDataItem) => e.serviceName.toLowerCase())
                  )
                ].map((element: string, headIndex: number) => (
                  <>
                    <TRow
                      className={"sub-heading-row"}
                      onClick={() => {
                        setSiblingsToggle("groupRow-" + headIndex);
                      }}>
                      <TData colSpan={columns.length}>
                        <Flexbox alignItems="center">
                          <ChevronRight className="chevron-right" />
                          <ChevronDown className="chevron-down" />
                          <SelectionCheckbox
                            className="parent-checkbox"
                            checked={
                              treeData?.filter(
                                (elm: ITreeViewDataItem) =>
                                  elm?.serviceName?.toLowerCase() === element &&
                                  allSelectedKeys?.map(e => e.id).includes(elm.id)
                              ).length ===
                              treeData?.filter(
                                (elm: ITreeViewDataItem) =>
                                  elm?.serviceName?.toLowerCase() === element
                              ).length
                            }
                            indeterminate={
                              treeData &&
                              treeData?.filter(
                                (elm: ITreeViewDataItem) =>
                                  elm?.serviceName?.toLowerCase() === element &&
                                  allSelectedKeys?.map(e => e.id).includes(elm.id)
                              ).length > 0 &&
                              treeData?.filter(
                                (elm: ITreeViewDataItem) =>
                                  elm?.serviceName?.toLowerCase() === element &&
                                  allSelectedKeys?.map(e => e.id).includes(elm.id)
                              ).length <
                                treeData?.filter(
                                  (elm: ITreeViewDataItem) =>
                                    elm?.serviceName?.toLowerCase() === element
                                ).length
                            }
                            onChange={event => {
                              let tempSelectedKeys: ITreeViewDataItem[] =
                                allSelectedKeys?.filter(
                                  (elm: ITreeViewDataItem) =>
                                    elm?.serviceName?.toLowerCase() !== element
                                ) || [];

                              if (event.target.checked) {
                                tempSelectedKeys = tempSelectedKeys.concat(
                                  treeData?.filter(
                                    (elm: ITreeViewDataItem) =>
                                      elm?.serviceName?.toLowerCase() === element
                                  ) || []
                                );
                              }

                              setAllSelectedKeys(tempSelectedKeys);
                            }}
                          />
                          <strong>{element.toUpperCase()}</strong>
                        </Flexbox>
                      </TData>
                    </TRow>
                    {(() => {
                      const endCutoff =
                        treeData?.filter(
                          (elm: ITreeViewDataItem) =>
                            elm?.serviceName?.toLowerCase() === element
                        ).length || 0;

                      const tempReturnValue = table
                        .getRowModel()
                        .rows.slice(startCutoff, startCutoff + endCutoff)
                        .map(row => (
                          <TRow
                            key={row.id}
                            data-ref={element}
                            className={"groupRow-" + headIndex}>
                            {row.getVisibleCells().map(cell => (
                              <TData key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </TData>
                            ))}
                          </TRow>
                        ));
                      startCutoff = startCutoff + endCutoff;
                      return tempReturnValue;
                    })()}
                  </>
                ));
              })()}
            </TBody>
          </Table>
        </div>
      ) : (
        <CarbonIQEmptyTable />
      )}

      {loading || (updateLoading && <LoadingPanel />)}
    </>
  );
};
export default Subscription;
