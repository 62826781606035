import { createContext, useContext, useLayoutEffect, useMemo, useState } from "react";
type ThemeContextProps = {
  theme?: string;
  toggleTheme?: () => void;
};
const ThemeContext = createContext<ThemeContextProps>({});
const ThemeProvider = ({ children }: { children: any }) => {
  const initialTheme = () => localStorage.getItem("MACK_USER_THEME") || "dark";
  const [theme, setTheme] = useState(initialTheme);
  const toggleTheme = () => setTheme(currTheme => (currTheme === "light" ? "dark" : "light"));

  useLayoutEffect(() => {
    localStorage.setItem("MACK_USER_THEME", theme);
    if (theme === "light") {
      document.documentElement.classList.remove("dark-mode");
      document.documentElement.classList.add("light-mode");
    } else {
      document.documentElement.classList.remove("light-mode");
      document.documentElement.classList.add("dark-mode");
    }
  }, [theme]);

  const themeProviderValue = useMemo(() => ({ theme, toggleTheme }), [theme]);

  return <ThemeContext.Provider value={themeProviderValue}>{children}</ThemeContext.Provider>;
};
const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
export { ThemeContext, ThemeProvider, useTheme };
