import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useState } from "react";
import { GqlResponse, TMovementDeliveryEvent } from "ticketing/ticketing.types";
import RevisionsGridWrapper from "./RevisionsGridWrapper";

type Response = GqlResponse<
  TMovementDeliveryEvent[],
  "deliveryEventsOfMovementAtRevision"
>;
const GET_DELIVERY_EVENTS_REVISIONS_OF_MOVEMENT = loader(
  "../../ticketing-graphql/deliveryEventsOfMovementAtRevision.graphql"
);

const transformData = (deliveryEvents: TMovementDeliveryEvent[]) => {
  return deliveryEvents?.map((d) => ({
    ...d,
    deliveryEventDate: new Date(d.deliveryEventDate),
  }));
};

type RevisionsMovementDeliveryEventsProps = {
  movementId: string;
  revisionNumber: string;
};

const columns = [
  { title: "Event", field: "deliveryEventType.name", width: "140px", key: 1 },
  {
    title: "Event Date",
    field: "deliveryEventDate",
    format: "{0:MM/dd/yy}",
    width: "200px",
    key: 2
  },
  { title: "Status", field: "deliveryEventStatus", width: "140px", key: 3 },
];

/**
 *
 * @param param0
 * @returns
 */
const RevisionsMovementDeliveryEvents = ({
  movementId,
  revisionNumber,
}: RevisionsMovementDeliveryEventsProps) => {
  const { loading, error } = useQuery<Response>(
    GET_DELIVERY_EVENTS_REVISIONS_OF_MOVEMENT,
    {
      fetchPolicy: "no-cache",
      variables: { movementId, revisionNumber },
      onCompleted: (data) =>
        setDeliveryEvents(
          transformData(data.deliveryEventsOfMovementAtRevision)
        ),
    }
  );

  const [deliveryEvents, setDeliveryEvents] = useState<TMovementDeliveryEvent[]>();


  return (
    <RevisionsGridWrapper
      loading={loading}
      error={error}
      dataContent={deliveryEvents}
      columns={columns}
    />
  );
};

export default RevisionsMovementDeliveryEvents;
