import { useMutation } from "@apollo/client";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import {
  Drawer,
  FormField,
  Heading,
  Loading,
  Select,
  Sentiments,
  Sizes,
  TextInput,
  TextTypes,
  Toggle,
  Tree,
  TreeNode,
  Variants
} from "@sede-x/shell-ds-react-framework";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import { IMACkRoleView, IMACkUser } from "../../../auth";
import { TUserLegalEntity } from "../types";
import "../user.css";

const ADD_USER = loader("../graphql/mutation-add-user.graphql");
const UPDATE_USER = loader("../graphql/mutation-update-user.graphql");
const FETCH_POLICY_NO_CACHE = { fetchPolicy: "no-cache" as MutationFetchPolicy };

interface TreeViewDataItem {
  key: string;
  title: string;
  isChecked: boolean;
}
interface IAddUpdateUserResultData {
  user: IMACkUser;
}

interface IInitialValues extends Partial<IMACkUser> {
  active?: boolean;
  defaultLegalEntity?: TUserLegalEntity;
}

const UserEdit = (props: {
  user: IMACkUser;
  roles: IMACkRoleView[];
  legalEntities: TUserLegalEntity[];
  onEditDone: (done: IMACkUser | null) => void;
  onClose: () => void;
}) => {
  const [addUser, { loading: aLoading, error: aError }] = useMutation<IAddUpdateUserResultData>(
    ADD_USER,
    {
      ...FETCH_POLICY_NO_CACHE,
      onCompleted: data => props.onEditDone(data.user)
    }
  );

  const [updateUser, { loading: uLoading, error: uError }] =
    useMutation<IAddUpdateUserResultData>(UPDATE_USER, {
      ...FETCH_POLICY_NO_CACHE,
      onCompleted: data => props.onEditDone(data.user)
    });

  const [treeState, setTreeState] = useState<TreeViewDataItem[]>();
  const [checkedRoles, setCheckedRoles] = useState<string[]>();

  useEffect(() => {
    if (props.roles) {
      setTreeState(
        props?.roles?.map(p => ({
          key: String(p.id),
          title: p.name,
          isChecked: !!props.user?.roles?.some(r => String(r.id) === String(p.id))
        }))
      );
      setCheckedRoles(props.user?.roles?.map(r => `${r.id}`) ?? []);
    }
  }, [props.roles, props.user?.roles]);

  const [initialValues, setInitialValues] = useState<IInitialValues>({
    id: props.user.id,
    firstName: props.user.firstName,
    middleName: props.user.middleName ?? "",
    lastName: props.user.lastName,
    mobile: props.user.mobile ?? "",
    email: props.user.email,
    active: props.user.status === "ACTIVE",
    defaultLegalEntityId: props.user.defaultLegalEntityId,
    defaultLegalEntity: props.legalEntities.find(e => e.id === props.user.defaultLegalEntityId)
  });

  const onSubmitClick = () => {
    const user = {
      ...initialValues,
      id: initialValues.id?.trim(),
      firstName: initialValues.firstName?.trim(),
      middleName: initialValues.middleName?.trim(),
      lastName: initialValues.lastName?.trim(),
      mobile: initialValues.mobile?.trim(),
      email: initialValues.email?.trim(),
      defaultLegalEntityId: initialValues.defaultLegalEntity?.id,
      roles: checkedRoles?.map(r => parseInt(r, 10)),
      status: initialValues.active ? "ACTIVE" : "DISABLED",
      active: initialValues.active
    };
    delete user.active;
    delete user.defaultLegalEntity;

    if (!props.user.id) {
      addUser({
        variables: {
          user
        },
        onCompleted: data => props.onEditDone(data.user)
      });
    } else {
      updateUser({
        variables: {
          user: {
            ...user,
            version: props.user.version
          }
        },
        onCompleted: data => props.onEditDone(data.user)
      });
    }
  };

  const onCheckChanged = (event: TreeNode) => {
    let tempData: string[] = checkedRoles?.filter(item => event.key !== item) || [];
    tempData = event.isChecked ? tempData.concat(event.key as string) : tempData;
    setCheckedRoles(tempData);
  };
  const [open, setOpen] = useState(true);

  const handleOnClose = () => {
    props.onClose();
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const isLoading = [loading, aLoading, uLoading].some(elm => elm);

  return (
    <Drawer
      header={
        <Heading className="setting-form-heading" type={TextTypes.H2}>
          Edit User
        </Heading>
      }
      borders={false}
      closeButton={false}
      sticky
      mask={true}
      open={open}
      size={Sizes.Medium}
      onClose={() => {
        handleOnClose();
      }}
      actions={[
        {
          label: "Cancel",
          action: () => {
            handleOnClose();
          },
          props: {
            variant: Variants.Outlined
          }
        },
        {
          label: "Save",
          action: () => {
            setLoading(true);
            onSubmitClick();
          }
        }
      ]}>
      <CommonErrorComponent error={[aError, uError]} />

      {isLoading && (
        <div className="loading-wrapper">
          <Loading />
        </div>
      )}
      <form className="setting-form-container">
        <FormField
          size={"small"}
          id="user-id-label"
          mandatory={true}
          label="User Id"
          bottomLeftHelper={{
            content: !initialValues?.id ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            size={Sizes.Small}
            disabled={!!props.user.id}
            readOnly={!!props.user.id}
            onChange={event => {
              if (!props.user.id) {
                setInitialValues({
                  ...initialValues,
                  id: event.target.value.trim()
                });
              }
            }}
            value={initialValues?.id ?? ""}
            invalid={!initialValues?.id}
          />
        </FormField>

        <FormField
          size={"small"}
          id="firstName-label"
          mandatory={true}
          label="First Name"
          bottomLeftHelper={{
            content: !initialValues?.firstName ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            size={Sizes.Small}
            onChange={event => {
              setInitialValues({
                ...initialValues,
                firstName: event.target.value
              });
            }}
            value={initialValues?.firstName ?? ""}
            invalid={!initialValues?.firstName}
          />
        </FormField>

        <FormField size={"small"} id="middleName-label" label="Middle Name">
          <TextInput
            size={Sizes.Small}
            onChange={event => {
              setInitialValues({
                ...initialValues,
                middleName: event.target.value
              });
            }}
            value={initialValues?.middleName ?? ""}
          />
        </FormField>

        <FormField
          size={"small"}
          id="lastName-label"
          mandatory={true}
          label="Last Name"
          bottomLeftHelper={{
            content: !initialValues?.lastName ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            size={Sizes.Small}
            onChange={event => {
              setInitialValues({
                ...initialValues,
                lastName: event.target.value
              });
            }}
            value={initialValues?.lastName ?? ""}
            invalid={!initialValues?.lastName}
          />
        </FormField>

        <FormField
          size={"small"}
          id="email-label"
          mandatory={true}
          label="Email"
          bottomLeftHelper={{
            content: !initialValues?.email ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            size={Sizes.Small}
            onChange={event => {
              setInitialValues({
                ...initialValues,
                email: event.target.value.trim()
              });
            }}
            value={initialValues?.email ?? ""}
            invalid={!initialValues?.email}
          />
        </FormField>

        <FormField size={"small"} id="mobile-label" label="Phone">
          <TextInput
            size={Sizes.Small}
            onChange={event => {
              setInitialValues({
                ...initialValues,
                mobile: event.target.value.trim()
              });
            }}
            value={initialValues?.mobile ?? ""}
          />
        </FormField>

        <FormField size={"small"} id="legal-entity-label" label="Legal Entity">
          <Select
            options={props.legalEntities?.map((elm: TUserLegalEntity) => ({
              key: elm.id,
              label: elm.name,
              value: elm.name
            }))}
            size={"small"}
            id="legal-entity-input"
            placeholder="Select a legal entity"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            labelInValue={true}
            value={initialValues?.defaultLegalEntity?.name ?? undefined}
            onChange={data => {
              setInitialValues({
                ...initialValues,
                defaultLegalEntity: !data ? data : { id: data.key, name: data.value }
              });
            }}
          />
        </FormField>

        <FormField size={"medium"} id="role-status-label" label="Active">
          <Toggle
            size={Sizes.Large}
            checked={initialValues?.active}
            onChange={() => {
              setInitialValues({
                ...initialValues,
                active: !initialValues?.active
              });
            }}
          />
        </FormField>

        <FormField size={"medium"} id="role-privilleges-label" label="Roles">
          <div className="hidden-scrollbar">
            <Tree
              treeData={treeState || []}
              isCheckable
              size={Sizes.Small}
              onCheck={(event: TreeNode) => {
                onCheckChanged(event);
              }}
            />
          </div>
        </FormField>
      </form>
    </Drawer>
  );
};
export default UserEdit;
