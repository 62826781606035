import { ApolloError } from "@apollo/client";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import {
  GridColumn as Column,
  Grid,
  GridColumnProps,
  GridNoRecords,
  GridPageChangeEvent
} from "@progress/kendo-react-grid";
import { useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import LoadingPanel from "../../../shared/components/LoadingPanel";

// type TRevisionGridColumn = GridColumnProps & {
//   key: string
// }

type RevisionsGridWrapperProps = {
  loading?: boolean;
  error?: ApolloError;
  dataContent?: any;
  columns?: GridColumnProps[];
};

interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 10 };

const RevisionsGridWrapper = (props: RevisionsGridWrapperProps) => {
  const [page, setPage] = useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState<number | string | undefined>();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.syntheticEvent as PagerTargetEvent;
    const take = event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }

    setPage({
      ...event.page,
      take
    });
  };
  return (
    <div className={"content-wrapper"} style={{ height: "100%" }}>
      <div className={"grid-wrapper"} style={{ height: "100%" }}>
        <div className={"card-container"} style={{ height: "100%" }}>
          {props?.loading && <LoadingPanel />}
          {props?.error && <ApolloErrorViewer error={props?.error} />}
          <Grid
            style={{ height: "100%" }}
            data={props?.dataContent?.slice(page.skip, page.take + page.skip)}
            resizable
            skip={page.skip}
            take={page.take}
            total={props?.dataContent?.length}
            pageable={{
              buttonCount: 4,
              pageSizes: [5, 10, 25, 50],
              pageSizeValue
            }}
            onPageChange={pageChange}
            dataItemKey="revisionNumber">
            <GridNoRecords>
              <div style={{ color: "gray" }}>There is no data available</div>
            </GridNoRecords>
            {props.columns?.map(item => {
              return <Column {...item} />;
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default RevisionsGridWrapper;
