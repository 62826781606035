import { useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import { INotification } from "../models";

import {
  Notification as KendoNotification,
  NotificationEvent as KendoNotificationEvent,
  NotificationGroup as KendoNotificationGroup,
} from "@progress/kendo-react-notification";
import { loader } from 'graphql.macro';
import { Fade } from "@progress/kendo-react-animation";

const NEW_NOTIFICATIONS_SUBSCRIPTION = loader("../graphql/subscription-new-notifications.graphql");

interface NotificationData {
  onNewNotification: INotification;
}
const MAX_NOTIFICATION_DISPLAY_TIME_MS = 10_000;
const PushNotification = () => {
  const { data: sNotification, error: sError } = useSubscription<NotificationData>(NEW_NOTIFICATIONS_SUBSCRIPTION);

  const [notifications, setNotifications] = useState<INotification[]>([]);

  useEffect(() => {
    if (!sError && sNotification?.onNewNotification) {
      const notification = sNotification.onNewNotification;
      setNotifications((ns) => ns.concat(notification));
      setTimeout(() =>
        setNotifications((ns) => ns.filter((n) => n.id !== notification.id)),
        MAX_NOTIFICATION_DISPLAY_TIME_MS
      );
    }
  }, [sError, sNotification]);

  const onCloseNotification = (m: INotification) => {
    setNotifications((ns) => ns.filter((n) => n.id !== m.id));
  };

  return (
    <KendoNotificationGroup
      style={{
        right: 0,
        bottom: 0,
        alignItems: "flex-start",
        flexWrap: "wrap-reverse",
      }}
    >
      {notifications.map((m: INotification) => (
        <Fade enter={true} exit={true} key={m.id}>
          <KendoNotification
            type={{ style: "info", icon: true }}
            closable={true}
            onClose={(_e: KendoNotificationEvent) => {
              onCloseNotification(m);
            }}
          >
            <p key={m.id}>{m.title}</p>
          </KendoNotification>
        </Fade>
      ))}
    </KendoNotificationGroup>
  );
};

export default PushNotification;
