import { useQuery } from "@apollo/client";
import {
  Badge,
  BaseTable,
  Button,
  Loading,
  Sentiments,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  AddSquare,
  CheckCircleSolid,
  CrossCircle,
  CrossCircleSolid,
  EditOutlined,
  Search
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  ColumnDef,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { MackPrivilege } from "auth";
import { useMackAuth } from "auth/AuthenticationProvider";
import { ROWS_PER_PAGE } from "carbonIQ/CarbonIQConstants";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import ColumnText from "global/elements/columnText";
import GlobalHeader from "global/sections/header";
import { loader } from "graphql.macro";
import { useState } from "react";
import { LongQueryOperatorInput } from "ticketing/ticketing.types";
import { toEqOrInQueryOperator } from "ticketing/utils";
import { GqlResponse } from "types";
import { CIQOption, TProductsFetchData, TProductsFilterData } from "../carbonIQtypes";
import CarbonIQEmptyTable from "../emptyTable";
import ProductSearch from "./ProductSearch";
import AddOrUpdateProducts from "./addOrUpdateProducts";

const carbonProductsFilterBy = loader("../graphql/query-CarbonProductsFilterBy.graphql");
const FETCH_POLICY_NO_CACHE = "no-cache";

type TProductsFilterByResponse = GqlResponse<TProductsFetchData[], "ciProductsFilterBy">;

const gridColumns: ColumnDef<TProductsFetchData>[] = [
  {
    header: "GSAP Code",
    accessorKey: "gsapCode",
    enableSorting: true,
    size: 300,
    cell: arg => <ColumnText width={"300px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "GSAP Name",
    accessorKey: "gsapName",
    enableSorting: true,
    size: 300,
    cell: arg => <ColumnText width={"300px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Generic Product Name",
    accessorKey: "genericProduct.name",
    enableSorting: true,
    size: 200,
    cell: arg => <ColumnText width={"200px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Active",
    accessorKey: "active",
    enableSorting: true,
    size: 300,
    cell: arg => (
      <p style={{ width: "100px" }}>
        {(arg.getValue() as boolean) ? (
          <CheckCircleSolid fill={"var(--forest-500)"} />
        ) : (
          <CrossCircleSolid fill={"var(--red-500)"} />
        )}
      </p>
    )
  }
];

const gridColumnsGenerate = (
  clickcallback: (arg: {
    row: {
      original: Partial<TProductsFetchData>;
    };
  }) => void,
  editSelected: number | undefined,
  hasEditPermission: boolean | undefined = false
) => {
  if (!hasEditPermission) {
    return gridColumns;
  } else {
    const additionalGridColumn = {
      header: "",
      accessorKey: "components",
      cell: (arg: { row: { original: Partial<TProductsFetchData> } }) => (
        <Button
          className={arg.row.original.id === editSelected ? "editedRow" : ""}
          title="Edit"
          size={Sizes.Small}
          onClick={() => clickcallback(arg)}>
          <EditOutlined />
          Edit
        </Button>
      ),
      size: 40,
      enableSorting: false
    };
    return [...gridColumns, additionalGridColumn];
  }
};

const CarbonIQProducts = () => {
  const { mackUser } = useMackAuth();

  const hasAddProductsPrivilege = mackUser?.hasPrivilege(MackPrivilege.AddCIProduct);

  const hasModifyProductsPrivilege = mackUser?.hasPrivilege(MackPrivilege.ModifyCIProduct);
  const [loading] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [selectedProductSearchFilter, setSelectedProductSearchFilter] =
    useState<TProductsFilterData>();

  const [masterDataList, setMasterDataList] = useState<TProductsFetchData[]>([]);

  const [editSelected, setEditSelected] = useState<number>();

  const filterCIQProduct = (data: TProductsFilterData) => {
    const tempfilter: {
      gsapCode?: LongQueryOperatorInput;
      genericProductId?: LongQueryOperatorInput;
      active?: { eq: boolean };
    } = {};
    if (data?.selectedGsap && data?.selectedGsap?.length > 0) {
      tempfilter["gsapCode"] = toEqOrInQueryOperator<string, LongQueryOperatorInput>(
        data?.selectedGsap?.map(elm => elm.value as string)
      );
    }

    if (data?.selectedGenericPRoduct && data?.selectedGenericPRoduct?.length > 0) {
      tempfilter["genericProductId"] = toEqOrInQueryOperator<string, LongQueryOperatorInput>(
        data.selectedGenericPRoduct.map(elm => elm.key as string)
      );
    }

    tempfilter["active"] = { eq: data?.selectedActive ?? true };

    return Object.keys(tempfilter).length ? tempfilter : null;
  };

  const applyCustomFilter = (data: TProductsFilterData) => {
    const tempFilterData = filterCIQProduct(data);
    refetch({
      filter: tempFilterData
    });
  };

  //Fetching Carbon IQ  Product Blend data
  const {
    loading: lCIQDocument,
    error: eCIQDocument,
    data: filterDataList,
    refetch
  } = useQuery<TProductsFilterByResponse>(carbonProductsFilterBy, {
    fetchPolicy: FETCH_POLICY_NO_CACHE,
    onCompleted: data => {
      if (masterDataList.length <= 0) {
        setMasterDataList(data.ciProductsFilterBy);
      }
      setPagination({
        pageIndex: 0,
        pageSize: ROWS_PER_PAGE
      });
    }
  });

  const [addOrUpdateOpen, setAddOrUpdateOpen] = useState(false);
  const [updateOpenProductDetails, setUpdateOpenProductDetails] = useState<TProductsFetchData>({
    active: true
  });

  const columns = gridColumnsGenerate(
    arg => {
      setEditSelected(arg.row.original.id as number);
      setUpdateOpenProductDetails(arg.row.original as TProductsFetchData);
      setAddOrUpdateOpen(true as boolean);
    },
    editSelected,
    hasModifyProductsPrivilege
  );

  const [sorting, setSorting] = useState<SortingState>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false
  };

  const handlAddOrUpdateSubmit = () => {
    refetch();
    setAddOrUpdateOpen(false);
  };

  const handleAddNewProductClick = () => {
    setUpdateOpenProductDetails({ active: true });
    setAddOrUpdateOpen(true);
  };

  const isLoading = [loading, lCIQDocument].some(elm => elm);

  const handleSearchSubmit = (data: TProductsFilterData) => {
    setSelectedProductSearchFilter(data);
    applyCustomFilter(data);
  };

  const handleSearchReset = () => {
    setSelectedProductSearchFilter(null);
    refetch({
      filter: null
    });
  };

  return (
    <>
      <CommonErrorComponent error={eCIQDocument} />
      {isLoading && (
        <div className="loading-wrapper">
          <Loading />
        </div>
      )}

      <GlobalHeader
        pageName="Products"
        filterButtonContent={[
          !!selectedProductSearchFilter && (
            <span className="customer-Search-current-filter">
              {!!selectedProductSearchFilter?.selectedGsap &&
                Object.keys(selectedProductSearchFilter?.selectedGsap).length > 0 && (
                  <span className="customer-Search-current-filter-txt">
                    {selectedProductSearchFilter?.selectedGsap
                      ?.map((elm: CIQOption) => elm.label)
                      .join(", ")}
                  </span>
                )}

              {!!selectedProductSearchFilter?.selectedGenericPRoduct &&
                Object.keys(selectedProductSearchFilter?.selectedGenericPRoduct).length > 0 && (
                  <span className="customer-Search-current-filter-txt">
                    {selectedProductSearchFilter?.selectedGenericPRoduct
                      ?.map((elm: CIQOption) => elm.value)
                      .join(", ")}
                  </span>
                )}

              {
                <span className="customer-Search-current-filter-txt">
                  Active: {selectedProductSearchFilter?.selectedActive ? "True" : "False"}
                </span>
              }
              {
                <button className="customer-Search-reset" onClick={handleSearchReset}>
                  <CrossCircle />
                  Clear Filter
                </button>
              }
            </span>
          )
        ]}
        descriptiontxt="Please use this screen to assign a generic product to each GSAP product, and to activate or deactivate products."
        buttonContent={[
          <Badge
            key={1}
            icon={<Search />}
            sentiment={Sentiments.Information}
            variant={Variants.Filled}
            onClick={() => setSearchOpen(!searchOpen)}
          />,
          hasAddProductsPrivilege && (
            <Badge
              key={2}
              icon={<AddSquare />}
              sentiment={Sentiments.Information}
              variant={Variants.Filled}
              onClick={() => handleAddNewProductClick()}>
              Add New Product
            </Badge>
          )
        ]}
      />

      {filterDataList && filterDataList.ciProductsFilterBy.length > 0 ? (
        <BaseTable
          columns={columns}
          data={filterDataList?.ciProductsFilterBy}
          className="carboniq-data-table"
          tableOptions={tableOptions}
        />
      ) : (
        <CarbonIQEmptyTable />
      )}
      {addOrUpdateOpen && (
        <AddOrUpdateProducts
          onClose={() => setAddOrUpdateOpen(!addOrUpdateOpen)}
          onSubmit={() => handlAddOrUpdateSubmit()}
          details={updateOpenProductDetails}
        />
      )}
      {searchOpen && (
        <ProductSearch
          onClose={() => setSearchOpen(!searchOpen)}
          onSubmit={(data: TProductsFilterData) => {
            handleSearchSubmit(data);
          }}
          masterData={masterDataList}
        />
      )}
    </>
  );
};

export default CarbonIQProducts;
