import { Card, Directions, Flexbox } from "@sede-x/shell-ds-react-framework";
import {
  Bell,
  FileText,
  LineChart,
  Order,
  Search
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { MackUser } from "auth";
import { AuthenticationContext } from "auth/AuthenticationProvider";
import GlobalHeader from "global/sections/header";
import { ReactNode, useContext } from "react";

type TDashboardCard = {
  link: string;
  title: string;
  description?: string;
  icon: ReactNode;
  key?: string;
  class?: string;
  elmkey?: string;
};

type TDashboardCardWrap = TDashboardCard & {
  isHidden?: boolean;
};

const getDashboardCardData = (mackUser: MackUser | undefined | null) => [
  [
    {
      key: "1-1",
      link: "/#/ticketing",
      title: "Movements",
      description: "OPS Support",
      icon: <Order className="card-icon" />,
      isHidden: !mackUser?.hasMovementsOrTicketingPrivilege()
    },
    {
      key: "1-2",
      link: "/#/ticketing/manageTickets",
      title: "Search Tickets",
      description: "OPS Support",
      icon: <Search className="card-icon" />,
      isHidden: !mackUser?.hasMovementsOrTicketingPrivilege()
    }
  ],
  [
    {
      key: "2-1",
      link: "/#/forecast",
      title: "Forecast",
      description: "Envelope Planning",
      icon: <LineChart className="card-icon" />,
      isHidden: !mackUser?.hasForcastPrivilege()
    }
  ],
  [
    {
      key: "3-1",
      link: "/#/carboniq",
      title: "Carbon IQ",
      icon: <FileText className="card-icon" />,
      isHidden: !mackUser?.hasAnyPrivilegeFromService("CARBONIQ")
    }
  ],
  [
    {
      key: "4-1",
      link: "/#/notifications",
      title: "Notifications",
      icon: <Bell className="card-icon" />
    }
  ]
];

const DashboardCard = (props: TDashboardCard) => {
  return (
    <a className={`${props.class} card-inner-wrap-link`} href={props.link} key={props.elmkey}>
      <Card
        key={props.elmkey}
        className="card-body"
        header={{
          title: props.title,
          description: props.description
        }}
        direction={Directions.Horizontal}>
        {props.icon}
      </Card>
    </a>
  );
};

const d = new Date();
const timeHours = d.getHours();

const getGreatingMessage = () => {
  // added constants as forced by sonarQube
  const morning = 5;
  const afternoon = 12;
  const evening = 18;
  const night = 22;
  const morningMessage = "Good Morning";
  const afternoonMessage = "Good Afternoon";
  const eveningMessage = "Good Evening";
  const nightMessage = "Good Night";
  if (timeHours >= morning && timeHours < afternoon) {
    return morningMessage;
  } else if (timeHours >= afternoon && timeHours < evening) {
    return afternoonMessage;
  } else if (timeHours >= evening && timeHours < night) {
    return eveningMessage;
  } else if (timeHours >= night || timeHours < morning) {
    return nightMessage;
  } else {
    return "Good Day";
  }
};

const Dashboard = () => {
  const authContext = useContext(AuthenticationContext);
  const mackUser = authContext.mackUser;

  const dashboardCardData: TDashboardCardWrap[][] = getDashboardCardData(mackUser);

  return (
    <div className="dashboard-page">
      <GlobalHeader pageName={`${getGreatingMessage()} ${mackUser?.mackUser.firstName}`} />
      <div className="cards-wrapper">
        {dashboardCardData.map(elm => {
          const cardRowData = elm.filter(e => !e.isHidden);
          return cardRowData.length > 0 ? (
            <Flexbox className="cards-inner-flex" key={"parent-key-" + elm[0].key}>
              {cardRowData.map(innerElm => (
                <DashboardCard
                  link={innerElm.link}
                  title={innerElm.title}
                  description={innerElm.description}
                  icon={innerElm.icon}
                  class={innerElm.class}
                  key={"child-key-" + innerElm.key}
                  elmkey={"child-key-" + innerElm.key}
                />
              ))}
            </Flexbox>
          ) : (
            <></>
          );
        })}
      </div>
    </div>
  );
};

export default Dashboard;
