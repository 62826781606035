import { Alert, Sentiments } from "@sede-x/shell-ds-react-framework";
import { CrossCircle } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { useEffect, useState } from "react";
import { TErrorType } from "./carbonIQtypes";

const CommonErrorComponent = ({
  error = undefined,
  triggerCondition,
  messageLabel = "",
  timeOut = 3000,
  autoDismissable = false,
  customErrorMessage
}: {
  error: TErrorType | TErrorType[];
  triggerCondition?: boolean;
  messageLabel?: string;
  timeOut?: number;
  autoDismissable?: boolean;
  customErrorMessage?: string;
}) => {
  const errors = (Array.isArray(error) ? error : [error]).filter(e => !!e);

  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (!!customErrorMessage || (!!error && errors.length > 0)) {
      setHasError(true);
    }
    if (autoDismissable) {
      setTimeout(() => {
        setHasError(false);
      }, timeOut);
    }
  }, [triggerCondition, error, customErrorMessage, errors.length, autoDismissable, timeOut]);

  const args: { label?: string } = {};

  if (messageLabel.length > 0) {
    args["label"] = messageLabel;
  }

  return (
    <>
      {(!!customErrorMessage || (hasError && errors.length > 0)) && (
        <Alert
          {...args}
          sentiment={Sentiments.Negative}
          solidBgColor
          iconVisibility={false}
          className="common-error-container">
          {customErrorMessage ? (
            <p style={{ padding: "0 30px" }}>{customErrorMessage}</p>
          ) : (
            <>
              <ul>
                {errors
                  .flatMap(e => e?.graphQLErrors)
                  .filter(e => !!e)
                  ?.map(e => (
                    <li key={e?.message}>{e?.message}</li>
                  ))}
                {errors
                  .flatMap(e => e?.networkError)
                  .filter(e => !!e)
                  ?.map(e => (
                    <li key={e?.message}>{e?.message}</li>
                  ))}

                {errors
                  .flatMap(e =>
                    !(e?.networkError || e?.graphQLErrors) ? e?.message : undefined
                  )
                  .filter(e => !!e)
                  ?.map(e => (
                    <li key={e}>{e}</li>
                  ))}
              </ul>
            </>
          )}
          <CrossCircle
            className="close-icon"
            onClick={() => {
              setHasError(false);
            }}
          />
        </Alert>
      )}
    </>
  );
};

export default CommonErrorComponent;
