import { useQuery } from "@apollo/client";
import {
  Badge,
  BaseTable,
  Button,
  Loading,
  Sentiments,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  AddSquare,
  CrossCircle,
  EditOutlined,
  Search
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  ColumnDef,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { MackPrivilege } from "auth";
import { useMackAuth } from "auth/AuthenticationProvider";
import { ROWS_PER_PAGE } from "carbonIQ/CarbonIQConstants";
import { TCIQOption, TFilterSelectedSubmit, TTerminalsFilter } from "carbonIQ/carbonIQtypes";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import ColumnText from "global/elements/columnText";
import GlobalHeader from "global/sections/header";
import { loader } from "graphql.macro";
import { useState } from "react";
import { LongQueryOperatorInput } from "ticketing/ticketing.types";
import { toEqOrInQueryOperator } from "ticketing/utils";
import { GqlResponse } from "types";
import CarbonIQEmptyTable from "../emptyTable";
import TerminalsSearch from "./TerminalsSearch";
import AddOrUpdateTerminals from "./addOrUpdateTerminals";

const carbonTerminalsFilterBy = loader("../graphql/query-CarbonTerminalsFilterBy.graphql");
const FETCH_POLICY_NO_CACHE = "no-cache";

type TTerminalsFilterByResponse = GqlResponse<TTerminalsFilter[], "ciTerminalsFilterBy">;

const gridColumns: ColumnDef<TTerminalsFilter>[] = [
  {
    header: "Terminal Code",
    accessorKey: "gsapCode",
    enableSorting: true,
    size: 300,
    cell: arg => <ColumnText width={"300px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Terminal Name",
    accessorKey: "gsapName",
    enableSorting: true,
    size: 300,
    cell: arg => <ColumnText width={"300px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Display Name",
    accessorKey: "displayName",
    enableSorting: true,
    size: 300,
    cell: arg => <ColumnText width={"300px"}>{arg.getValue() as string}</ColumnText>
  }
];

const gridColumnsGenerate = (
  clickcallback: (arg: {
    row: {
      original: Partial<TTerminalsFilter>;
    };
  }) => void,
  editSelected: number | undefined,
  hasEditPermission: boolean | undefined = false
) => {
  if (!hasEditPermission) {
    return gridColumns;
  } else {
    const additionalGridColumn = {
      header: "",
      accessorKey: "id",
      cell: (arg: { row: { original: Partial<TTerminalsFilter> } }) => (
        <Button
          className={arg.row.original.id === editSelected ? "editedRow" : ""}
          title="Edit"
          size={Sizes.Small}
          onClick={() => clickcallback(arg)}>
          <EditOutlined />
          Edit
        </Button>
      ),
      size: 40,
      enableSorting: false
    };
    return [...gridColumns, additionalGridColumn];
  }
};

const CarbonIQTerminals = () => {
  const { mackUser } = useMackAuth();

  const hasAddTerminalsPrivilege = mackUser?.hasPrivilege(MackPrivilege.AddCITerminal);

  const hasModifyTerminalsPrivilege = mackUser?.hasPrivilege(MackPrivilege.ModifyCITerminal);
  const [loading] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [selectedTerminalSearchFilter, setSelectedTerminalSearchFilter] =
    useState<TFilterSelectedSubmit>();

  // const [filterDataList, setFilterDataList] = useState<TTerminalsFilter[]>([]);
  const [masterDataList, setMasterDataList] = useState<TTerminalsFilter[]>([]);
  const [editSelected, setEditSelected] = useState<number>();

  const filterCIQTerminal = (data: TFilterSelectedSubmit) => {
    const tempfilter: any = {};
    if (data && data.length > 0) {
      tempfilter["gsapName"] = toEqOrInQueryOperator<string, LongQueryOperatorInput>(
        data?.map((elm: TCIQOption) => "" + elm.value)
      );
    }

    return Object.keys(tempfilter).length ? tempfilter : null;
  };

  const applyCustomFilter = (data: TFilterSelectedSubmit) => {
    const tempFilterData = filterCIQTerminal(data);
    refetch({
      filter: tempFilterData
    });
  };

  //Fetching Carbon IQ  Terminals data
  const {
    loading: lCIQDocument,
    error: eCIQDocument,
    data: filterDataList,
    refetch
  } = useQuery<TTerminalsFilterByResponse>(carbonTerminalsFilterBy, {
    fetchPolicy: FETCH_POLICY_NO_CACHE,
    onCompleted: data => {
      if (masterDataList.length <= 0) {
        setMasterDataList(data.ciTerminalsFilterBy);
      }
      setPagination({
        pageIndex: 0,
        pageSize: ROWS_PER_PAGE
      });
    }
  });

  const [addOrUpdateOpen, setAddOrUpdateOpen] = useState(false);
  const [updateOpenTerminalDetails, setUpdateOpenTerminalDetails] =
    useState<TTerminalsFilter | null>(null);

  const columns = gridColumnsGenerate(
    arg => {
      setEditSelected(arg.row.original.id as number);
      setUpdateOpenTerminalDetails(arg.row.original as TTerminalsFilter);
      setAddOrUpdateOpen(true as boolean);
    },
    editSelected,
    hasModifyTerminalsPrivilege
  );

  const [sorting, setSorting] = useState<SortingState>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false
  };

  const handlAddOrUpdateSubmit = () => {
    refetch();
    setAddOrUpdateOpen(false);
  };

  const handleAddNewTerminalClick = () => {
    setUpdateOpenTerminalDetails(null);
    setAddOrUpdateOpen(true);
  };

  const isLoading = [loading, lCIQDocument].some(elm => elm);

  const handleSearchSubmit = (data: TFilterSelectedSubmit) => {
    setSelectedTerminalSearchFilter(data);
    applyCustomFilter(data);
  };

  const handleSearchReset = () => {
    setSelectedTerminalSearchFilter(null);
    refetch({
      filter: null
    });
  };

  return (
    <>
      <CommonErrorComponent error={eCIQDocument} />
      {isLoading && (
        <div className="loading-wrapper">
          <Loading />
        </div>
      )}

      <GlobalHeader
        pageName="Terminals"
        filterButtonContent={[
          !!selectedTerminalSearchFilter && (
            <span className="customer-Search-current-filter">
              {!!selectedTerminalSearchFilter &&
                Object.keys(selectedTerminalSearchFilter).length > 0 && (
                  <span
                    className="customer-Search-current-filter-txt"
                    title={selectedTerminalSearchFilter
                      ?.map((elm: TCIQOption) => elm.label)
                      .join(", ")}>
                    {selectedTerminalSearchFilter
                      ?.map((elm: TCIQOption) => elm.label)
                      .join(", ")}
                  </span>
                )}

              {
                <button className="customer-Search-reset" onClick={handleSearchReset}>
                  <CrossCircle />
                  Clear Filter
                </button>
              }
            </span>
          )
        ]}
        descriptiontxt="Please use this screen to add new terminals and manage their display names."
        buttonContent={[
          <Badge
            key={1}
            icon={<Search />}
            sentiment={Sentiments.Information}
            variant={Variants.Filled}
            onClick={() => setSearchOpen(!searchOpen)}
          />,
          hasAddTerminalsPrivilege && (
            <Badge
              key={2}
              icon={<AddSquare />}
              sentiment={Sentiments.Information}
              variant={Variants.Filled}
              onClick={() => handleAddNewTerminalClick()}>
              Add New Terminal
            </Badge>
          )
        ]}
      />

      {filterDataList && filterDataList.ciTerminalsFilterBy.length > 0 ? (
        <BaseTable
          columns={columns}
          data={filterDataList.ciTerminalsFilterBy}
          className="carboniq-data-table"
          tableOptions={tableOptions}
        />
      ) : (
        <CarbonIQEmptyTable />
      )}
      {addOrUpdateOpen && (
        <AddOrUpdateTerminals
          onClose={() => setAddOrUpdateOpen(!addOrUpdateOpen)}
          onSubmit={() => handlAddOrUpdateSubmit()}
          details={updateOpenTerminalDetails}
        />
      )}
      {searchOpen && (
        <TerminalsSearch
          onClose={() => setSearchOpen(!searchOpen)}
          onSubmit={(data: TFilterSelectedSubmit) => {
            handleSearchSubmit(data);
          }}
          masterData={masterDataList}
        />
      )}
    </>
  );
};

export default CarbonIQTerminals;
