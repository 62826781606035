import lighthouse from "../shared/assets/lighthouse.png";

const CarbonIQEmptyTable = () => {
  return (
    <div className="some-random-class-name" style={{ textAlign: "center" }}>
      <img src={lighthouse} alt="lighthouse" className="lighthouse" />

      <p style={{ textAlign: "center" }}>
        <strong>No data Found</strong>
      </p>
      <p style={{ textAlign: "center" }}>
        <span>Try adjusting your search to find what you’re looking for.</span>
      </p>
    </div>
  );
};

export default CarbonIQEmptyTable;
