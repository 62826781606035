import { Badge } from "@sede-x/shell-ds-react-framework";
import { ReactElement, useEffect, useState } from "react";

export const EnvBadge = (): ReactElement => {
  const [envName, setEnvName] = useState<string>("");

  useEffect(() => {
    const environment =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_ENVIRONMENT
        : window._env_.REACT_APP_ENVIRONMENT;
    if (environment !== "PRODUCTION") {
      setEnvName(environment);
    } else {
      setEnvName("");
    }
  }, []);

  return (
    <>
      {envName && envName.length > 0 && (
        <Badge className={"env-badge-label " + envName?.toLowerCase()}>{envName}</Badge>
      )}
    </>
  );
};
