import { useLazyQuery } from "@apollo/client";
import { getter } from "@progress/kendo-data-query";
import { firstDayOfMonth, lastDayOfMonth } from "@progress/kendo-date-math";
import { toString as toKString } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
  Dialog,
  DialogActionsBar,
  Window,
  WindowActionsBar
} from "@progress/kendo-react-dialogs";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridItemChangeEvent,
  GridNoRecords,
  GridToolbar
} from "@progress/kendo-react-grid";
import { NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { loader } from "graphql.macro";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { GqlResponse, TAllocationMovement, TMovement } from "ticketing/ticketing.types";
import {
  equalsIgnoreCase,
  fromISODateString,
  toMovementFilterInputFromMovement,
  truncateTo
} from "ticketing/utils";

const MOVEMENTS_SEARCH = loader("../../ticketing-graphql/movementSearch.graphql");

type MovementSearchResponse = GqlResponse<TMovement[], "movementsFilterBy">;

type VolumeAllocationContextProps = {
  totalQuantityToAllocate: number;
  leftOverQuantityToAllocate: number;
};

const VolumeAllocationContext = createContext<VolumeAllocationContextProps>({
  totalQuantityToAllocate: 0,
  leftOverQuantityToAllocate: 0
});

const FinalQuantityCell = (props: GridCellProps) => (
  <td
    className="k-table-td"
    colSpan={props.colSpan}
    style={{
      ...props.style,
      color: `${
        props.dataItem.finalScheduledQuantity !== props.dataItem.scheduledQuantity
          ? "orange"
          : "#e7e7e7"
      }`
    }}
    role={"gridcell"}>
    {props.dataItem.finalScheduledQuantity}
  </td>
);

const NumericCell = (props: GridCellProps) => {
  const { ariaColumnIndex, columnIndex, dataItem, field } = props;
  const { totalQuantityToAllocate } = useContext(VolumeAllocationContext);

  const isInEdit = dataItem.inEdit;
  const valueGetter = field && getter(field);
  const value = valueGetter ? valueGetter(dataItem) : 0;

  //when taking volume from a movement, do not exceed what it can give
  const maxQuantity =
    totalQuantityToAllocate < 0
      ? Math.abs(
          (dataItem as TAllocationMovement).scheduledQuantity -
            ((dataItem as TAllocationMovement).actualizedQuantity ?? 0)
        )
      : undefined;

  const onChange = (e: NumericTextBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        value: e.value,
        syntheticEvent: e.syntheticEvent
      });
    }
  };

  return (
    <td
      className="k-table-td k-grid-edit-cell"
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      role={"gridcell"}>
      {isInEdit ? (
        <div>
          <NumericTextBox
            format="0.0###"
            defaultValue={value}
            onChange={onChange}
            min={0}
            max={maxQuantity}
            step={1}
            spinners={false}
          />
        </div>
      ) : (
        <span>{value}</span>
      )}
    </td>
  );
};

const isStatusExpectedOrScheduled = (status?: string) =>
  equalsIgnoreCase(status, "expected") || equalsIgnoreCase(status, "scheduled");

const transformMovements = (
  source: TMovement,
  quantityToAllocate: number,
  movements: TMovement[]
): TAllocationMovement[] => {
  //filter out the source movements, and actualized - internal and external
  return movements
    ?.filter(
      m =>
        source.id !== m.id &&
        !m.isActualizedExternally &&
        isStatusExpectedOrScheduled(m.status?.name)
    )
    ?.filter(
      m => m.tickets?.length === 0 && (quantityToAllocate > 0 || m.scheduledQuantity !== 0)
    )
    ?.map(m => ({
      id: m.id,
      version: m.version,
      startDate: fromISODateString(m.startDate)!,
      endDate: fromISODateString(m.endDate)!,
      deliveryId: m.enterpriseSystemCode,
      scheduledQuantity: m.scheduledQuantity,
      actualizedQuantity: m.actualizedQuantity,
      finalScheduledQuantity: m.scheduledQuantity,
      quantityAllocated: 0,
      inEdit: true
    }))
    ?.sort((m1, m2) => m1.startDate.getTime() - m2.startDate.getTime());
};

/**
 *
 * @param param0
 * @returns
 */
const VolumeAllocationModal = ({
  quantityToAllocate,
  onCancel,
  onSave,
  sourceMovement
}: {
  quantityToAllocate: number;
  onCancel: () => void;
  onSave: (allocations: TAllocationMovement[] | null) => void;
  sourceMovement: TMovement;
}) => {
  const isIncrease = quantityToAllocate > 0;
  const [volumeAllocationNomsSearch, { loading, error }] = useLazyQuery<MovementSearchResponse>(
    MOVEMENTS_SEARCH,
    {
      fetchPolicy: "no-cache",
      onCompleted: data =>
        setVolumeAllocationNoms(
          transformMovements(sourceMovement, quantityToAllocate, data.movementsFilterBy)
        )
    }
  );

  const [volumeAllocationNoms, setVolumeAllocationNoms] = useState<TAllocationMovement[]>([]);

  const [leftOverQuantityToAllocate, setLeftOverQuantityToAllocate] = useState<number>(
    Math.abs(quantityToAllocate)
  );

  const [movementStartDate, setMovementStartDate] = useState<Date>(firstDayOfMonth(new Date()));
  const [movementEndDate, setMovementEndDate] = useState<Date>(lastDayOfMonth(new Date()));

  const searchForMatchingMovements = useCallback(
    (startDate: Date, endDate: Date) => {
      //build filters from sourceMovement
      const movementsFilter = toMovementFilterInputFromMovement(
        sourceMovement,
        startDate,
        endDate
      );
      volumeAllocationNomsSearch({
        variables: { movementsFilter }
      });
    },
    [sourceMovement, volumeAllocationNomsSearch]
  );

  const [showConfirmModal, setShowConfirmModal] = useState({
    showModal: false,
    message: ""
  });

  useEffect(() => {
    const startDate = firstDayOfMonth(new Date());
    const endDate = lastDayOfMonth(new Date());
    searchForMatchingMovements(startDate, endDate);
  }, [searchForMatchingMovements]);

  const handleStartDateChange = (event: DatePickerChangeEvent) => {
    if (event.value) {
      setMovementStartDate(event.value);
    }
  };

  const handleEndDateChange = (event: DatePickerChangeEvent) => {
    if (event.value) {
      setMovementEndDate(event.value);
    }
  };

  const handleSave = () => {
    const totalVolumeAllocated = truncateTo(
      volumeAllocationNoms.reduce((s, i) => s + i.quantityAllocated, 0),
      4
    );
    const actualQuantityToAllocate = Math.abs(quantityToAllocate);
    if (totalVolumeAllocated === actualQuantityToAllocate) {
      return doSave();
    }
    setShowConfirmModal({
      showModal: true,
      message: `You are about to ${
        totalVolumeAllocated > actualQuantityToAllocate ? "Over" : "Under"
      } allocate, would you like to proceed?`
    });
    return undefined;
  };

  const onConfirmAllocation = () => {
    setShowConfirmModal({ showModal: false, message: "" });
    doSave();
  };

  const doSave = () => {
    const allocations = volumeAllocationNoms
      .filter(v => v.quantityAllocated > 0)
      .map(a => ({
        ...a,
        quantityAllocated: a.quantityAllocated * (quantityToAllocate < 0 ? -1 : 1)
      }));
    onSave(allocations);
  };

  const itemChange = (e: GridItemChangeEvent) => {
    //reset left over quantity
    setLeftOverQuantityToAllocate(
      Math.abs(quantityToAllocate) -
        e.value -
        volumeAllocationNoms
          .filter(a => a.id !== e.dataItem.id)
          .reduce((n, a) => n + a.quantityAllocated, 0)
    );

    //update state with new quantity
    setVolumeAllocationNoms(allocations =>
      allocations.map(a => {
        if (a.id === e.dataItem.id) {
          return {
            ...a,
            quantityAllocated: e.value,
            finalScheduledQuantity: isIncrease
              ? a.scheduledQuantity + e.value
              : a.scheduledQuantity - e.value
          };
        }
        return a;
      })
    );
  };

  const allocationState = useMemo(
    () => ({
      totalQuantityToAllocate: quantityToAllocate,
      leftOverQuantityToAllocate
    }),
    [leftOverQuantityToAllocate, quantityToAllocate]
  );

  return (
    <Window
      className="volume-allocation-modal"
      title={`Volume Allocation (${sourceMovement.enterpriseSystemCode})`}
      initialWidth={960}
      initialHeight={300}
      minHeight={300}
      minWidth={960}
      onClose={onCancel}
      resizable
      modal>
      <VolumeAllocationContext.Provider value={allocationState}>
        <div className={"content-wrapper"} style={{ height: "100%" }}>
          <div className={"grid-wrapper"} style={{ height: "100%" }}>
            <div className={"card-container"} style={{ height: "100%" }}>
              <Grid
                data={volumeAllocationNoms}
                resizable
                editField="inEdit"
                style={{
                  height: "100%"
                }}
                onItemChange={itemChange}
                dataItemKey="id">
                <GridToolbar>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flex: "1 1 auto"
                    }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px"
                      }}>
                      <span>{`Quantity to Allocate:`}</span>
                      <span
                        style={{
                          textDecorationLine: "underline",
                          backgroundColor: "white",
                          padding: "4px 8px",
                          // margin: "0 0 0 4px",
                          borderRadius: "4px"
                        }}>{`${toKString(leftOverQuantityToAllocate, "0.0###")} / ${toKString(
                        quantityToAllocate,
                        "0.0###"
                      )} ${sourceMovement?.unitOfMeasure?.name}`}</span>

                      <span
                        className={`k-icon ${
                          isIncrease ? "k-i-arrow-up" : "k-i-arrow-down"
                        }`}></span>
                      <span>{`${isIncrease ? "Increase" : "Decrease"}`}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center"
                      }}>
                      <Label>From</Label>
                      <DatePicker
                        name="startDate"
                        id="endDate"
                        onChange={handleStartDateChange}
                        defaultValue={movementStartDate}
                        width={144}
                        size={"small"}
                      />
                      <Label>To</Label>
                      <DatePicker
                        name="ticketEndDate"
                        id="ticketEndDate"
                        onChange={handleEndDateChange}
                        defaultValue={movementEndDate}
                        width={144}
                        size={"small"}
                      />
                      <Button
                        icon="search"
                        onClick={() =>
                          searchForMatchingMovements(movementStartDate, movementEndDate)
                        }
                        themeColor={"primary"}
                        className="theme-btn-red">
                        Search
                      </Button>
                    </div>
                  </div>
                </GridToolbar>
                <GridNoRecords>
                  <div style={{ color: "gray" }}>There is no data available</div>
                </GridNoRecords>
                <GridColumn
                  title={isIncrease ? "Give" : "Take"}
                  field="quantityAllocated"
                  // editor="numeric"
                  cell={NumericCell}
                />
                <GridColumn title="Delivery Id" field="deliveryId" editable={false} />
                <GridColumn
                  title="Date"
                  field="startDate"
                  format="{0:yyyy-MM-dd}"
                  editable={false}
                />
                <GridColumn
                  title="Scheduled Quantity"
                  field="scheduledQuantity"
                  editable={false}
                />
                <GridColumn
                  title="Final Quantity"
                  field="finalScheduledQuantity"
                  editable={false}
                  cell={FinalQuantityCell}
                />
              </Grid>
            </div>
          </div>
        </div>
        {showConfirmModal.showModal && (
          <Dialog title={"Volume allocation confirmation"}>
            <div
              style={{
                padding: "16px",
                color: "whitesmoke",
                backgroundColor: "gray"
              }}>
              {showConfirmModal.message}
            </div>
            <DialogActionsBar>
              <Button
                icon="cancel"
                onClick={() => setShowConfirmModal({ showModal: false, message: "" })}>
                No
              </Button>
              <Button themeColor={"primary"} icon="check" onClick={onConfirmAllocation}>
                Yes
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </VolumeAllocationContext.Provider>
      <WindowActionsBar layout={"end"}>
        {loading && <InlineLoadingPanel />}
        {error && <ApolloErrorViewer error={error}></ApolloErrorViewer>}
        <Button
          icon="arrows-no-change"
          onClick={() => onSave(null)}
          themeColor={"warning"}
          className="theme-btn-yellow">
          Save without Allocating
        </Button>
        <Button
          icon="save"
          onClick={handleSave}
          disabled={leftOverQuantityToAllocate === Math.abs(quantityToAllocate)}
          themeColor={"primary"}
          className="theme-btn-green">
          Save
        </Button>
        <Button
          icon="cancel"
          onClick={onCancel}
          themeColor={"secondary"}
          className="theme-btn-red">
          Cancel
        </Button>
      </WindowActionsBar>
    </Window>
  );
};

export default VolumeAllocationModal;
