import { useQuery } from "@apollo/client";
import {
  Badge,
  BaseTable,
  Button,
  Loading,
  Sentiments,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  CrossCircle,
  EditOutlined,
  Search
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  ColumnDef,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { MackPrivilege } from "auth";
import { useMackAuth } from "auth/AuthenticationProvider";
import { ROWS_PER_PAGE } from "carbonIQ/CarbonIQConstants";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import ColumnText from "global/elements/columnText";
import GlobalHeader from "global/sections/header";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import { GqlResponse } from "types";
import { TCICustomer, TCustomerFilter, TUpdateCICustomer } from "../carbonIQtypes";
import CarbonIQEmptyTable from "../emptyTable";
import CustomerLookup from "./CustomerLookup";
import UpdateCustomer from "./UpdateCustomer";

const carbonCustomers = loader("./../graphql/carbonCustomers.graphql");

type TCustomerSearchResponse = GqlResponse<TCICustomer[], "ciCustomersFilterBy">;

const gridColumns: ColumnDef<Partial<TCICustomer>>[] = [
  {
    header: "Customer Name",
    accessorKey: "gsapName",
    enableSorting: true,
    cell: arg => <ColumnText width={"225px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Display Name",
    accessorKey: "displayName",
    enableSorting: true,
    cell: arg => <ColumnText width={"225px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Customer Email",
    accessorKey: "email",
    enableSorting: true,
    cell: arg => <ColumnText width={"225px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Key A/c Mgr Email",
    accessorKey: "managerEmail",
    enableSorting: true,
    cell: arg => <ColumnText width={"225px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Generate Letters?",
    accessorKey: "shouldGenerateLetters",
    enableSorting: true,
    cell: arg => <ColumnText width={"75px"}>{arg.getValue() as string}</ColumnText>
  }
];

const gridColumnsGenerate = (
  clickcallback: (arg: {
    row: {
      original: Partial<TCICustomer>;
    };
  }) => void,
  editSelected: number | undefined,
  isDisabled: boolean
) => {
  if (isDisabled) {
    return gridColumns;
  } else {
    const additionalGridColumn = {
      header: "",
      accessorKey: "components",
      enableSorting: false,
      cell: (arg: { row: { original: Partial<TCICustomer> } }) => (
        <Button
          className={(arg.row.original.id as number) === editSelected ? "editedRow" : ""}
          title="Edit"
          size={Sizes.Small}
          disabled={isDisabled}
          onClick={() => clickcallback(arg)}>
          <EditOutlined />
          Edit
        </Button>
      )
    };
    return [...gridColumns, additionalGridColumn];
  }
};

const CarbonIQCustomers = () => {
  const { mackUser } = useMackAuth();
  const [loading] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [updateOpenCustomerDetails, setUpdateOpenCustomerDetails] =
    useState<Partial<TCICustomer> | null>(null);
  const [customers, setCustomers] = useState<Partial<TCICustomer>[]>([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [selectedCustomerSearchFilter, setSelectedCustomerSearchFilter] = useState<
    Partial<TCustomerFilter>
  >({});
  const [editSelected, setEditSelected] = useState<number>();
  const [originalData, setOriginalData] = useState<Partial<TCICustomer>[]>([]);

  const {
    loading: ciqCustomersLoading,
    error: ciqCustomersError,
    data: customersData,
    refetch
  } = useQuery<TCustomerSearchResponse>(carbonCustomers, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      setOriginalData(data?.ciCustomersFilterBy);
      setPagination({
        pageIndex: 0,
        pageSize: ROWS_PER_PAGE
      });
    }
  });

  useEffect(() => {
    customersData && setCustomers(customersData?.ciCustomersFilterBy);
  }, [customersData]);

  const columns = gridColumnsGenerate(
    arg => {
      setEditSelected(arg.row.original.id as number);
      setUpdateOpenCustomerDetails(arg.row.original);
      setUpdateOpen(true);
    },
    editSelected,
    !mackUser?.hasPrivilege(MackPrivilege.ModifyCICustomer)
  );

  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel()
  };

  const isLoading = [ciqCustomersLoading, loading].some(l => l);
  const handleSearchSubmit = (data: TCustomerFilter) => {
    setSelectedCustomerSearchFilter(data);
    if (data?.customerIDs && data?.customerIDs?.length === 0) {
      return; //user has clicked search without first selecting customer(s)
    }

    // logic to apply filter without making new API call, just reducing the list from existing data
    setCustomers((old: Partial<TCICustomer>[]) =>
      old.filter((c: Partial<TCICustomer>) => c.id === data?.customerIDs?.at(0)?.id)
    );
  };
  const handleUpdateSubmit = (data: TUpdateCICustomer) => {
    if (data) {
      updateData(data);
    }
    setUpdateOpen(false);
  };

  // function to update in the existing data with the new data for particular customer without making new API call
  const updateData = (data: TUpdateCICustomer) => {
    if (customers.findIndex(c => c.id === data?.updateCICustomer?.id) > -1) {
      setCustomers(
        customers.map(c => (c.id === data?.updateCICustomer.id ? data?.updateCICustomer : c))
      );
    }
  };

  const handleCustomerSearchReset = () => {
    setSelectedCustomerSearchFilter({});
    refetch();
  };

  return (
    <>
      <CommonErrorComponent error={ciqCustomersError} />

      {isLoading && (
        <div className="loading-wrapper">
          <Loading />
        </div>
      )}

      <GlobalHeader
        pageName="Customers"
        filterButtonContent={[
          Object.keys(selectedCustomerSearchFilter).length > 0 && (
            <span className="customer-Search-current-filter">
              {!!selectedCustomerSearchFilter?.customerIDs?.length && (
                <span className="customer-Search-current-filter-txt">
                  {selectedCustomerSearchFilter?.customerIDs?.map(elm => elm.value).join(", ")}
                </span>
              )}
              {!!selectedCustomerSearchFilter?.customerIDs?.length && (
                <button className="customer-Search-reset" onClick={handleCustomerSearchReset}>
                  <CrossCircle />
                  Clear Filter
                </button>
              )}
            </span>
          )
        ]}
        descriptiontxt="Please use this screen to change the Display name for any customer, their email and that of their Key Account Manager. You can also decide if you want to stop letters being generated for any customer."
        buttonContent={[
          <Badge
            key={1}
            icon={<Search />}
            sentiment={Sentiments.Information}
            variant={Variants.Filled}
            onClick={() => setSearchOpen(!searchOpen)}
          />
        ]}
      />

      {customers.length > 0 ? (
        <BaseTable
          columns={columns}
          data={customers}
          className="carboniq-data-table"
          tableOptions={tableOptions}
        />
      ) : (
        <CarbonIQEmptyTable />
      )}
      {searchOpen && (
        <CustomerLookup
          customerData={originalData}
          onClose={() => setSearchOpen(!searchOpen)}
          onSubmit={(data: TCustomerFilter) => {
            handleSearchSubmit(data);
          }}
        />
      )}

      {updateOpen && (
        <UpdateCustomer
          onClose={() => setUpdateOpen(!updateOpen)}
          onSubmit={handleUpdateSubmit}
          details={updateOpenCustomerDetails}
        />
      )}
    </>
  );
};

export default CarbonIQCustomers;
