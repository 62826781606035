import { Drawer, Heading, Sizes, TextTypes, Variants } from "@sede-x/shell-ds-react-framework";
import CarbonIQEmptyTable from "carbonIQ/emptyTable";
import { useState } from "react";
import { TValidationsConfig } from "ticketing/ticketing.types";

const ShowOpsValidationDetails = ({
  details,
  onClose
}: {
  details?: TValidationsConfig;
  onClose: () => void;
}) => {
  const [open, setOpen] = useState(true);
  const handleOnClose = () => {
    onClose();
    setOpen(false);
  };

  return (
    <Drawer
      header={
        <Heading className="setting-form-heading" type={TextTypes.H2}>
          {"OPS Validation Details"}
        </Heading>
      }
      borders={false}
      closeButton={true}
      sticky
      mask={true}
      open={open}
      size={Sizes.Medium}
      onClose={() => {
        handleOnClose();
      }}
      actions={[
        {
          label: "Close",
          action: () => {
            handleOnClose();
          },
          props: {
            variant: Variants.Outlined
          }
        }
      ]}>
      {details ? (
        <table className="data-table">
          <tr>
            <td>Id </td>
            <td>{details.id ?? "-"}</td>
          </tr>
          <tr>
            <td>Code </td>
            <td>{details.code ?? "-"}</td>
          </tr>
          <tr>
            <td>Business Unit </td>
            <td>{details.businessUnitDefined ?? "-"}</td>
          </tr>
          <tr>
            <td>Enterprise System </td>
            <td>{details.enterpriseSystemCode ?? "-"}</td>
          </tr>
          <tr>
            <td>Delivery ID </td>
            <td>{details.enterpriseSystemCode ?? "-"}</td>
          </tr>
          <tr>
            <td>Legal Entity ID </td>
            <td>{details.legalEntityId ?? "-"}</td>
          </tr>
          <tr>
            <td>Aggregated </td>
            <td>{details.runForAggregated ?? "-"}</td>
          </tr>
          <tr>
            <td>Cancelled Nom </td>
            <td>{details.runForCancelledNomIndicator ?? "-"}</td>
          </tr>
          <tr>
            <td>Converted Data </td>
            <td>{details.runForConvertedDataIndicator ?? "-"}</td>
          </tr>
          <tr>
            <td>Gain Loss Nom </td>
            <td>{details.runForGainLossNomIndicator ?? "-"}</td>
          </tr>
          <tr>
            <td>Non Transit </td>
            <td>{details.runForNonTransitIndicator ?? "-"}</td>
          </tr>
          <tr>
            <td>Paper Rins </td>
            <td>{details.runForPaperRinsNomIndicator ?? "-"}</td>
          </tr>
          <tr>
            <td>Planned Nom </td>
            <td>{details.runForPlannedNomIndicator ?? "-"}</td>
          </tr>
          <tr>
            <td>Rins Nom </td>
            <td>{details.runForRinsNomIndicator ?? "-"}</td>
          </tr>
          <tr>
            <td>Soft Delete </td>
            <td>{details.runForSoftDeleteIndicator ?? "-"}</td>
          </tr>
          <tr>
            <td>Validator Script </td>
            <td>{details.validatorScript ?? "-"}</td>
          </tr>
        </table>
      ) : (
        <CarbonIQEmptyTable />
      )}
    </Drawer>
  );
};

export default ShowOpsValidationDetails;
