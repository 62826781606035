import {
  Button,
  FormField,
  Modal,
  RangeDatePicker,
  Variants
} from "@sede-x/shell-ds-react-framework";
import dayjs from "dayjs";
import { useState } from "react";

import localeData from "dayjs/plugin/localeData";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import { INotificationSearchCriteria } from "notification/models";

const NotificationSearchModal = ({
  onClose,
  onSubmit
}: {
  onClose: () => void;
  onSubmit: (elm: INotificationSearchCriteria) => void;
}) => {
  const [open, setOpen] = useState(true);
  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  dayjs.extend(weekday);
  dayjs.extend(utc);
  dayjs.extend(localeData);

  return (
    <Modal
      title="Search Notification(s)"
      width={"700px"}
      height={"600px"}
      mask={true}
      open={open}
      onClose={() => {
        handleOnClose();
      }}>
      <form
        onSubmit={(event: React.SyntheticEvent) => {
          event.preventDefault();
          const target = event.target as typeof event.target & {
            getElementsByClassName: (data: string) => {
              item: (data: number) => {
                getElementsByTagName: (data: string) => { value: Date }[];
              };
            };
          };

          const tempInpList = target
            .getElementsByClassName("date-range-wrapper")
            .item(0)
            .getElementsByTagName("input");
          const inpVal1 = tempInpList[0].value;
          const inpVal2 = tempInpList[1].value;

          onSubmit({
            startDate: dayjs.utc(inpVal1).toDate(),
            endDate: dayjs.utc(inpVal2).toDate()
          });
          handleOnClose();
        }}>
        <FormField size={"medium"} id="date-range-label" label="Period">
          <div id="date-range-wrapper" className="date-range-wrapper">
            <RangeDatePicker
              placeholder={["Start Date", "End Date"]}
              picker="date"
              format="DD MMM YYYY"
              id="date-range"
              size={"small"}
            />
          </div>
        </FormField>
        <div style={{ paddingBottom: "230px" }}></div>
        <div className="from-button-wrap">
          <Button
            variant={Variants.Outlined}
            onClick={() => {
              handleOnClose();
            }}>
            Cancel
          </Button>
          <Button type="submit">Search</Button>
        </div>
      </form>
    </Modal>
  );
};

export default NotificationSearchModal;
