import { useQuery } from "@apollo/client";
import {
  Badge,
  BaseTable,
  Button,
  Loading,
  Positions,
  Sentiments,
  Sizes,
  Tooltip,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  AddSquare,
  CrossCircle,
  EditOutlined,
  IconCircleInfo,
  Search
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  ColumnDef,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { MackPrivilege } from "auth";
import { useMackAuth } from "auth/AuthenticationProvider";
import { ROWS_PER_PAGE } from "carbonIQ/CarbonIQConstants";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import ColumnText from "global/elements/columnText";
import GlobalHeader from "global/sections/header";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import { GqlResponse } from "types";
import {
  CIQOption,
  TCILookup,
  TCIWeightedAverage,
  TWeightedAverageFilter,
  TWeightedAverageFilterInput
} from "../carbonIQtypes";
import CarbonIQEmptyTable from "../emptyTable";
import WeightedAverageCrud from "./WeightedAverageCrud";
import WeightedAverageLookup from "./WeightedAverageLookup";

const ciqWeightedAverages = loader("./../graphql/carbonWeightedAverage.graphql");
const ciqLookupComponents = loader("./../graphql/lookupComponents.graphql");
const ciqLookupTerminals = loader("./../graphql/lookupTerminals.graphql");

type TWeightedAverageSearchResponse = GqlResponse<
  TCIWeightedAverage[],
  "ciWeightedAveragesFilterBy"
>;
type TComponentsResponse = GqlResponse<CIQOption[], "ciComponentsFilterBy">;
type TTerminalsResponse = GqlResponse<CIQOption[], "ciTerminalsFilterBy">;

const gridColumns: ColumnDef<Partial<TCIWeightedAverage>>[] = [
  {
    header: "Component",
    accessorKey: "component.name",
    enableSorting: true,
    cell: arg => <ColumnText width={"200px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Terminal",
    accessorKey: "terminal.displayName",
    enableSorting: true,
    cell: arg => <ColumnText width={"200px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Year",
    accessorKey: "year",
    enableSorting: true,
    cell: arg => <ColumnText width={"70px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Average CI",
    accessorKey: "averageCI",
    enableSorting: true,
    cell: arg => (
      <p style={{ width: "70px" }} className="weighted-info-wrap">
        {arg.getValue() as string}

        <Tooltip
          placement={Positions.Right}
          overlay={
            <span
              style={{
                display: "block",
                padding: "4px 8px",
                maxWidth: "240px"
              }}>
              {(arg.row.original.comments as string) || "No comments"}
            </span>
          }
          trigger={<IconCircleInfo className="weighted-info" />}
        />
      </p>
    )
  },
  {
    header: "Feedstock",
    accessorKey: "feedStock",
    enableSorting: true,
    cell: arg => <ColumnText width={"200px"}>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Country of Origin",
    accessorKey: "countryOfOrigin",
    enableSorting: true,
    cell: arg => <ColumnText width={"150px"}>{arg.getValue() as string}</ColumnText>
  }
];

const gridColumnsGenerate = (
  clickcallback: (arg: {
    row: {
      original: Partial<TCIWeightedAverage>;
    };
  }) => void,
  editSelected: number | undefined,
  hasEditPermission: boolean | undefined = false
) => {
  if (!hasEditPermission) {
    return gridColumns;
  } else {
    const additionalGridColumn = {
      header: "",
      accessorKey: "components",
      enableSorting: false,
      cell: (arg: { row: { original: Partial<TCIWeightedAverage> } }) => (
        <Button
          className={(arg.row.original.id as number) === editSelected ? "editedRow" : ""}
          title="Edit"
          size={Sizes.Small}
          onClick={() => clickcallback(arg)}>
          <EditOutlined />
          Edit
        </Button>
      )
    };
    return [...gridColumns, additionalGridColumn];
  }
};
const CarbonIQWeightedAverage = () => {
  const { mackUser } = useMackAuth();
  const [loading] = useState(false);
  const [addOrUpdateOpen, setAddOrUpdateOpen] = useState(false);

  const hasAddPrivilege = mackUser?.hasPrivilege(MackPrivilege.AddCIWeightedAverages);

  const [updateOpenWeightedAverageDetails, setUpdateOpenWeightedAverageDetails] =
    useState<Partial<TCIWeightedAverage> | null>(null);
  const [weightedAverages, setWeightedAverages] = useState<Partial<TCIWeightedAverage>[]>([]);
  const [components, setComponents] = useState<TCILookup[]>([]);
  const [terminals, setTerminals] = useState<TCILookup[]>([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [selectedWeightedAverageSearchFilter, setSelectedWeightedAverageSearchFilter] =
    useState<TWeightedAverageFilter>({});
  const [editSelected, setEditSelected] = useState<number>();

  const {
    loading: ciqWeightedAveragesLoading,
    error: eCIQWeightedAverages,
    data: weightedAveragesData,
    refetch
  } = useQuery<TWeightedAverageSearchResponse>(ciqWeightedAverages, {
    fetchPolicy: "no-cache",
    onCompleted: (data: TWeightedAverageSearchResponse) => {
      setPagination({
        pageIndex: 0,
        pageSize: ROWS_PER_PAGE
      });
    }
  });

  useEffect(() => {
    weightedAveragesData &&
      setWeightedAverages(weightedAveragesData.ciWeightedAveragesFilterBy);
  }, [weightedAveragesData]);

  const { loading: componentsLoading, error: eLookupComponents } =
    useQuery<TComponentsResponse>(ciqLookupComponents, {
      fetchPolicy: "no-cache",
      onCompleted: (data: TComponentsResponse) => {
        setComponents(data.ciComponentsFilterBy);
      }
    });

  const { loading: terminalsLoading, error: eLookupTerminals } = useQuery<TTerminalsResponse>(
    ciqLookupTerminals,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data: TTerminalsResponse) => {
        setTerminals(data.ciTerminalsFilterBy);
      }
    }
  );

  const columns = gridColumnsGenerate(
    arg => {
      setEditSelected(arg.row.original.id as number);
      setUpdateOpenWeightedAverageDetails(arg.row.original);
      setAddOrUpdateOpen(true);
    },
    editSelected,
    mackUser?.hasPrivilege(MackPrivilege.ModifyCIWeightedAverages)
  );

  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false
  };

  const yearLength = 3;
  const isLoading = [
    ciqWeightedAveragesLoading,
    componentsLoading,
    terminalsLoading,
    loading
  ].some(l => l);

  const handleWeightedAverageSearchSubmit = (data: TWeightedAverageFilter) => {
    setSelectedWeightedAverageSearchFilter(data);
    const payload: Partial<TWeightedAverageFilterInput> = {};
    if (data?.components && data?.components?.length > 0) {
      payload.genericProductComponentId = {
        in: data?.components.map((c: any) => c.key)
      };
    }
    if (data?.terminals && data?.terminals?.length > 0) {
      payload.terminalId = { in: data?.terminals.map((c: any) => c.key) };
    }
    if (data?.years && data?.years?.length > 0 && data?.years?.at(0)?.length! > yearLength) {
      payload.year = { in: data?.years.map((year: string) => +year) };
    }
    refetch({
      filter: payload
    });
  };
  const handleAddNewWeightedAverageClick = () => {
    setUpdateOpenWeightedAverageDetails(null);
    setAddOrUpdateOpen(true);
  };
  const handleWeightedAverageSearchReset = () => {
    setSelectedWeightedAverageSearchFilter({});
    refetch();
  };
  const handleUpdateSubmit = (data: any) => {
    if (data) {
      updateData(data);
    }
    setAddOrUpdateOpen(false);
  };

  const updateData = (data: any) => {
    let updatedRecord: TCIWeightedAverage;
    if (updateOpenWeightedAverageDetails === null) {
      updatedRecord = data.addCIWeightedAverage;
      setWeightedAverages([updatedRecord, ...weightedAverages]);
    } else {
      updatedRecord = data.updateCIWeightedAverage;

      if (weightedAverages.findIndex(w => w.id === updatedRecord?.id) > -1) {
        setWeightedAverages(
          weightedAverages.map(w => (w.id === updatedRecord.id ? updatedRecord : w))
        );
      }
    }
  };
  const hasFilters =
    selectedWeightedAverageSearchFilter?.years?.at(0) !== "" ||
    !!selectedWeightedAverageSearchFilter?.terminals?.length ||
    (selectedWeightedAverageSearchFilter?.components &&
      selectedWeightedAverageSearchFilter?.components?.length > 0);

  return (
    <>
      <CommonErrorComponent
        error={[eCIQWeightedAverages, eLookupComponents, eLookupTerminals]}
      />

      {isLoading && (
        <div className="loading-wrapper">
          <Loading />
        </div>
      )}

      <GlobalHeader
        pageName="Weighted Average CI"
        filterButtonContent={[
          Object.keys(selectedWeightedAverageSearchFilter).length > 0 && (
            <span className="customer-Search-current-filter">
              {selectedWeightedAverageSearchFilter?.components &&
                selectedWeightedAverageSearchFilter?.components?.length > 0 && (
                  <span className="customer-Search-current-filter-txt">
                    {selectedWeightedAverageSearchFilter?.components
                      ?.map(elm => elm.label)
                      .join(", ")}
                  </span>
                )}
              {!!selectedWeightedAverageSearchFilter?.terminals?.length && (
                <span className="customer-Search-current-filter-txt">
                  {selectedWeightedAverageSearchFilter?.terminals
                    ?.map(elm => elm.label)
                    .join(", ")}
                </span>
              )}
              {selectedWeightedAverageSearchFilter?.years?.at(0) !== "" && (
                <span className="customer-Search-current-filter-txt">
                  {selectedWeightedAverageSearchFilter?.years?.map(elm => elm).join(", ")}
                </span>
              )}
              {hasFilters && (
                <button
                  className="customer-Search-reset"
                  onClick={handleWeightedAverageSearchReset}>
                  <CrossCircle />
                  Clear Filter
                </button>
              )}
            </span>
          )
        ]}
        descriptiontxt="Please use this screen to add the weighted average CI values for each component per terminal and Year, and their associated feedstock and country of origin."
        buttonContent={[
          <Badge
            key={1}
            icon={<Search />}
            sentiment={Sentiments.Information}
            variant={Variants.Filled}
            onClick={() => setSearchOpen(!searchOpen)}
          />,
          hasAddPrivilege && (
            <Badge
              key={2}
              icon={<AddSquare />}
              sentiment={Sentiments.Information}
              variant={Variants.Filled}
              onClick={() => handleAddNewWeightedAverageClick()}>
              Add Weighted Average CI
            </Badge>
          )
        ]}
      />

      {weightedAverages.length > 0 ? (
        <BaseTable
          columns={columns}
          data={weightedAverages}
          className="carboniq-data-table"
          tableOptions={tableOptions}
        />
      ) : (
        <CarbonIQEmptyTable />
      )}
      {searchOpen && (
        <WeightedAverageLookup
          weightedAverageData={weightedAverages}
          onClose={() => setSearchOpen(!searchOpen)}
          onSubmit={(data: TWeightedAverageFilter) => {
            handleWeightedAverageSearchSubmit(data);
          }}
          terminals={terminals}
          components={components}
          selectedWeightedAverageSearchFilter={selectedWeightedAverageSearchFilter}
        />
      )}
      {addOrUpdateOpen && (
        <WeightedAverageCrud
          onClose={() => setAddOrUpdateOpen(!addOrUpdateOpen)}
          onSubmit={handleUpdateSubmit}
          details={updateOpenWeightedAverageDetails}
          weightedAverageData={weightedAverages} /*needed for duplication validation */
        />
      )}
    </>
  );
};

export default CarbonIQWeightedAverage;
