import { BaseTable } from "@sede-x/shell-ds-react-framework";
import {
  ColumnDef,
  getPaginationRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState
} from "@tanstack/react-table";
import { ROWS_PER_PAGE } from "carbonIQ/CarbonIQConstants";
import CarbonIQEmptyTable from "carbonIQ/emptyTable";
import ColumnText from "global/elements/columnText";
import GlobalHeader from "global/sections/header";
import { useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import useValidationConfigs from "ticketing/hooks/useValidationConfigs";
import { TValidationsConfig } from "ticketing/ticketing.types";
import EnterpriseSystemSelect from "../enterpriseSystemSelect";
import ShowOpsValidationDetails from "./showOpsValidationDetails";

const gridColumns: ColumnDef<TValidationsConfig>[] = [
  {
    header: "Object Type",
    accessorKey: "objectTypeName",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Avtive",
    accessorKey: "active",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Validation Type",
    accessorKey: "validationTypeName",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Readline",
    accessorKey: "redlineCategory",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Message",
    accessorKey: "messageText",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  }
];

const gridColumnsGenerate = (openDrawer: (arg: TValidationsConfig) => void) => {
  const additionalGridColumn: ColumnDef<TValidationsConfig> = {
    header: "Code",
    accessorKey: "code",
    enableSorting: true,
    enableResizing: false,
    cell: arg => (
      <ColumnText>
        <button onClick={() => openDrawer(arg.row.original)} className="clickable-row" />
        {arg.getValue() as string}
      </ColumnText>
    )
  };
  return [additionalGridColumn, ...gridColumns];
};

const OpsValidation = () => {
  const { loading, error, validationConfigs } = useValidationConfigs();

  const rowclickHandler = (arg: TValidationsConfig) => {
    setShowDetailsOpen(true);
    setShowDetailsData(arg);
  };

  const columns = gridColumnsGenerate((arg: TValidationsConfig) => {
    rowclickHandler(arg);
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({}); //manage your own row selection state

  const [showDetailsOpen, setShowDetailsOpen] = useState(false);
  const [showDetailsData, setShowDetailsData] = useState<TValidationsConfig>();

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination,
      rowSelection
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: false
  };

  if (loading) {
    return <InlineLoadingPanel />;
  }
  if (error) {
    return <ApolloErrorViewer error={error} />;
  }

  return (
    <>
      <GlobalHeader
        pageName="Ops Validation"
        buttonContent={[<EnterpriseSystemSelect key={1} />]}
      />

      {validationConfigs && validationConfigs.length > 0 ? (
        <BaseTable
          columns={columns}
          data={validationConfigs}
          className="carboniq-data-table clickable-row-table"
          tableOptions={tableOptions}
        />
      ) : (
        <CarbonIQEmptyTable />
      )}

      {showDetailsOpen && (
        <ShowOpsValidationDetails
          onClose={() => setShowDetailsOpen(!showDetailsOpen)}
          details={showDetailsData}
        />
      )}
    </>
  );
};
export default OpsValidation;
