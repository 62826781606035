/* eslint-disable */
import React, { useState } from "react";

import { State as DataState } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import {
  GridColumn as Column,
  Grid,
  GridCellProps,
  GridDataStateChangeEvent,
  GridToolbar
} from "@progress/kendo-react-grid";
import { Label } from "@progress/kendo-react-labels";
import LoadingPanel from "../../../shared/components/LoadingPanel";
import ForecastSearchForm from "../forecastSearch/ForecastSearchForm";

import { Page } from "../../../shared/api/models";
import { fetchForecastExport, sendToEndur } from "../../api";
import { IForecastSearchCriteria, IGSEData } from "../../models";

import useCronDialog from "../../../hooks/useCronDialog";
import useFileDownloader from "../../../hooks/useFileDownloader";

import GlobalHeader from "global/sections/header";
import "./ForecastExport.css";

const RightAlignedCell = (props: GridCellProps) => {
  return <td style={{ textAlign: "right" }}>{Object(props.dataItem)[props.field!]}</td>;
};

const PAGE_SIZE_50 = 50;
const PAGE_SIZE_100 = 100;
const PAGE_SIZE_200 = 200;
interface IExportState {
  loading: boolean;
  showSearch: boolean;
  forecastData: Page<IGSEData> | null;
  apiError: string;
}

const ForecastExport = () => {
  const [exportState, setExportState] = useState<IExportState>({
    loading: false,
    forecastData: null,
    apiError: "",
    showSearch: true
  });

  const [downloadFile, downloaderComponentUI] = useFileDownloader();
  const [setCronVisible, cronDialogUI] = useCronDialog();
  const [labelVisible, setLableVisible] = useState(false);

  const [search, setSearch] = useState<IForecastSearchCriteria | null>(null);

  const [dataState, setDataState] = React.useState<DataState>({
    take: 100,
    skip: 0,
    sort: [{ field: "forecastStartDate", dir: "asc" }]
  });

  const dataStateChange = async (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
    setExportState({ ...exportState, loading: true });
    await onSearch(search!, e.dataState);
  };
  const getErrorMessage = (error: any) => {
    if (error?.response) {
      if (error.response.data?.errors) {
        //api error will have errors array
        return error.response.data.errors.join(";");
      }
      return "Error: Unknown Error";
    }
    return "Error: Network Error, please try later"; //when no response
  };

  const onSearch = async (searchState: IForecastSearchCriteria, gridState: DataState) => {
    try {
      setSearch(searchState);
      setLableVisible(false);
      const data = await fetchForecastExport(searchState, gridState);
      setExportState({
        loading: false,
        forecastData: data,
        apiError:
          data.totalElements === 0
            ? "No data found for given search criteria, please try again"
            : "",
        showSearch: data.totalElements === 0
      });
    } catch (error) {
      setExportState({
        loading: false,
        forecastData: null,
        apiError: getErrorMessage(error),
        showSearch: true
      });
    }
  };

  const onSubmit = async (e: IForecastSearchCriteria) => {
    setExportState({
      loading: true,
      forecastData: null,
      apiError: "",
      showSearch: false
    });
    setDataState({});
    await onSearch(e, {});
  };
  const onSearchOpen = async (e: boolean) => setExportState({ ...exportState, showSearch: e });

  const hasData = () => (exportState.forecastData?.totalElements ?? 0) > 0;
  const configAction = [
    {
      icon: "k-icon k-i-cog k-cursor-pointer k-pl-1 k-pr-1 k-color-primary",
      title: "Settings",
      click: () => setCronVisible()
    }
  ];

  const onDownloadClick = () => {
    setLableVisible(false);
    downloadFile({ query: search! });
  };
  const onSendToEndur = async () => {
    try {
      setExportState({ ...exportState, loading: true, apiError: "" });
      setLableVisible(false);
      await sendToEndur(search!);
      setExportState({ ...exportState, loading: false });
      setLableVisible(true);
    } catch (error) {
      setExportState({
        ...exportState,
        loading: false,
        apiError: getErrorMessage(error)
      });
    }
  };
  const getGridStyle = () => {
    return {
      height: `calc(100vh - ${exportState.showSearch ? "432px" : "116px"})`
    };
  };

  return (
    <div id="ForecastExport" className="forecast-page">
      <GlobalHeader pageName="Forecast Export" />

      {exportState.loading && <LoadingPanel />}
      <ForecastSearchForm
        showMonthlyToggle={false}
        onOpen={onSearchOpen}
        onSearch={onSubmit}
        isOpen={exportState.showSearch}
        actions={configAction}
      />
      {exportState.apiError && (
        <div className={"card-message form-error"}>
          <span>{exportState.apiError}</span>
        </div>
      )}
      {hasData() && (
        <div className={"content-wrapper"}>
          <div className={"grid-wrapper"}>
            <div className={"grid-container"}>
              <div className={"card-container"}>
                <Grid
                  data={exportState.forecastData?.content}
                  {...dataState}
                  pageable={
                    exportState.forecastData && exportState.forecastData.totalPages > 1
                      ? { pageSizes: [PAGE_SIZE_50, PAGE_SIZE_100, PAGE_SIZE_200] }
                      : false
                  }
                  pageSize={exportState.forecastData?.size}
                  skip={exportState.forecastData?.size! * exportState.forecastData?.number!}
                  take={exportState.forecastData?.size}
                  total={exportState.forecastData?.totalElements}
                  sortable={{ allowUnsort: true, mode: "multiple" }}
                  onDataStateChange={dataStateChange}
                  rowHeight={32}
                  style={getGridStyle()}>
                  <GridToolbar>
                    {labelVisible && (
                      <Label style={{ color: "#ff6358" }}>
                        Send to Endur Successfully completed!
                      </Label>
                    )}
                    <Button
                      themeColor={"primary"}
                      onClick={onSendToEndur}
                      disabled={exportState.loading || !hasData()}
                      className={"theme-btn-yellow"}>
                      Send to Endur
                    </Button>
                    <Button
                      themeColor={"primary"}
                      onClick={onDownloadClick}
                      disabled={exportState.loading || !hasData()}
                      className={"theme-btn-yellow"}>
                      Download
                    </Button>
                  </GridToolbar>
                  <Column field="planningCycle" title="Planning Cycle" width="88px" />
                  <Column
                    field="forecastStartDate"
                    title="Forecast Start&nbsp;Date"
                    width="96px"
                  />
                  <Column field="forecastEndDate" title="Forecast End&nbsp;Date" width="96px" />
                  <Column field="forecastType" title="Forecast Type" width="88px" />
                  <Column field="gsapOrgClass" title="GSAP Org&nbsp;Class" width="88px" />
                  <Column field="businessUnit" title="Business&nbsp;Unit" width="120px" />
                  <Column field="orgClass" title="Org&nbsp;Class" width="200px" />
                  <Column
                    field="gsapContractNum"
                    title="GSAP Contract&nbsp;Num"
                    width="120px"
                  />
                  <Column field="gsapMotMod" title="GSAP Mot&nbsp;Mod" width="80px" />
                  <Column
                    field="gsapPropertyNum"
                    title="GSAP Property&nbsp;Num"
                    width="120px"
                  />
                  <Column
                    field="gsapProductCode"
                    title="GSAP Product&nbsp;Code"
                    width="120px"
                  />
                  <Column
                    field="volumeFinished"
                    title="Volume Orginal"
                    width="96px"
                    cell={RightAlignedCell}
                  />
                  <Column
                    field="approvedVolume"
                    title="Volume Approved"
                    width="96px"
                    cell={RightAlignedCell}
                  />
                  <Column field="gsapUOM" title="GSAP UOM" width="80px" />
                  <Column
                    field="gsapProductCodeFinished"
                    title="GSAP Finished&nbsp;Product"
                    width="128px"
                  />
                  <Column
                    field="gsapUomFinished"
                    title="GSAP UOM&nbsp;Finished"
                    width="120px"
                  />
                  <Column
                    field="tmeSceVrsDte"
                    title="TME&nbsp;SCE VRS&nbsp;DATE"
                    width="160px"
                  />
                  <Column field="srcPhySysIsc" title="SRC&nbsp;PHY SYS&nbsp;ISC" />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      )}
      {cronDialogUI}
      {downloaderComponentUI}
    </div>
  );
};

export default ForecastExport;
