import { Flexbox, Heading, TextTypes } from "@sede-x/shell-ds-react-framework";
import HeaderDescription from "carbonIQ/headerDescription";
import { ReactNode } from "react";
import { EnvBadge } from "shared/components/EnvBadge";

const GlobalHeader = ({
  pageName,
  descriptiontxt,
  filterButtonContent,
  buttonContent
}: {
  pageName: string;
  descriptiontxt?: string;
  filterButtonContent?: ReactNode[];
  buttonContent?: ReactNode[];
}) => {
  return (
    <>
      <div className="common-header-wrapper">
        <Heading type={TextTypes.H2} bold className="common-header-heading">
          <Flexbox>
            {pageName}
            <EnvBadge />
            {filterButtonContent?.filter((elm: ReactNode) => !!elm)}
          </Flexbox>
        </Heading>
        {buttonContent && (
          <div className="header-btn-wrap">
            {buttonContent?.filter((elm: ReactNode) => !!elm)}
          </div>
        )}
      </div>
      {descriptiontxt && <HeaderDescription descriptiontxt={descriptiontxt} />}
    </>
  );
};
export default GlobalHeader;
