import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { GridItemChangeEvent } from "@progress/kendo-react-grid";
import { uploadTicketDocument } from "forecast/api";
import GlobalHeader from "global/sections/header";
import { GraphQLError } from "graphql";
import { loader } from "graphql.macro";
import { useCallback, useMemo, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import useValidationConfigs from "ticketing/hooks/useValidationConfigs";
import {
  GqlResponse,
  Picklists,
  TBatch,
  TDocumentUploadResult,
  TMovement,
  TMovementGroup
} from "ticketing/ticketing.types";
import { equalsIgnoreCase } from "ticketing/utils";
import InlineLoadingPanel from "../../../shared/components/InlineLoadingPanel";
import EnterpriseSystemSelect from "../enterpriseSystemSelect";
import { BulkImportFileDropZone } from "./BulkImportFileDropZone";
import { BulkImportTicketGrid } from "./BulkImportTicketGrid";
import { TicketResultsGrid } from "./TicketResultsGrid";
import { BatchCell } from "./cells/BatchCell";
import { CarrierCell } from "./cells/CarrierCell";
import { DateCell } from "./cells/DateCell";
import { DeliveryIdCell } from "./cells/DeliveryIdCell";
import { FacilityCell } from "./cells/FacilityCell";
import { NumericCell } from "./cells/NumericCell";
import { PdfFileNameCell } from "./cells/PdfFileNameCell";
import { PicklistCell } from "./cells/PicklistCell";
import { ProductCell } from "./cells/ProductCell";
import { ShipCodeCell } from "./cells/ShipCodeCell";
import { TextCell } from "./cells/TextCell";
import {
  BULK_IMPORT_FIELDS as FIELDS,
  TAllRefData,
  TBulkImportTicket,
  TFieldConfig,
  TPdfFile,
  TRefData,
  TTicketSaveResult
} from "./types";
import {
  BulkImportContext,
  getMovementsFilter,
  getRefNames,
  getVolSeq,
  lookupBatchRefData,
  lookupMovements,
  lookupRefData,
  lookupShipCode,
  merge,
  onTicketFieldUpdated,
  toLinkTicketInputs,
  toTicketInput,
  transformRefData
} from "./util";
import { validateBulkImportTicket } from "./validations";

type TTicketSaveResultData = GqlResponse<TTicketSaveResult[], "addTickets">;

const FETCH_POLICY_NO_CACHE = {
  fetchPolicy: "no-cache" as MutationFetchPolicy
};

const INIT_REF_DATA = loader("./graphql/initRefData.graphql");

const ADD_TICKETS = loader("./graphql/addTickets.graphql");
const BULK_LINK_TICKETS = loader("../../ticketing-graphql/bulkLinkTicketsToMovements.graphql");

type LinkTicketResponse = GqlResponse<TMovement[], "movements">;

const INIT_FIELDS: TFieldConfig[] = [
  {
    name: FIELDS.TICKET_NUMBER_FIELD_NAME,
    title: "Ticket(BOL)#",
    dataType: "string",
    width: "96px",
    cell: TextCell,
    isRequired: true
  },
  {
    name: FIELDS.TICKET_DATE_FIELD_NAME,
    title: "Ticket Date",
    dataType: "date",
    width: "120px",
    cell: DateCell,
    isRequired: true,
    resizable: true
  },
  {
    name: FIELDS.MOT_FIELD_NAME,
    title: "Mode Of Transport",
    dataType: "string",
    width: "120px",
    isPicklist: true,
    cell: PicklistCell,
    typeAhead: false,
    lookupName: "modeOfTransports",
    isRequired: true
  },
  {
    name: FIELDS.BATCH_FIELD_NAME,
    title: "Batch",
    dataType: "string",
    width: "120px",
    isRefData: true,
    cell: BatchCell,
    lookupName: "batches",
    typeAhead: true
  },
  {
    name: FIELDS.DELIVERY_ID_FIELD_NAME,
    title: "Delivery Id",
    dataType: "string",
    width: "120px",
    isRefData: true,
    lookupName: "movements",
    cell: DeliveryIdCell,
    typeAhead: true
  },
  {
    name: FIELDS.CARRIER_FIELD_NAME,
    title: "Carrier",
    dataType: "string",
    width: "120px",
    isRefData: true,
    cell: CarrierCell,
    lookupName: "carriers",
    typeAhead: true
  },
  {
    name: FIELDS.PRODUCT_FIELD_NAME,
    title: "Product",
    dataType: "string",
    width: "144px",
    isRefData: true,
    lookupName: "products",
    cell: ProductCell,
    typeAhead: true
  },
  {
    name: FIELDS.FACILITY_FIELD_NAME,
    title: "Location",
    dataType: "string",
    width: "144px",
    isRefData: true,
    lookupName: "facilities",
    cell: FacilityCell,
    typeAhead: true
  },
  {
    name: FIELDS.LOGISTICS_FIELD_NAME,
    title: "Logistics System",
    dataType: "string",
    width: "120px",
    lookupName: "logisticsSystems",
    cell: PicklistCell,
    typeAhead: false
  },
  {
    name: FIELDS.SHIP_FROM_CODE_FIELD_NAME,
    title: "Ship From Code",
    dataType: "shipCode",
    width: "120px",
    isRefData: true,
    lookupName: "shipFromCodes",
    cell: ShipCodeCell,
    typeAhead: true
  },
  {
    name: FIELDS.SHIP_TO_CODE_FIELD_NAME,
    title: "Ship To Code",
    dataType: "shipCode",
    width: "120px",
    isRefData: true,
    cell: ShipCodeCell,
    lookupName: "shipToCodes",
    typeAhead: true
  },
  {
    name: FIELDS.RAILCARS_FIELD_NAME,
    title: "Railcars",
    dataType: "string",
    width: "160px",
    cell: TextCell
  },
  {
    name: FIELDS.PO_FIELD_NAME,
    title: "PO Number",
    dataType: "string",
    width: "120px",
    cell: TextCell
  },
  {
    name: FIELDS.BORDER_CROSSING_DATE_FIELD_NAME,
    title: "Border Crossing Date",
    dataType: "date",
    width: "120px",
    cell: DateCell
  },
  {
    name: FIELDS.INVOICE_COMMENTS_FIELD_NAME,
    title: "Invoice Comments",
    dataType: "string",
    width: "160px",
    cell: TextCell
  },
  {
    name: FIELDS.PDF_FILE_FIELD_NAME,
    title: "File Name",
    dataType: "string",
    width: "144px",
    isPicklist: true,
    lookupName: "fileNames",
    typeAhead: false,
    isRequired: false,
    cell: PdfFileNameCell
  },
  {
    name: FIELDS.PDF_FILE_RANGE_FIELD_NAME,
    title: "Page Range",
    dataType: "string",
    width: "120px",
    isRequired: false,
    cell: TextCell
  },
  {
    name: FIELDS.VOLUME_GROUP_FIELD_NAME,
    title: "Volume 1",
    dataType: "volume",
    children: [
      {
        name: "volume1net",
        title: "Net",
        dataType: "number",
        width: "120px",
        cell: NumericCell,
        isRequired: true
      },
      {
        name: "volume1gross",
        title: "Gross",
        dataType: "number",
        width: "120px",
        cell: NumericCell,
        isRequired: true
      },
      {
        name: "volume1uom",
        title: "UOM",
        dataType: "string",
        width: "96px",
        isPicklist: true,
        lookupName: "unitOfMeasures",
        typeAhead: false,
        isRequired: true,
        cell: PicklistCell
      },
      {
        name: "volume1temp",
        title: "Temp",
        dataType: "number",
        width: "96px",
        isRequired: true,
        cell: NumericCell
      },
      {
        name: "volume1tempuom",
        title: "Temp UOM",
        dataType: "string",
        width: "96px",
        isPicklist: true,
        lookupName: "temperatureUOMs",
        typeAhead: false,
        isRequired: true,
        cell: PicklistCell
      }
    ]
  }
];

const DISABLE_EDIT_FIELDS = [
  FIELDS.BATCH_FIELD_NAME,
  FIELDS.PRODUCT_FIELD_NAME,
  FIELDS.FACILITY_FIELD_NAME
];

const EXPAND_FIELD = "expanded";

const copyVolumeGroup = (index: string | number): TFieldConfig | undefined => {
  const volGroupField = INIT_FIELDS.find(f => f.name === FIELDS.VOLUME_GROUP_FIELD_NAME);
  if (volGroupField) {
    return {
      ...volGroupField,
      name: volGroupField.name.replace("1", `${index}`),
      title: volGroupField.title.replace("1", `${index}`),
      children: volGroupField.children?.map(c => ({
        ...c,
        name: c.name.replace("1", `${index}`)
      }))
    };
  }
};

const transformVolumes = (
  ticket: TBulkImportTicket,
  uoms: Picklists.TUnitOfMeasure[] | undefined,
  temperatureUOMs: Picklists.TTemperatureUOM[] | undefined,
  vGroups: string[]
) => {
  //flattening all volumes
  return vGroups.reduce<Record<string, unknown>>((v, g) => {
    const uom = `volume${g}uom`;
    const tempuom = `volume${g}tempuom`;
    return {
      ...v,
      [uom]: {
        ...ticket[uom],
        value: uoms?.find(u => equalsIgnoreCase(u.name, ticket[uom]?.initial))
      },
      [tempuom]: {
        ...ticket[tempuom],
        value: temperatureUOMs?.find(u => equalsIgnoreCase(u.name, ticket[tempuom]?.initial))
      }
    };
  }, {});
};

const BulkImportTickets = () => {
  //Init Ref Data
  const [loadRefData, { loading: rLoading, error }] = useLazyQuery<TAllRefData>(INIT_REF_DATA, {
    ...FETCH_POLICY_NO_CACHE,
    onCompleted: data => onLoadRefDataCompleted(data)
  });

  const [saveTickets, { loading: saveTicketLoading }] = useMutation<TTicketSaveResultData>(
    ADD_TICKETS,
    FETCH_POLICY_NO_CACHE
  );

  const [bulkLinkTickets, { loading: linkTicketLoading }] = useMutation<LinkTicketResponse>(
    BULK_LINK_TICKETS,
    FETCH_POLICY_NO_CACHE
  );

  const { picklists } = usePicklists();
  const {
    loading: vLoading,
    error: vError,
    validationConfigs,
    userQueryCodes
  } = useValidationConfigs();
  const [columns, setColumns] = useState<TFieldConfig[]>(INIT_FIELDS);
  const [tickets, setTickets] = useState<TBulkImportTicket[]>([]);
  const [volumeGroups, setVolumeGroups] = useState<string[]>(["1"]);
  const [allRefData, setAllRefData] = useState<TAllRefData>({});
  const [saveErrors, setSaveErrors] = useState<ApolloError>();
  const [saveCompleted, setSaveCompleted] = useState(false);
  const [results, setResults] = useState<TTicketSaveResult[]>();

  const onLoadRefDataCompleted = (data: TAllRefData) => {
    const transformedRefData = transformRefData(data);
    setAllRefData(refData => ({ ...refData, ...transformedRefData }));
    setTickets(tickets.map(ticket => transformTicket(ticket, transformedRefData)));
  };
  const transformTicket = (ticket: TBulkImportTicket, data: TAllRefData): TBulkImportTicket => {
    //2786983
    //if deliveryId is supplied in the file and is valid
    // ...then use this movement's batch, product and location insted
    const movement = lookupMovements(data.movements, ticket.deliveryid?.initial);

    const transformedTicket = {
      ...ticket,
      deliveryid: {
        ...ticket.deliveryid,
        value: movement
      },
      batchname: {
        ...ticket.batchname,
        value:
          movement?.activeMovement?.batch ??
          lookupBatchRefData(
            data.batches,
            ticket.batchname?.initial,
            ticket?.modeoftransport?.initial
          )
      },
      modeoftransport: {
        ...ticket.modeoftransport,
        value: lookupRefData(picklists?.modeOfTransports, ticket.modeoftransport?.initial)
      },
      carriername: {
        ...ticket.carriername,
        value: lookupRefData(data.carriers, ticket.carriername?.initial)
      },
      productname: {
        ...ticket.productname,
        value:
          movement?.activeMovement?.product ??
          lookupRefData(data.products, ticket.productname?.initial)
      },
      locationname: {
        ...ticket.locationname,
        value:
          movement?.activeMovement?.titleTransferFacility ??
          lookupRefData(data.facilities, ticket.locationname?.initial)
      },
      logisticsname: {
        ...ticket.logisticsname,
        value: lookupRefData(picklists?.logisticsSystems, ticket.logisticsname?.initial)
      },
      shipfromcode: {
        ...ticket.shipfromcode,
        value: lookupShipCode(picklists?.shipFrom, ticket.shipfromcode?.initial)
      },
      shiptocode: {
        ...ticket.shiptocode,
        value: lookupShipCode(picklists?.shipTo, ticket.shiptocode?.initial)
      },
      filename: {
        ...ticket.filename,
        value: allRefData?.fileNames?.find(f =>
          equalsIgnoreCase(f.name, ticket.filename?.initial ?? "")
        )
      },
      ...transformVolumes(
        ticket,
        picklists?.unitOfMeasures,
        picklists?.temperatureUOMs,
        volumeGroups
      )
    };
    Object.assign(transformedTicket, { errors: validateTicket(transformedTicket) });
    return transformedTicket;
  };

  const processFields = (fields: string[]) => {
    const volumes: string[] = [
      ...new Set(
        fields
          .filter(f => f.toLowerCase().startsWith("volume"))
          .map(f => getVolSeq(f))
          .filter(Boolean)
          .filter(seq => seq && Number(seq))
      )
    ].sort((a, b) => a.localeCompare(b));

    const additionalColumns: TFieldConfig[] = [];
    if (volumes && volumes.length > 0) {
      //filter if already exists
      volumes
        .filter(i => volumeGroups.findIndex(v => v === i) < 0)
        .forEach(i => {
          const volGroupField = copyVolumeGroup(i);
          if (volGroupField) {
            additionalColumns.push(volGroupField);
          }
        });
    }
    if (additionalColumns && additionalColumns.length > 0) {
      setColumns([...columns, ...additionalColumns]);
    }
    setVolumeGroups([...new Set([...volumeGroups, ...volumes])]);
  };

  const validateTicket = useCallback(
    (t: TBulkImportTicket) =>
      validateBulkImportTicket(t, volumeGroups, picklists, validationConfigs, userQueryCodes),
    [picklists, volumeGroups, validationConfigs, userQueryCodes]
  );

  const validateTickets = useCallback(() => {
    setTickets(ts => ts.map(t => ({ ...t, errors: validateTicket(t) })));
  }, [setTickets, validateTicket]);

  //add to ref data,tickets and validate tickets
  const onNewPdfFile = useCallback(
    (f: TPdfFile) => {
      setAllRefData(data => ({
        ...data,
        fileNames: [...(data?.fileNames ?? []), f]
      }));

      setTickets(ts =>
        ts.map(t => {
          if (t.filename?.initial && equalsIgnoreCase(t.filename.initial, f.name)) {
            t.filename.value = f;
          }
          t.errors = validateTicket(t);
          if (!t.errors?.length) {
            t[EXPAND_FIELD] = false;
          }
          return t;
        })
      );
    },
    [setTickets, setAllRefData, validateTicket]
  );

  //on parse completed
  const onNewTicketsData = (fields?: string[], data?: TBulkImportTicket[]) => {
    //get disintct volume fields's index
    //ignore the first required one
    if (fields) {
      processFields(fields);
    }
    if (data) {
      const fabricatedId = new Date().getTime();
      const newTickets = data.map((t, i) => ({
        ...t,
        KEY: `T${fabricatedId}:${i}`
      }));
      const allTickets = [...tickets, ...newTickets];
      setTickets(allTickets); //add to state

      loadRefData({
        variables: {
          enterpriseSystemId: picklists?.enterpriseSystemId,
          modeOfTransportNames: getRefNames(allTickets, "modeoftransportname"),
          batchNames: getRefNames(allTickets, "batchname"),
          productNames: getRefNames(allTickets, "productname"),
          facilityNames: getRefNames(allTickets, "locationname"),
          logisticsSystemNames: getRefNames(allTickets, "logisticsname"),
          carrierNames: getRefNames(allTickets, "carriername"),
          movementsFilter: getMovementsFilter(allTickets, picklists)
        }
      });
    }
  };

  const saveChanges = async () => {
    try {
      //Upload documents used with in tickets
      const documents = await uploadDocuments();
      //save tickets
      const savedTickets = await addTickets(documents);
      //link tickets
      if (
        tickets.filter(t => (t.errors?.length ?? 0) === 0 && t.deliveryid?.value != null)
          .length > 0
      ) {
        const movementTickets = await linkTickets(savedTickets);
        setResults(
          savedTickets?.map(t => ({
            ...t,
            status: movementTickets?.some(mt => mt?.ticketId === t.id) ? "LINKED" : t.status,
            deliveryId: movementTickets?.find(mt => mt?.ticketId === t.id)?.deliveryId ?? ""
          }))
        );
      } else {
        setResults(savedTickets);
      }
      setSaveCompleted(true);
    } catch (errors) {
      setSaveErrors(new ApolloError(errors));
    } finally {
      reset(); //???
    }
  };

  const onUpload = async (file: File): Promise<TDocumentUploadResult[]> =>
    uploadTicketDocument(file, {});

  const uploadDocuments = async (): Promise<TDocumentUploadResult[]> => {
    //Upload documents used with in tickets
    const uploadDocRequests = allRefData.fileNames
      ?.filter(f => tickets.some(t => f.file.name === t.filename?.value?.name))
      .map(f => onUpload(f.file));

    if (uploadDocRequests?.length) {
      const uploadDocResponses = await Promise.all(uploadDocRequests);
      return uploadDocResponses.flat();
    }
    return [];
  };

  const addTickets = async (
    documents?: TDocumentUploadResult[] | null
  ): Promise<TTicketSaveResult[]> => {
    const ticketInputs = tickets
      .filter(t => !t.errors?.length)
      .map(t => toTicketInput(t, volumeGroups, documents));

    if (ticketInputs) {
      const saveTicketResponse = await saveTickets({
        variables: { tickets: ticketInputs }
      });
      if (saveTicketResponse.errors?.length) {
        throw saveTicketResponse.errors;
      }
      if (saveTicketResponse.data) {
        return saveTicketResponse.data.addTickets.filter((t): t is TTicketSaveResult =>
          Boolean(t)
        );
      }
    }
    return [];
  };

  const linkTickets = async (savedTickets: TTicketSaveResult[]) => {
    const linkTicketInputs = toLinkTicketInputs(tickets, savedTickets, picklists);
    const linkTicketResponse = await bulkLinkTickets({
      variables: {
        input: linkTicketInputs
      }
    });

    const errors = linkTicketResponse?.errors?.filter((e): e is GraphQLError => Boolean(e));
    if (errors?.length) {
      throw errors;
    }
    return linkTicketResponse.data?.movements.flatMap(m =>
      m.tickets.map(t => ({
        deliveryId: m.enterpriseSystemCode,
        ticketId: t.id!
      }))
    );
  };

  const updateRefData = useCallback(
    (event: GridItemChangeEvent) => {
      const field = event.field ?? "";
      const config = columns
        .flatMap(c => [c, ...(c.children ? c.children : [])])
        .filter(Boolean)
        .find(f => f.name === field);

      const isValidConfig = !!config && !!config?.isRefData && !!config?.lookupName;
      if (isValidConfig && event.value?.value) {
        const lookupName = config?.lookupName ?? "";

        if (lookupName === "movements" && event.value) {
          const newMovements = (event.value.value as TMovementGroup).movements;
          const movements = merge(allRefData.movements, newMovements) as TMovement[];
          const batches = merge(
            allRefData.batches,
            newMovements?.map(m => m.batch),
            (a, b) => a.id === b.id
          ) as TBatch[];
          const products = merge(
            allRefData.products,
            newMovements?.map(m => m.product),
            (a, b) => a.id === b.id
          ) as TRefData[];
          const facilities = merge(
            allRefData.facilities,
            newMovements?.map(m => m.titleTransferFacility),
            (a, b) => a.id === b.id
          ) as TRefData[];
          setAllRefData({ ...allRefData, movements, batches, products, facilities });
        } else if (
          lookupName &&
          ((allRefData[lookupName] as TRefData[] | TPdfFile[])?.findIndex(
            i => i.name === event.value.value.name
          ) ?? -1) < 0
        ) {
          const refData = [...(allRefData[lookupName] ?? []), event.value.value];
          setAllRefData({ ...allRefData, [lookupName]: refData });
        }
      }
    },
    [columns, allRefData, setAllRefData]
  );

  const onTicketUpdated = useCallback(
    (event: GridItemChangeEvent) => {
      if (event.field) {
        setTickets(ts =>
          ts.map(item => {
            if (item.KEY === event.dataItem.KEY) {
              item[event.field!] = event.value;
              item.inEdit = null;
              onTicketFieldUpdated(item, event.field!);
              item.errors = validateTicket(item);
              if (!item.errors?.length) {
                item[EXPAND_FIELD] = false;
              }
              updateRefData(event);
              validateTickets();
            }
            return item;
          })
        );
      }
    },
    [setTickets, updateRefData, validateTicket, validateTickets]
  );

  const reset = () => {
    setTickets([]);
    setColumns(INIT_FIELDS);
    setVolumeGroups(["1"]);
    setAllRefData({});
  };

  const onRestart = () => {
    reset();
    setSaveCompleted(false);
    setResults([]);
  };

  const enterEdit = useCallback(
    (dataItem: TBulkImportTicket, field: string | undefined) => {
      if (!dataItem.deliveryid?.value || !DISABLE_EDIT_FIELDS.includes(field! as FIELDS)) {
        setTickets(ts =>
          ts.map(item => ({
            ...item,
            inEdit: item.KEY === dataItem.KEY ? field : null
          }))
        );
      }
    },
    [setTickets]
  );

  const exitEdit = useCallback(() => {
    setTickets(ts =>
      ts.map(item => ({
        ...item,
        inEdit: null
      }))
    );
  }, [setTickets]);

  const onDeleteTicket = (index: number) => {
    const data = [...tickets];
    data.splice(index, 1);
    setTickets(data);
  };

  const expandChange = useCallback(
    (dataItem: TBulkImportTicket) => {
      setTickets(ts =>
        ts.map(item => {
          if (item.KEY === dataItem.KEY) {
            item[EXPAND_FIELD] = !dataItem[EXPAND_FIELD];
          }
          return item;
        })
      );
    },
    [setTickets]
  );

  const bulkImportContext = useMemo(
    () => ({
      fieldConfig: columns,
      refData: allRefData
    }),
    [columns, allRefData]
  );

  const hasTickets = () => (tickets?.length ?? 0) > 0;
  const isSaveInProgress = [saveTicketLoading, linkTicketLoading].some(l => l);

  if (vLoading) {
    return <InlineLoadingPanel></InlineLoadingPanel>;
  }

  if (vError) {
    return <ApolloErrorViewer error={vError} />;
  }

  return (
    <BulkImportContext.Provider value={bulkImportContext}>
      <GlobalHeader
        pageName="Bulk Import"
        descriptiontxt=""
        buttonContent={[<EnterpriseSystemSelect key={1} />]}
      />
      <div id="BulkImportTickets" className="bulk-import-tickets-page">
        <div className="search-wrapper">
          <div className={"card-container"}>
            {!saveCompleted && (
              <BulkImportFileDropZone
                pdfFiles={allRefData?.fileNames ?? []}
                onNewPdfFile={onNewPdfFile}
                onNewTicketsData={onNewTicketsData}
              />
            )}
          </div>
        </div>
        {rLoading && <InlineLoadingPanel />}
        {error && <ApolloErrorViewer error={error} />}
        {saveErrors && <ApolloErrorViewer error={saveErrors} />}
        {saveCompleted && (
          <TicketResultsGrid results={results} onReset={onRestart}></TicketResultsGrid>
        )}
        {hasTickets() && !saveErrors && (
          <BulkImportTicketGrid
            tickets={tickets}
            columns={columns}
            loading={isSaveInProgress}
            onSave={saveChanges}
            onReset={reset}
            enterEdit={enterEdit}
            exitEdit={exitEdit}
            onTicketUpdated={onTicketUpdated}
            onDeleteTicket={onDeleteTicket}
            expandChange={expandChange}
          />
        )}
      </div>
    </BulkImportContext.Provider>
  );
};
export default BulkImportTickets;
