import { gql, useQuery } from "@apollo/client";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GqlResponse, TEnterpriseSystem } from "types";
import { actionCreator } from "../../_redux/actionCreator";
import {
  ENTERPRISE_SYSTEMS,
  SELECT_ENTERPRISE_SYSTEM,
  SELECT_SYSTEM_DATE
} from "./../../ticketing/state/ticketingActionTypes";

const GET_ENTERPRISE_SYSTEMS = gql`
  query enterpriseSystems {
    enterpriseSystems {
      id
      name
    }
  }
`;
type TEnterpriseSystemResponse = GqlResponse<TEnterpriseSystem[], "enterpriseSystems">;

export const Header = (): ReactElement => {
  const dispatch = useDispatch();

  useQuery<TEnterpriseSystemResponse>(GET_ENTERPRISE_SYSTEMS, {
    onCompleted: data => {
      dispatch(actionCreator(ENTERPRISE_SYSTEMS, data.enterpriseSystems));
      if ((data.enterpriseSystems?.length ?? 0) > 0) {
        setEnterpriseSystemSelected(data.enterpriseSystems.at(0));
      }
    }
  });

  const [enterpriseSystemSelected, setEnterpriseSystemSelected] = useState<TEnterpriseSystem>();

  useEffect(() => {
    const environment =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_ENVIRONMENT
        : window._env_.REACT_APP_ENVIRONMENT;
    if (environment === "PRODUCTION") {
      dispatch(actionCreator(SELECT_SYSTEM_DATE, new Date()));
    }
  }, [dispatch]);

  useEffect(() => {
    if (enterpriseSystemSelected && Object.keys(enterpriseSystemSelected).length !== 0) {
      dispatch(actionCreator(SELECT_ENTERPRISE_SYSTEM, enterpriseSystemSelected));
    }
  }, [enterpriseSystemSelected, dispatch]);

  return <></>;
};
