import { useQuery } from "@apollo/client";
import { GridCellProps } from "@progress/kendo-react-grid";
import { loader } from "graphql.macro";
import { useState } from "react";
import { GqlResponse, TTicketVolumeRevision } from "ticketing/ticketing.types";
import RevisionsGridWrapper from "./RevisionsGridWrapper";

type Response = GqlResponse<
  TTicketVolumeRevision[],
  "volumesOfTicketAtRevision"
>;

const query = loader(
  "../../ticketing-graphql/volumesOfTicketAtRevision.graphql"
);

const LastModifiedByCell = (props: GridCellProps) => {
  const item = props.dataItem;
  const displayName = [
    item.lastModifiedBy.lastName,
    item.lastModifiedBy.firstName,
  ]
    .filter(Boolean)
    .join(", ");
  return <td title={item.lastModifiedBy.id}>{displayName}</td>;
};

const transformData = (ticketRevisionVolumes: TTicketVolumeRevision[]) => {
  return ticketRevisionVolumes?.map((trm) => ({
    ...trm,
    lastModifiedDate: new Date(trm.lastModifiedDate + "Z"),
  }));
};

type RevisionsTicketVolumesProps = {
  ticketId: string;
  revisionNumber: string;
};

/***
 *
 */
const RevisionsTicketVolumes = ({
  ticketId,
  revisionNumber,
}: RevisionsTicketVolumesProps) => {
  const [revisions, setRevisions] = useState<TTicketVolumeRevision[]>();
  const { loading, error } = useQuery<Response>(query, {
    fetchPolicy: "no-cache",
    variables: { ticketId, revisionNumber },
    onCompleted: (data) =>
      setRevisions(transformData(data.volumesOfTicketAtRevision)),
  });

  const childData = [
    { title: "Volume Id", field: "id", width: "140px" },
    {
      title: "Net Volume",
      field: "netVolume",
      width: "140px",
      format: "{0:n4}",
    },
    {
      title: "Gross Volume",
      field: "grossVolume",
      width: "140px",
      format: "{0:n4}",
    },
    {
      title: "Unit of Measure",
      field: "unitOfMeasure.name",
      width: "140px",
    },
    { title: "Temperature", field: "temperature", width: "140px" },
    {
      title: "Temperature UOM",
      field: "temperatureUnitOfMeasure",
      width: "140px",
    },
    {
      title: "Last Update Date",
      field: "lastModifiedDate",
      width: "140px",
      format: "{0:MM/dd/yy HH:mm:ss}",
    },
    {
      title: "Updated By",
      field: "lastUpdateUser",
      width: "140px",
      cell: LastModifiedByCell,
    },
  ];

  return (
    <RevisionsGridWrapper
      loading={loading}
      error={error}
      dataContent={revisions}
      columns={childData}
    />
  );
};

export default RevisionsTicketVolumes;
