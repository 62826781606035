import { ApolloError } from "@apollo/client";
import { Label } from "@progress/kendo-react-labels";

export const ApolloErrorViewer = ({ error }: { error: ApolloError | ApolloError[] }) => {
  const errors = Array.isArray(error) ? error : [error];
  const graphQLErrorMessages = new Set(
    errors.flatMap(e => e.graphQLErrors.map(graphqlError => graphqlError.message))
  );
  const netWorkErrorMessages = new Set(
    errors.flatMap(e => e.networkError?.message).filter(Boolean)
  );

  return (
    <div className="error-message">
      {[...graphQLErrorMessages].map(msg => (
        <Label key={msg}>{msg}</Label>
      ))}
      {[...netWorkErrorMessages].map(msg => (
        <Label key={msg}>`[Network error]: ${msg}`</Label>
      ))}
    </div>
  );
};
