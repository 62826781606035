import { ITicketingState } from "../../_redux/IReduxState";

import { TEnterpriseSystem } from '../../types';

import {
  SELECT_ENTERPRISE_SYSTEM,
  ENTERPRISE_SYSTEMS,
  SELECT_SYSTEM_DATE
} from "./ticketingActionTypes";

const initState: ITicketingState = {
  selectedEnterpriseSystem: { id: 0, name: "" },
  systemDateSelected: new Date(),
  enterpriseSystems: [],
};

export default function ticketingReducer(state: ITicketingState = initState, action: { type: string; payload: Date | TEnterpriseSystem | TEnterpriseSystem[] }) {
  switch (action.type) {
    case ENTERPRISE_SYSTEMS:
      return {
        ...state,
        enterpriseSystems: action.payload as TEnterpriseSystem[],
      };
    case SELECT_ENTERPRISE_SYSTEM:
      return {
        ...state,
        selectedEnterpriseSystem: action.payload as TEnterpriseSystem,
      };
    case SELECT_SYSTEM_DATE:
      return {
        ...state,
        systemDateSelected: action.payload,
      };
    default:
      return state;
  }
}
