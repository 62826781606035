import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import {
  CellOptions,
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup
} from "@progress/kendo-react-excel-export";
import {
  GridColumn as Column,
  getSelectedState,
  Grid,
  GridCellProps,
  GridHeaderSelectionChangeEvent,
  GridNoRecords,
  GridPageChangeEvent,
  GridRowProps,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
  GridToolbar
} from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import calculateSize from "calculate-size";
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { TBulkTicketUnlinkInput, TLinkedTicket, TTicket } from "ticketing/ticketing.types";
import AddOrUpdateTicket from "./AddOrUpdateTicket";

export type TGridTicket = Omit<TLinkedTicket, "actualizedVolume" | "railcars"> & {
  grossVolume: number;
  railcars?: string;
  netVolume: number;
  volumeUnit: string;
  deliveryIds: string;
  transitComplete?: boolean;
};

type TSelectedState = { [id: string]: boolean | number[] };
const deliveryIdsColumn = {
  title: "Delivery Ids",
  field: "deliveryIds"
};

const columns = [
  { title: "Source", field: "source" },
  { title: "Ticket Number", field: "ticketNumber" },
  {
    title: "Ticket Date",
    field: "startDate"
  },
  { title: "Last Updated By", field: "lastModifiedBy.id" },
  {
    title: "Last Updated At",
    field: "lastModifiedDate"
  },
  { title: "Status", field: "status" },
  {
    title: "Volume",
    field: "",
    Children: [
      {
        title: "Net",
        field: "netVolume",
        format: "{0:0.####}",
        className: "!k-text-right",
        exportFormat: "0.0###"
      },
      {
        title: "Gross",
        field: "grossVolume",
        format: "{0:0.####}",
        className: "!k-text-right",
        exportFormat: "0.0###"
      },
      { title: "Unit", field: "volumeUnit" }
    ]
  },
  { title: "Ship From", field: "shipFromCode.description" },
  { title: "Ship To", field: "shipToCode.description" },

  { title: "Batch", field: "batch.name" },
  { title: "MoT", field: "modeOfTransport.name" },
  { title: "Location", field: "facility.name" },
  { title: "Product", field: "product.name" },
  { title: "Carrier", field: "carrier.name" },
  { title: "Logistics System", field: "logisticsSystem.name" },
  { title: "Rail Cars", field: "railcars" },
  {
    title: "Border Crossing Date",
    field: "borderCrossingDate",
    width: "96px"
  },
  { title: "PO Number", field: "poNumber", exportFormat: null },
  { title: "Invoice Comment", field: "invoiceComment" },
  { title: "File Name", field: "document.fileName" },
  { title: "Page Numbers", field: "pageNumbers" }
];

const DATA_ITEM_KEY: string = "id";
const SELECTED_FIELD: string = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const ConditionalCellRender = (
  td: React.ReactElement<HTMLTableCellElement> | null,
  props: GridCellProps
) => {
  if (
    props.field === SELECTED_FIELD &&
    props.dataItem.status === "LINKED" &&
    (!props.dataItem.movements?.length || props.dataItem.transitComplete)
  ) {
    return (
      <td>
        <Checkbox disabled={true} hidden={true} />
      </td>
    );
  } else {
    return td;
  }
};

const PADDING = 16;
// pass the font properties based on the application
const CalculateSizeOptions = {
  font: "Segoe UI",
  fontSize: "14px"
};

type TPageState = {
  skip: number;
  take: number;
};
const initialSort: Array<SortDescriptor> = [{ field: "ProductName", dir: "asc" }];

interface EditCommandCellProps extends GridCellProps {
  enterEdit: (item: TTicket) => void;
}

const EditCommandCell = (props: EditCommandCellProps) => {
  return (
    <td>
      <Button
        fillMode={"flat"}
        themeColor={"primary"}
        icon="edit"
        type="button"
        size={"small"}
        disabled={props.dataItem.status === "LINKED"}
        hidden={props.dataItem.status === "LINKED"}
        onClick={() => props.enterEdit(props.dataItem)}
      />
    </td>
  );
};

const makeEditCell = (props: EditCommandCellProps) => <EditCommandCell {...props} />;
const cellOptions: CellOptions = {
  borderBottom: { size: 1 },
  borderLeft: { size: 1 },
  borderRight: { size: 1 },
  borderTop: { size: 1 },
  fontFamily: "arial",
  fontSize: 12
};
const headerCellOptions: CellOptions = {
  ...cellOptions,
  bold: true,
  textAlign: "center"
};

const customRowRender = (
  trElement: React.ReactElement<HTMLTableRowElement>,
  props: GridRowProps
) => {
  const transitComplete = !!props.dataItem.transitComplete;
  const red = { backgroundColor: "rgb(243, 23, 0, 0.32)" };
  const trProps = { style: { ...trElement.props.style, ...red }, title: "Transit completed" };
  return React.cloneElement(
    trElement,
    { ...(transitComplete ? trProps : {}) },
    trElement.props.children as ReactNode
  );
};

/**
 *
 * @param param0
 * @returns
 */
export const TicketingGrid = ({
  tickets,
  onRefresh,
  onDelete,
  onUnlink,
  loading
}: {
  tickets: TGridTicket[];
  onRefresh: () => void;
  onDelete: (ticketsIds: string[]) => void;
  onUnlink: (ticketUnlinkInput: TBulkTicketUnlinkInput[]) => void;
  loading: boolean;
}) => {
  const _export = useRef<ExcelExport | null>(null);
  const [page, setPage] = useState<TPageState>({ skip: 0, take: 25 });
  const [sort, setSort] = useState(initialSort);
  const [showEditTicketForm, setShowEditTicketForm] = useState<boolean>(false);
  const [editTicket, setEditTicket] = useState<TTicket>();

  const excelExport = () => {
    if (_export.current != null) {
      _export.current.save();
    }
  };

  const pageChange = (event: GridPageChangeEvent) => {
    setPage(event.page);
  };

  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});

  useEffect(() => {
    setSelectedState({});
  }, [tickets]);

  const calculateWidth = useCallback(
    (field: string, title: string) => {
      let maxWidth = calculateSize(title, CalculateSizeOptions).width + PADDING;
      if (field) {
        tickets.forEach(item => {
          const valueGetter = getter(field);
          const value = valueGetter(item);
          const size = calculateSize(value, CalculateSizeOptions).width + PADDING;
          if (size > maxWidth) {
            maxWidth = size;
          }
        });
      }
      return maxWidth;
    },
    [tickets]
  );

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState,
        dataItemKey: DATA_ITEM_KEY
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback((event: GridHeaderSelectionChangeEvent) => {
    const checkboxElement = event.syntheticEvent.target;
    //@ts-ignore
    const checked = checkboxElement.checked;
    const newSelectedState = {} as TSelectedState;

    event.dataItems
      .filter(item => !item.transitComplete)
      .forEach(item => {
        newSelectedState[idGetter(item)] = checked;
      });
    setSelectedState(newSelectedState);
  }, []);

  const getSelectedTicketIds = useCallback(() => {
    return Object.entries(selectedState)
      .filter(item => item[1])
      .map(item => item[0]);
  }, [selectedState]);

  const handleDelete = () => {
    const selectedTicketIds = getSelectedTicketIds();
    if (selectedTicketIds.length > 0) {
      setSelectedState({});
      onDelete(selectedTicketIds);
    }
  };

  const handleUnlink = useCallback(() => {
    const selectedTicketIds = getSelectedTicketIds();
    if (selectedTicketIds.length > 0) {
      const input = tickets
        .filter(t => selectedTicketIds.includes(t.id) && t.movements?.length)
        .flatMap(t =>
          t.movements?.map(m => ({
            id: m.id,
            version: m.version,
            ticketId: t.id
          }))
        )
        .reduce<TBulkTicketUnlinkInput[]>((a, i) => {
          const movmentInput = a.find(m => m.movements.some(k => k.movementId === i?.id));
          if (movmentInput) {
            movmentInput.ticketIds.push(i?.ticketId!);
          } else {
            a.push({
              movements: [{ movementId: i?.id!, version: i?.version! }],
              ticketIds: [i?.ticketId!]
            });
          }
          return a;
        }, []);
      onUnlink(input);
    }
  }, [getSelectedTicketIds, onUnlink, tickets]);

  const enterEdit = useCallback((item: TTicket) => {
    setShowEditTicketForm(true);
    setEditTicket(item);
  }, []);

  const hasTickets = !!tickets?.length;
  const hasSelectedTickets = Object.entries(selectedState).some(s => s[1]);
  const canUnlink =
    tickets.length > 0 &&
    tickets.some(t => t.status === "LINKED" && t.movements?.length && !t.transitComplete);

  const canDelete = tickets.length > 0 && tickets.some(t => t.status !== "LINKED");

  return (
    <div style={{ height: "100%" }}>
      <div className={"content-wrapper"} style={{ height: "100%" }}>
        <div className={"grid-wrapper"} style={{ display: "flex", height: "100%" }}>
          <div
            className={"grid-container"}
            style={{
              flex: "1 1 auto",
              overflow: "hidden",
              height: "100%",
              width: "0"
            }}>
            <div className={"card-container"} style={{ height: "100%" }}>
              <ExcelExport data={tickets} ref={_export} fileName={"tickets.xlsx"}>
                {canUnlink && (
                  <ExcelExportColumn
                    field={deliveryIdsColumn.field}
                    title={deliveryIdsColumn.title}
                    key={deliveryIdsColumn.field}
                    cellOptions={cellOptions}
                    headerCellOptions={headerCellOptions}
                  />
                )}
                {columns.map(column =>
                  column.Children ? (
                    <ExcelExportColumnGroup
                      title={column.field || column.title}
                      headerCellOptions={headerCellOptions}
                      key={column.field || column.title}>
                      {column.Children.map(child => (
                        <ExcelExportColumn
                          field={child.field}
                          title={child.title}
                          key={child.field}
                          cellOptions={{ ...cellOptions, format: child.exportFormat }}
                          headerCellOptions={headerCellOptions}
                        />
                      ))}
                    </ExcelExportColumnGroup>
                  ) : (
                    <ExcelExportColumn
                      field={column.field}
                      title={column.title}
                      key={column.field}
                      cellOptions={{ ...cellOptions, format: column.exportFormat ?? undefined }}
                      headerCellOptions={headerCellOptions}
                    />
                  )
                )}
              </ExcelExport>
              <Grid
                rowRender={customRowRender}
                cellRender={ConditionalCellRender}
                style={{ height: "100%" }}
                size={"small"}
                // data={resultState}
                data={orderBy(
                  tickets.slice(page.skip, page.take + page.skip).map(item => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)]
                  })),
                  sort
                )}
                skip={page.skip}
                take={page.take}
                total={tickets.length}
                pageable={Boolean(tickets?.length)}
                onPageChange={pageChange}
                sortable={{ allowUnsort: false, mode: "single" }}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                  setPage({ skip: 0, take: 25 });
                  setSort(e.sort);
                }}
                dataItemKey="id"
                selectedField={SELECTED_FIELD}
                selectable={{
                  enabled: false,
                  drag: false,
                  cell: false,
                  mode: "multiple"
                }}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                resizable
                reorderable>
                <GridToolbar className="!k-justify-content-flex-start">
                  <Button
                    title="Delete"
                    disabled={loading || !hasSelectedTickets}
                    hidden={!canDelete}
                    icon={"delete"}
                    onClick={handleDelete}
                    themeColor={"primary"}>
                    Delete
                  </Button>
                  <Button
                    title="Unlink"
                    disabled={loading || !hasSelectedTickets}
                    hidden={!canUnlink}
                    icon={"delete"}
                    onClick={handleUnlink}
                    themeColor={"primary"}>
                    Unlink
                  </Button>
                  <Button
                    fillMode={"flat"}
                    disabled={loading}
                    hidden={!hasTickets}
                    icon={"excel"}
                    onClick={excelExport}
                    title="Export to Excel"
                    themeColor={"success"}
                  />
                </GridToolbar>
                <GridNoRecords>There is no data available</GridNoRecords>
                <Column
                  field={SELECTED_FIELD}
                  hidden={!(canDelete || canUnlink)}
                  headerSelectionValue={
                    !tickets
                      .filter(item => !item.transitComplete)
                      .some(item => !selectedState[idGetter(item)])
                  }
                />
                <Column
                  cell={props => makeEditCell({ ...props, enterEdit })}
                  width={"36"}
                  hidden={canUnlink}
                />
                <Column
                  field={deliveryIdsColumn.field}
                  title={deliveryIdsColumn.title}
                  key={deliveryIdsColumn.field}
                  headerClassName={"k-justify-content-center k-color-warning"}
                  width={calculateWidth(deliveryIdsColumn.field, deliveryIdsColumn.title)}
                  hidden={!canUnlink}></Column>
                {columns.map(column => (
                  <Column
                    key={column.field || column.title}
                    minResizableWidth={96}
                    {...column}
                    headerClassName={"k-justify-content-center k-color-warning"}
                    width={calculateWidth(column.field, column.title)}>
                    {column.Children?.map(child => (
                      <Column
                        key={child.field}
                        {...child}
                        headerClassName={"k-justify-content-center k-color-success"}
                        width={calculateWidth(child.field, child.title)}></Column>
                    ))}
                  </Column>
                ))}
              </Grid>
            </div>
            {showEditTicketForm && (
              <AddOrUpdateTicket
                onCancel={() => setShowEditTicketForm(false)}
                onSuccess={() => {
                  setShowEditTicketForm(false);
                  onRefresh();
                }}
                activeTicket={editTicket}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketingGrid;
