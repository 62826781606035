import { gql, useQuery } from "@apollo/client";
import {
  Button,
  Dropdown,
  Menu,
  MenuItem,
  Positions,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { ChevronDown } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { TCIQOption } from "carbonIQ/carbonIQtypes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GqlResponse, TEnterpriseSystem } from "types";
import { actionCreator } from "../../_redux/actionCreator";
import {
  ENTERPRISE_SYSTEMS,
  SELECT_ENTERPRISE_SYSTEM,
  SELECT_SYSTEM_DATE
} from "../state/ticketingActionTypes";

const GET_ENTERPRISE_SYSTEMS = gql`
  query enterpriseSystems {
    enterpriseSystems {
      id
      name
    }
  }
`;

type TEnterpriseSystemResponse = GqlResponse<TEnterpriseSystem[], "enterpriseSystems">;

const EnterpriseSystemSelect = () => {
  const dispatch = useDispatch();

  useQuery<TEnterpriseSystemResponse>(GET_ENTERPRISE_SYSTEMS, {
    onCompleted: data => {
      setEnterpriseSystemList(
        data.enterpriseSystems.map(elm => {
          return {
            value: elm?.name,
            label: elm?.name,
            key: elm?.id
          };
        })
      );
      dispatch(actionCreator(ENTERPRISE_SYSTEMS, data.enterpriseSystems));
      if ((data.enterpriseSystems?.length ?? 0) > 0) {
        const tempData = data.enterpriseSystems.at(0);
        if (tempData) {
          setEnterpriseSystemSelected({
            value: tempData?.name,
            label: tempData?.name,
            key: tempData?.id
          });
        }
      }
    }
  });

  const [enterpriseSystemList, setEnterpriseSystemList] = useState<TCIQOption[]>();
  const [enterpriseSystemSelected, setEnterpriseSystemSelected] = useState<TCIQOption>();

  useEffect(() => {
    const environment =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_ENVIRONMENT
        : window._env_.REACT_APP_ENVIRONMENT;
    if (environment === "PRODUCTION") {
      dispatch(actionCreator(SELECT_SYSTEM_DATE, new Date()));
    }
  }, [dispatch]);

  useEffect(() => {
    if (enterpriseSystemSelected && Object.keys(enterpriseSystemSelected).length !== 0) {
      dispatch(
        actionCreator(SELECT_ENTERPRISE_SYSTEM, {
          id: enterpriseSystemSelected.key,
          name: enterpriseSystemSelected.value
        })
      );
    }
  }, [enterpriseSystemSelected, dispatch]);

  const overlay = (
    <Menu>
      {enterpriseSystemList?.map(elm => (
        <MenuItem
          key=""
          onClick={() => {
            setEnterpriseSystemSelected(elm);
          }}>
          {elm.value}
        </MenuItem>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={overlay} trigger={["click"]}>
      <Button
        variant={Variants.Transparent}
        icon={<ChevronDown />}
        iconPosition={Positions.Right}>
        {enterpriseSystemSelected?.value}
      </Button>
    </Dropdown>
  );
};

export default EnterpriseSystemSelect;
