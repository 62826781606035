import { useMutation } from "@apollo/client";
import {
  Drawer,
  FormField,
  Heading,
  Loading,
  Sentiments,
  Sizes,
  TextInput,
  TextTypes,
  Toggle,
  Tree,
  TreeNode,
  Variants
} from "@sede-x/shell-ds-react-framework";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import { loader } from "graphql.macro";
import { useState } from "react";
import { IMACkPrivilege, IMACkRole } from "../../../auth";
import "../user.css";

const ADD_ROLE = loader("../graphql/mutation-add-role.graphql");
const UPDATE_ROLE = loader("../graphql/mutation-update-role.graphql");

interface IDefaultTreeData {
  key: string;
  title: string;
  isExpanded: boolean;
  isChecked: boolean | "indeterminate";
  children?: IDefaultTreeData[];
}

interface IInitialValues {
  name: string;
  description: string;
  active: boolean;
}

const buildPrivilegesTree = (privileges: IMACkPrivilege[], rolePrivilageIds: string[]) => {
  const calcLevel0CheckedState = (s: string) => {
    const tempTotalElms = privileges.filter((p: IMACkPrivilege) => p.serviceName === s);

    const tempCheckedElms = tempTotalElms.filter(e =>
      rolePrivilageIds?.includes(String(e.privilegeId))
    );

    if (tempCheckedElms.length === 0) {
      return false;
    } else if (tempCheckedElms.length === tempTotalElms.length) {
      return true;
    }
    return "indeterminate";
  };

  const calcLevel1CheckedState = (s: string, g: string) => {
    const tempTotalElms = privileges.filter(p => p.serviceName === s && p.group === g);

    const tempCheckedElms = tempTotalElms.filter(e =>
      rolePrivilageIds?.includes(String(e.privilegeId))
    );

    if (tempCheckedElms.length === 0) {
      return false;
    } else if (tempCheckedElms.length === tempTotalElms.length) {
      return true;
    }
    return "indeterminate";
  };

  const tempDefaultTreeData: IDefaultTreeData[] = [
    ...new Set(privileges.map(p => p.serviceName))
  ].map(s => ({
    key: s,
    title: s,
    isExpanded: !!calcLevel0CheckedState(s),
    isChecked: calcLevel0CheckedState(s),
    children: [...new Set(privileges.filter(p => p.serviceName === s).map(p => p.group))].map(
      g => ({
        key: `${s}_${g}`,
        title: g,
        isExpanded: !!calcLevel1CheckedState(s, g),
        isChecked: calcLevel1CheckedState(s, g),
        children: privileges
          .filter(p => p.serviceName === s && p.group === g)
          .map(p => ({
            key: `${p.privilegeId}`,
            title: p.name,
            isChecked: rolePrivilageIds?.includes(String(p.privilegeId)),
            isExpanded: false
          }))
      })
    )
  }));

  return tempDefaultTreeData;
};

const RoleEdit = (props: {
  role: IMACkRole;
  privileges: IMACkPrivilege[];
  onEditDone: (done: IMACkRole | null) => void;
  onClose: () => void;
}) => {
  const [addRole, { loading: aLoading, error: aError }] = useMutation(ADD_ROLE, {
    fetchPolicy: "no-cache"
  });
  const [updateRole, { loading: uLoading, error: uError }] = useMutation(UPDATE_ROLE, {
    fetchPolicy: "no-cache"
  });

  const [checkedPrivileges, setCheckedPrivileges] = useState<string[] | undefined>(
    props.role.privileges?.map((p: IMACkPrivilege) => String(p.privilegeId))
  );

  const [initialValues, setInitialValues] = useState<IInitialValues>({
    name: props.role.name,
    description: props.role.description ?? "",
    active: props.role.active
  });

  const onCheckChanged = (event: TreeNode) => {
    const tempCheckChangeIDs = handleTreeCheckChange(event);

    let tempData: string[] | undefined = checkedPrivileges?.filter(
      item => !tempCheckChangeIDs.includes(item)
    );

    tempData = event.isChecked ? tempData?.concat(tempCheckChangeIDs) : tempData;

    setCheckedPrivileges(tempData);
  };

  const handleTreeCheckChange = (event: TreeNode, keyToreturn: string = "key") => {
    let tempcheckedIds: string[] = [];

    if (event.children) {
      event.children.forEach((elm: TreeNode) => {
        tempcheckedIds = tempcheckedIds.concat(handleTreeCheckChange(elm));
      });
    } else {
      tempcheckedIds = tempcheckedIds.concat([String(event[keyToreturn as keyof TreeNode])]);
    }

    return tempcheckedIds;
  };

  const onSubmitClick = async () => {
    const privileges = props.privileges
      .filter((p: IMACkPrivilege) => checkedPrivileges?.includes(String(p?.privilegeId)))
      .map((p: IMACkPrivilege) => ({
        privilegeId: p.privilegeId,
        name: p.name,
        group: p.group,
        serviceName: p.serviceName
      }));

    const role = { ...initialValues, privileges };

    if (props.role.id) {
      updateRole({
        variables: {
          role: {
            ...role,
            id: props.role.id,
            version: props.role.version
          }
        },
        onCompleted: data => {
          props.onEditDone(data?.role ?? null);
        }
      });
    } else {
      addRole({
        variables: { role },
        onCompleted: data => {
          props.onEditDone(data?.role ?? null);
        }
      });
    }
  };

  const [open, setOpen] = useState(true);

  const handleOnClose = () => {
    props.onClose();
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);

  const isLoading = [loading, aLoading, uLoading].some(elm => elm);

  return (
    <Drawer
      header={<Heading type={TextTypes.H2}>Edit Roles</Heading>}
      borders={false}
      closeButton={false}
      sticky
      mask={true}
      open={open}
      size={Sizes.Medium}
      onClose={() => {
        handleOnClose();
      }}
      actions={[
        {
          label: "Cancel",
          action: () => {
            props.onEditDone(null);
            handleOnClose();
          },
          props: {
            variant: Variants.Outlined
          }
        },
        {
          label: "Save",
          action: () => {
            setLoading(true);
            onSubmitClick();
          }
        }
      ]}>
      <CommonErrorComponent error={[aError, uError]} />

      {isLoading && (
        <div className="loading-wrapper">
          <Loading />
        </div>
      )}
      <div className={""}>
        <form className="setting-form-container">
          <FormField
            size={"medium"}
            id="name-label"
            mandatory={true}
            label="Name"
            bottomLeftHelper={{
              content: !initialValues?.name ? <b>Field can't be blank.</b> : "",
              sentiment: Sentiments.Negative
            }}>
            <TextInput
              size={Sizes.Medium}
              onChange={event => {
                setInitialValues({
                  ...initialValues,
                  name: event.target.value
                });
              }}
              value={initialValues?.name ?? ""}
              invalid={!initialValues?.name}
            />
          </FormField>

          <FormField
            size={"medium"}
            id="description-label"
            mandatory={true}
            label="Description"
            bottomLeftHelper={{
              content: !initialValues?.description ? <b>Field can't be blank.</b> : "",
              sentiment: Sentiments.Negative
            }}>
            <TextInput
              size={Sizes.Medium}
              onChange={event => {
                setInitialValues({
                  ...initialValues,
                  description: event.target.value
                });
              }}
              value={initialValues?.description ?? ""}
              invalid={!initialValues?.description}
            />
          </FormField>

          <FormField size={"medium"} id="role-status-label" label="Role Status">
            <Toggle
              size={Sizes.Large}
              checked={initialValues?.active}
              onChange={() => {
                setInitialValues({
                  ...initialValues,
                  active: !initialValues?.active
                });
              }}
            />
          </FormField>
          <FormField size={"medium"} id="role-privilleges-label" label="Privilleges">
            <div className="hidden-scrollbar">
              <Tree
                treeData={buildPrivilegesTree(props.privileges, checkedPrivileges ?? [])}
                isCheckable
                size={Sizes.Small}
                onCheck={(event: TreeNode) => {
                  onCheckChanged(event);
                }}
              />
            </div>
          </FormField>
        </form>
      </div>
    </Drawer>
  );
};
export default RoleEdit;
