export interface INotificationSearchCriteria {
  startDate: Date;
  endDate: Date;
  goToType?: ChronoUnit;
}

export enum ChronoUnit {
  Day,
  Week,
  Month,
  Year
}
export interface INotification {
  id: number;
  version: number;
  notifier: INotifier;
  title: string;
  message: string;
  read?: boolean;
  readAt?: string;
  expiresAt?: string;
  createdDate?: string;
  wasRead?: boolean;
  expand?: boolean;
}

export interface INotifier {
  id: number;
  serviceName: string;
  entityName: string;
  eventType: string;
  descrition: string;
}
