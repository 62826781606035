import { useQuery } from "@apollo/client";
import {
  Badge,
  BaseTable,
  Button,
  Loading,
  Sentiments,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  AddSquare,
  CrossCircle,
  EditOutlined,
  Search
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  ColumnDef,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { MackPrivilege } from "auth";
import { useMackAuth } from "auth/AuthenticationProvider";
import { ROWS_PER_PAGE } from "carbonIQ/CarbonIQConstants";
import {
  CIQOption,
  TProductBlendFetchComponentsData,
  TProductBlendFetchData
} from "carbonIQ/carbonIQtypes";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import ColumnText from "global/elements/columnText";
import GlobalHeader from "global/sections/header";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import { GqlResponse } from "types";
import CarbonIQEmptyTable from "../emptyTable";
import ProductBlendSearch from "./ProductBlendSearch";
import AddOrUpdateProductBlend from "./addOrUpdateProductBlend";

const carbonGenericProductsFilterBy = loader(
  "../graphql/query-CarbonGenericProductsBlendFilterBy.graphql"
);
const FETCH_POLICY_NO_CACHE = "no-cache";

type TGenericProductsFilterByResponse = GqlResponse<
  TProductBlendFetchData[],
  "ciGenericProductsFilterBy"
>;

const gridColumns: ColumnDef<TProductBlendFetchData>[] = [
  {
    header: "Generic Product Name",
    accessorKey: "name",
    enableSorting: true,
    size: 300,
    cell: arg => <ColumnText width={"300px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Component",
    accessorKey: "components",
    size: 500,
    enableSorting: true,
    cell: arg => {
      const tempVal = arg.getValue() as TProductBlendFetchComponentsData[];
      return (
        <p>
          {tempVal
            .filter((elm: { blendPercent: number }) => elm.blendPercent > 0)
            .map((elm: TProductBlendFetchComponentsData) => {
              return `${elm.name} - ${elm.blendPercent}%`;
            })
            .join(", ")}
        </p>
      );
    }
  }
];

const gridColumnsGenerate = (
  clickcallback: (arg: {
    row: {
      original: Partial<TProductBlendFetchData>;
    };
  }) => void,
  editSelected: number | undefined,
  hasEditPermission: boolean | undefined = false
) => {
  if (!hasEditPermission) {
    return gridColumns;
  } else {
    const additionalGridColumn = {
      header: "",
      accessorKey: "id",
      cell: (arg: { row: { original: Partial<TProductBlendFetchData> } }) => (
        <Button
          className={arg.row.original.id === editSelected ? "editedRow" : ""}
          title="Edit"
          size={Sizes.Small}
          onClick={() => clickcallback(arg)}>
          <EditOutlined />
          Edit
        </Button>
      ),
      size: 40,
      enableSorting: false
    };
    return [...gridColumns, additionalGridColumn];
  }
};

const CarbonIQProductBlend = () => {
  const { mackUser } = useMackAuth();

  const hasAddProductBlendPrivilege = mackUser?.hasPrivilege(MackPrivilege.AddCIGenericProduct);

  const hasModifyProductBlendPrivilege = mackUser?.hasPrivilege(
    MackPrivilege.ModifyCIGenericProduct
  );
  const [searchOpen, setSearchOpen] = useState(false);
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<CIQOption[]>([]);

  const [customFilterDataList, setCustomFilterDataList] = useState<
    TProductBlendFetchData[] | undefined
  >([]);
  const [editSelected, setEditSelected] = useState<number>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applyCustomFilter = () => {
    if (selectedSearchFilter?.length > 0) {
      const tempData = selectedSearchFilter?.map((el: CIQOption) => el.key);

      const tempData1 = genericProducts?.ciGenericProductsFilterBy.filter(
        (elm: TProductBlendFetchData) => {
          return tempData.includes(elm.id);
        }
      );

      setCustomFilterDataList(tempData1);
    } else {
      setCustomFilterDataList(genericProducts?.ciGenericProductsFilterBy);
    }
  };

  //Fetching Carbon IQ  Product Blend data
  const {
    loading: lCIQDocument,
    error: eCIQDocument,
    data: genericProducts,
    refetch
  } = useQuery<TGenericProductsFilterByResponse>(carbonGenericProductsFilterBy, {
    fetchPolicy: FETCH_POLICY_NO_CACHE
  });

  const [addOrUpdateOpen, setAddOrUpdateOpen] = useState(false);
  const [updateOpenProductDetails, setUpdateOpenProductDetails] =
    useState<TProductBlendFetchData | null>(null);

  useEffect(() => {
    applyCustomFilter();
  }, [applyCustomFilter, genericProducts?.ciGenericProductsFilterBy, selectedSearchFilter]);

  const columns = gridColumnsGenerate(
    arg => {
      setEditSelected(arg.row.original.id as number);
      setUpdateOpenProductDetails(arg.row.original as TProductBlendFetchData);
      setAddOrUpdateOpen(true as boolean);
    },
    editSelected,
    hasModifyProductBlendPrivilege
  );

  const [sorting, setSorting] = useState<SortingState>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false
  };

  const handlAddOrUpdateSubmit = () => {
    refetch();
    setAddOrUpdateOpen(false);
  };

  const handleAddNewProductClick = () => {
    setUpdateOpenProductDetails(null);
    setAddOrUpdateOpen(true);
  };

  const isLoading = [lCIQDocument].some(elm => elm);

  const handleSearchSubmit = (data: CIQOption[]) => {
    setSelectedSearchFilter(data);
  };

  return (
    <>
      <CommonErrorComponent error={eCIQDocument} />
      {isLoading && (
        <div className="loading-wrapper">
          <Loading />
        </div>
      )}

      <GlobalHeader
        pageName="Product Blend"
        filterButtonContent={[
          Object.keys(selectedSearchFilter).length > 0 && (
            <span className="customer-Search-current-filter">
              <span className="customer-Search-current-filter-txt">
                {selectedSearchFilter?.map((elm: CIQOption) => elm.value).join(", ")}
              </span>
              <button
                className="customer-Search-reset"
                onClick={() => {
                  setSelectedSearchFilter([]);
                  setPagination({
                    pageIndex: 0,
                    pageSize: ROWS_PER_PAGE
                  });
                }}>
                <CrossCircle />
                Clear Filter
              </button>
            </span>
          )
        ]}
        descriptiontxt="Please use this screen to add Generic Products and their associated components."
        buttonContent={[
          <Badge
            key={1}
            icon={<Search />}
            sentiment={Sentiments.Information}
            variant={Variants.Filled}
            onClick={() => setSearchOpen(!searchOpen)}
          />,
          hasAddProductBlendPrivilege && (
            <Badge
              key={2}
              icon={<AddSquare />}
              sentiment={Sentiments.Information}
              variant={Variants.Filled}
              onClick={() => handleAddNewProductClick()}>
              Add New Product Blend
            </Badge>
          )
        ]}
      />

      {customFilterDataList && customFilterDataList?.length > 0 ? (
        <BaseTable
          columns={columns}
          data={customFilterDataList}
          className="carboniq-data-table"
          tableOptions={tableOptions}
          key={"productsBlend-table-key"}
        />
      ) : (
        <CarbonIQEmptyTable />
      )}
      {addOrUpdateOpen && (
        <AddOrUpdateProductBlend
          onClose={() => setAddOrUpdateOpen(!addOrUpdateOpen)}
          onSubmit={() => handlAddOrUpdateSubmit()}
          details={updateOpenProductDetails}
        />
      )}
      {searchOpen && (
        <ProductBlendSearch
          onClose={() => setSearchOpen(!searchOpen)}
          onSubmit={(data: CIQOption[]) => {
            handleSearchSubmit(data);
          }}
          masterData={genericProducts?.ciGenericProductsFilterBy}
        />
      )}
    </>
  );
};

export default CarbonIQProductBlend;
