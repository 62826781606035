import { ApolloError, useQuery } from "@apollo/client";
import {
  Badge,
  BaseTable,
  Button,
  Sentiments,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  AddSquare,
  CheckCircleSolid,
  CrossCircleSolid,
  EditOutlined
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  ColumnDef,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { ROWS_PER_PAGE } from "carbonIQ/CarbonIQConstants";
import CarbonIQEmptyTable from "carbonIQ/emptyTable";
import ColumnText from "global/elements/columnText";
import GlobalHeader from "global/sections/header";
import { loader } from "graphql.macro";
import { useState } from "react";
import { IMACkPrivilege, IMACkRole } from "../../../auth";
import InlineLoadingPanel from "../../../shared/components/InlineLoadingPanel";
import RoleEdit from "./RoleEdit";

const GET_ALL_ROLES = loader("../graphql/query-roles.graphql");
const GET_ALL_PRIVILEGES = loader("../graphql/query-privileges.graphql");

interface IRolesData {
  roles: IMACkRole[];
}

interface IPrivilegesData {
  privileges: IMACkPrivilege[];
}

const gridColumns: ColumnDef<IMACkRole>[] = [
  {
    header: "Name",
    accessorKey: "name",
    enableSorting: true,
    enableResizing: false,
    cell: arg => <ColumnText width={"150px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Description",
    accessorKey: "description",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText width={"500px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Status",
    accessorKey: "active",
    enableResizing: false,
    enableSorting: true,
    cell: arg => (
      <p style={{ width: "150px" }}>
        {(arg.getValue() as boolean) ? (
          <CheckCircleSolid fill={"var(--forest-500)"} />
        ) : (
          <CrossCircleSolid fill={"var(--red-500)"} />
        )}
      </p>
    )
  }
];

const gridColumnsGenerate = (
  clickcallback: (arg: {
    row: {
      original: Partial<IMACkRole>;
    };
  }) => void
) => {
  const additionalGridColumn = {
    header: "",
    accessorKey: "id",
    size: 50,
    enableSorting: false,
    cell: (arg: { row: { original: Partial<IMACkRole> } }) => (
      <div className="cta-button-wrapper" style={{ width: "150px" }}>
        <span>
          <Button title="Edit" size={Sizes.Small} onClick={() => clickcallback(arg)}>
            <EditOutlined />
            Edit
          </Button>
        </span>
      </div>
    )
  };

  return [...gridColumns, additionalGridColumn];
};

const UserRole = () => {
  const { loading: rLoading } = useQuery<IRolesData>(GET_ALL_ROLES, {
    fetchPolicy: "no-cache",
    errorPolicy: "none",
    onError: e => setError(e),
    onCompleted: data => setRoles(JSON.parse(JSON.stringify(data?.roles ?? [])))
  });
  const { loading: pLoading, data: privielegesData } = useQuery<IPrivilegesData>(
    GET_ALL_PRIVILEGES,
    {
      fetchPolicy: "no-cache",
      onError: e => setError(e)
    }
  );

  const [error, setError] = useState<ApolloError>();
  const [roles, setRoles] = useState<IMACkRole[]>([]);
  const [selectedRole, setSelectedRole] = useState<IMACkRole | null>();

  const onNewRole = () => {
    setSelectedRole({
      id: 0,
      version: 0,
      name: "",
      description: "",
      active: true,
      privileges: []
    });
    setAddOrUpdateOpen(true);
  };

  const onEditRole = (role: IMACkRole) => {
    setSelectedRole(role);
    setAddOrUpdateOpen(true);
  };

  const onEditDone = (result: IMACkRole | null) => {
    if (result != null) {
      if (roles.findIndex(r => r.id === result.id) > -1) {
        setRoles(roles.map(r => (r.id === result.id ? result : r))); //replace
      } else {
        setRoles([...roles, result]); //add
      }
    }
    setSelectedRole(null);
    setAddOrUpdateOpen(false);
  };

  const isLoading = [rLoading, pLoading].some(l => l);

  const columns = gridColumnsGenerate(arg => {
    onEditRole(arg.row.original as IMACkRole);
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false
  };
  const [addOrUpdateOpen, setAddOrUpdateOpen] = useState(false);

  return (
    <>
      <GlobalHeader
        pageName="Manage Roles"
        buttonContent={[
          <Badge
            key={1}
            icon={<AddSquare />}
            sentiment={Sentiments.Information}
            variant={Variants.Filled}
            onClick={() => {
              onNewRole();
            }}>
            Add New Role
          </Badge>
        ]}
      />

      {isLoading && <InlineLoadingPanel />}
      {error && <div className={"error-message"}>{error?.message ?? ""}</div>}

      {roles.length > 0 ? (
        <BaseTable
          columns={columns}
          data={roles}
          className="carboniq-data-table"
          tableOptions={tableOptions}
        />
      ) : (
        <CarbonIQEmptyTable />
      )}

      {addOrUpdateOpen && selectedRole && (
        <RoleEdit
          role={selectedRole}
          privileges={privielegesData?.privileges ?? []}
          onEditDone={onEditDone}
          onClose={() => setAddOrUpdateOpen(!addOrUpdateOpen)}
        />
      )}
    </>
  );
};
export default UserRole;
