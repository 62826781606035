import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  FormField,
  Loading,
  Modal,
  Select,
  Sentiments,
  TextInput,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  Add,
  MinusCircle
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  TComponentsFilter,
  TProductBlendFetchComponentsData,
  TProductBlendFetchData
} from "carbonIQ/carbonIQtypes";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import { GqlResponse } from "types";

const CarbonGenericProductsList = loader("../graphql/lookupComponents.graphql");

const ciAddNewGenericProduct = loader("../graphql/mutation-CarbonAddNewGenericProduct.graphql");

const ciUpdateGenericProduct = loader("../graphql/mutation-CarbonUpdateGenericProduct.graphql");

const FETCH_POLICY_CACHE_FIRST = "cache-first";

type TComponentsFilterByResponse = GqlResponse<TComponentsFilter[], "ciComponentsFilterBy">;

const AddOrUpdateProductBlend = ({
  details,
  onClose,
  onSubmit
}: {
  details: TProductBlendFetchData | null;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  const [open, setOpen] = useState(true);
  const handleOnClose = () => {
    onSubmit();
    onClose();
    setOpen(false);
  };

  const numberInputOnWheelPreventChange = (e: any) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };
  const [components, setComponents] = useState<TComponentsFilter[]>([]);
  const [loading, setLoading] = useState(false);

  //Fetching Carbon IQ  Product Blend Components List
  const { loading: lCIQDocument, error: eCIQDocument } = useQuery<TComponentsFilterByResponse>(
    CarbonGenericProductsList,
    {
      fetchPolicy: FETCH_POLICY_CACHE_FIRST,
      onCompleted: data => {
        setComponents(data.ciComponentsFilterBy);
      },
      onError: () => {
        setLoading(false);
      }
    }
  );

  const [totalSumOfPercentage, setTotalSumOfPercentage] = useState<number>(0);

  const isStateAdd: boolean = details === null || details === undefined;

  // used spread operator to avoid call be refrence
  const [preFillBlendPercentDetails, setPreFillBlendPercentDetails] = useState<
    TProductBlendFetchComponentsData[] | []
  >(!details ? [] : [...details.components]);

  const [preFillProductName, setPreFillProductName] = useState(isStateAdd ? "" : details?.name);

  useEffect(() => {
    let tempSum = 0;
    preFillBlendPercentDetails.forEach((elm: TProductBlendFetchComponentsData) => {
      tempSum += elm.blendPercent;
    });
    setTotalSumOfPercentage(Math.round((tempSum + Number.EPSILON) * 100) / 100);
  }, [preFillBlendPercentDetails]);

  // Add New Generic Product
  const [
    addNewGenericProduct,
    { loading: addNewGenericProductLoading, error: addNewGenericProductlError }
  ] = useMutation(ciAddNewGenericProduct, {
    fetchPolicy: "no-cache",
    onCompleted: () => handleOnClose(),
    onError: () => {
      setLoading(false);
    }
  });

  // Update New Generic Product
  const [
    updateGenericProduct,
    { loading: updateGenericProductLoading, error: updateGenericProductError }
  ] = useMutation(ciUpdateGenericProduct, {
    fetchPolicy: "no-cache",
    onCompleted: () => handleOnClose(),
    onError: () => {
      setLoading(false);
    }
  });

  const handleDeleteBlend = (index: number) => {
    preFillBlendPercentDetails.splice(index, 1);
    setPreFillBlendPercentDetails([...preFillBlendPercentDetails]);
  };

  const isLoading = [
    loading,
    lCIQDocument,
    addNewGenericProductLoading,
    updateGenericProductLoading
  ].some(elm => elm);

  return (
    <Modal
      title={(isStateAdd ? "Add New" : "Update") + " Product"}
      // description= 'You should accept this fake text if you want to continue',
      width={"700px"}
      height={"600px"}
      mask={true}
      open={open}
      onClose={() => {
        handleOnClose();
      }}>
      <CommonErrorComponent
        error={[eCIQDocument, addNewGenericProductlError, updateGenericProductError]}
      />

      {!isStateAdd && <p>Updating product - Warrning message</p>}
      <form
        onSubmit={event => {
          event.preventDefault();
          setLoading(true);
          const tempdata = {
            name: preFillProductName?.trim(),
            components: preFillBlendPercentDetails
              .filter(elm => elm.blendPercent >= 0)
              .map(elm => ({
                blendPercent: elm.blendPercent,
                componentId: elm.id
              }))
          };

          isStateAdd
            ? addNewGenericProduct({
                variables: {
                  product: tempdata
                }
              })
            : updateGenericProduct({
                variables: {
                  product: {
                    ...tempdata,
                    id: details?.id,
                    version: details?.version
                  }
                }
              });
        }}>
        <FormField
          size={"medium"}
          id="product-name-label"
          label="Generic Product Name *"
          bottomLeftHelper={{
            content: preFillProductName === "" ? "Field can't be blank." : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              setPreFillProductName(event.target.value);
            }}
            invalid={preFillProductName === ""}
            value={preFillProductName}
            required
          />
        </FormField>

        {preFillBlendPercentDetails.map(
          (element: TProductBlendFetchComponentsData, index: number) => {
            return (
              <div className="form-flex-container" key={"form-flex-container_" + element.id}>
                <div className="left">
                  <FormField
                    size={"medium"}
                    id="generic-product-name-label"
                    label={"Blend " + (index + 1)}
                    mandatory={true}
                    key={element.id}>
                    <Select
                      options={components
                        .filter((elm: TComponentsFilter) => {
                          return !preFillBlendPercentDetails
                            .map((el: TProductBlendFetchComponentsData) => el.id)
                            .includes(elm.id);
                        })
                        .map((prod: TComponentsFilter) => ({
                          value: prod.name,
                          label: prod.name,
                          key: prod.id
                        }))}
                      size={"medium"}
                      id="generic-product-name-input"
                      placeholder="Select a product"
                      optionLabelProp="label"
                      filterOption={true}
                      optionFilterProp="label"
                      labelInValue={true}
                      allowClear={false}
                      value={preFillBlendPercentDetails[index]?.name ?? undefined}
                      onChange={data => {
                        preFillBlendPercentDetails.splice(index, 1, {
                          id: data.key,
                          name: data.value,
                          blendPercent: 0
                        });
                        setPreFillBlendPercentDetails([...preFillBlendPercentDetails]);
                      }}
                    />
                  </FormField>
                </div>
                <div className="right">
                  <span
                    className="delete-icon"
                    onClick={() => {
                      handleDeleteBlend(index);
                    }}>
                    <MinusCircle fill={"#ff0000"} />
                  </span>
                  <FormField
                    size={"medium"}
                    key={"11"}
                    id={"product-percent-11"}
                    label={" (%)"}>
                    <TextInput
                      type={"number"}
                      value={preFillBlendPercentDetails[index].blendPercent ?? 0}
                      max={100}
                      min={0}
                      step=".01"
                      onWheel={numberInputOnWheelPreventChange}
                      onChange={event => {
                        preFillBlendPercentDetails.splice(index, 1, {
                          id: preFillBlendPercentDetails[index].id,
                          name: preFillBlendPercentDetails[index].name,

                          blendPercent:
                            Math.round((Number(event.target.value) + Number.EPSILON) * 100) /
                            100
                        });
                        setPreFillBlendPercentDetails([...preFillBlendPercentDetails]);
                      }}
                    />
                  </FormField>
                </div>
              </div>
            );
          }
        )}

        {preFillBlendPercentDetails.length < components.length && (
          <div>
            <Button
              size={"xsmall"}
              icon={<Add />}
              variant={Variants.Outlined}
              onClick={() => {
                setPreFillBlendPercentDetails([
                  ...preFillBlendPercentDetails,
                  { id: null, name: null, blendPercent: 0 }
                ]);
              }}>
              Add New Blend
            </Button>
          </div>
        )}

        {preFillBlendPercentDetails.length > 0 && (
          <p>Total percentage: {totalSumOfPercentage} %</p>
        )}
        <div className="from-button-wrap">
          <Button
            variant={Variants.Outlined}
            onClick={() => {
              handleOnClose();
            }}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={totalSumOfPercentage !== 100 || preFillProductName === ""}>
            Save
          </Button>
        </div>
      </form>
      {isLoading && (
        <div className="loading-wrapper">
          <Loading />
        </div>
      )}
    </Modal>
  );
};

export default AddOrUpdateProductBlend;
