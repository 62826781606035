import { Error as ErrorLabel } from "@progress/kendo-react-labels";
import logo from "../shared/assets/shell-logo.png";
import { IError } from "./models";

const LoginError = (props: IError) => {
  document.title = "Login Error - MACk";
  return (
    <div>
      <header className="header">
        <div className="nav-container">
          <div>
            <img src={logo} alt="logo" className="logo" />
          </div>
          <div className="title">
            <h1>MACk</h1>
            <span className="vl"></span>
            <h3>{props.error?.errorDescription}</h3>
          </div>
        </div>
      </header>
      <div style={{ backgroundColor: "black", height: "100vh" }}>
        <div className="k-centered">
          <ErrorLabel>
            <span style={{ fontSize: "2rem", margin: "auto" }}>
              {props.error?.errorDescription}
            </span>
          </ErrorLabel>
          <ErrorLabel>
            <span style={{ fontSize: ".8rem", margin: "auto" }}>
              {props.error?.errorCode}
            </span>
          </ErrorLabel>
        </div>
      </div>
    </div>
  );
};
export default LoginError;
