import { ApolloClient, InMemoryCache, from, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import ApolloLinkTimeout from "apollo-link-timeout";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { createClient } from "graphql-ws";

import { createFragmentRegistry } from "@apollo/client/cache";
import { loader } from "graphql.macro";
import { getAuthHeader } from "./auth";

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Operation Name: ${operation.operationName}`
      )
    );
  }
});

const MAX_GRAPHQL_TIMEOUT_MS = 30_000;
const timeoutLink = new ApolloLinkTimeout(MAX_GRAPHQL_TIMEOUT_MS); // 30 second timeout

// https://www.apollographql.com/docs/react/networking/authentication/#header
const authLink = setContext(async (_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return { headers: { Authorization: getAuthHeader(), ...headers } };
});

const baseURL =
  (process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_GATEWAY_URI
    : window._env_.REACT_APP_GATEWAY_URI) + "/graphql";

const httpLink = createUploadLink({ uri: baseURL });
const timeoutHttpLink = timeoutLink.concat(httpLink);
const wsLink = new GraphQLWsLink(
  createClient({
    url: baseURL.replace(/^https?:\/\//, match => (match === "https://" ? "wss://" : "ws://")),
    keepAlive: 10_000,
    connectionParams: async () => {
      return {
        authToken: getAuthHeader()
      };
    }
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  timeoutHttpLink
);

const MOVEMENTS_FRAGMENT = loader("./ticketing/ticketing-graphql/fragment-movement.graphql");

const TICKETS_FRAGMENT = loader("./ticketing/ticketing-graphql/fragment-ticket.graphqls");

export const client = new ApolloClient({
  link: from([errorLink, authLink.concat(splitLink)]),
  cache: new InMemoryCache({
    fragments: createFragmentRegistry(MOVEMENTS_FRAGMENT, TICKETS_FRAGMENT)
  })
});
