import { useQuery } from "@apollo/client";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid,
  GridColumn,
  GridNoRecords,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { loader } from "graphql.macro";
import { useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import {
  GqlResponse,
  TMovement,
  TMovementAlert,
  TMovementGroup,
} from "ticketing/ticketing.types";

const QUERY_MOVEMENT_ALERTS = loader(
  "../../ticketing-graphql/alertsOfMovement.graphql"
);

type Response = GqlResponse<Partial<TMovement[]>, "movements">;
type MovementAlertsProps = {
  movementGroup: TMovementGroup;
  onClose: () => void;
};

const transformData = (movements: Partial<TMovement[]>): TMovementAlert[] => {
  const alerts = movements.flatMap((m) => m?.alerts ?? []);
  return [...new Map(alerts.map((item) => [item!.code, item!])).values()];
};

export const MovementAlerts = ({
  movementGroup,
  onClose,
}: MovementAlertsProps) => {
  const [movementAlerts, setMovementAlerts] = useState<TMovementAlert[]>();
  const { loading, error } = useQuery<Response>(QUERY_MOVEMENT_ALERTS, {
    fetchPolicy: "no-cache",
    variables: { ids: movementGroup.movements.map((m) => m.id) },
    onCompleted: (data) => setMovementAlerts(transformData(data.movements)),
  });
  if (loading) {
    return <InlineLoadingPanel />;
  }
  if (error) {
    return <ApolloErrorViewer error={error} />;
  }

  return (
    <>
      <div className={"content-wrapper"}>
        <div className={"grid-wrapper"}>
          <div className={"card-container"}>
            <Grid data={movementAlerts} resizable dataItemKey="code">
              <GridToolbar>
                <Button themeColor={"primary"} onClick={onClose}>
                  Close
                </Button>
              </GridToolbar>
              <GridNoRecords>
                <div style={{ color: "gray" }}>There is no data available</div>
              </GridNoRecords>
              <GridColumn title="Category" field="category" />
              <GridColumn title="Code" field="code" />
              <GridColumn title="Description" field="desc" />
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
