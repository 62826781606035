import { useMutation } from "@apollo/client";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { Label } from "@progress/kendo-react-labels";
import { loader } from "graphql.macro";
import { useContext, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import { GqlResponse, TBatch, TMovement } from "ticketing/ticketing.types";
import { equalsIgnoreCase } from "ticketing/utils";
import BatchRevisions from "./RevisionsBatch";
import { MovementsMainContext } from "./utils";

export type BatchComponentProps = {
  batch: TBatch;
  isSelected: boolean;
};

const UPDATE_BATCH_TRANSIT_COMPLETE = loader(
  "../../ticketing-graphql/updateBatchTransitComplete.graphql"
);

const MOVEMENT_STATUS_ACTUALIZED = "actualized";
const MOVEMENT_STATUS_CANCELLED = "Cancelled";
const SERVICE_PROVIDER_GAIN_LOSS = "gain/loss";

/**
 * @dev
 * the biz rules are -
 * 1.   show the icon if transitDealNum > 0
 * 2.   if transitComplete show the lock icon, else the unlock
 * 08/20/2022
 * 3. 1365633 - Transit Complete Validation: Do not allow action when unactualized movements exist
 */
type Response = GqlResponse<TBatch, "updateTransitComplte">;
export default function BatchComponent({ batch, isSelected }: Readonly<BatchComponentProps>) {
  const { onBatchSelected, onBatchUpdated } = useContext(MovementsMainContext);
  const [updateBatch, { loading, error }] = useMutation<Response>(
    UPDATE_BATCH_TRANSIT_COMPLETE,
    {
      onCompleted: data => onBatchUpdated?.(data.updateTransitComplte)
    }
  );

  const [showBatchRevisions, setShowBatchRevisions] = useState(false);
  const [showTransitCompleteWarningMessage, setShowTransitCompleteWarningMessage] =
    useState(false);

  const handleCloseRevisions = () => {
    setShowBatchRevisions(false);
  };

  const updateBatchToggleTransitComplete = () => {
    updateBatch({
      variables: {
        batch: {
          id: batch.id,
          version: batch.version,
          transitComplete: !batch.transitComplete
        }
      }
    });
  };

  const onConfirmMarkTransitComplete = () => {
    setShowTransitCompleteWarningMessage(false);
    updateBatchToggleTransitComplete();
  };
  const onCancelTransitComplete = () => {
    setShowTransitCompleteWarningMessage(false);
  };

  const shouldShowTransitCompleteWarning =
    batch?.movements?.some(m => m.isActualizedExternally) && !batch.transitComplete;

  const handleToggleTransitComplete = () => {
    //2765307
    if (shouldShowTransitCompleteWarning) {
      setShowTransitCompleteWarningMessage(true);
    } else {
      updateBatchToggleTransitComplete();
    }
  };

  const handleBatchSelected = () => {
    if (!isSelected) {
      onBatchSelected?.(batch);
    }
  };

  const isValidMovement = (m: Partial<TMovement>) =>
    equalsIgnoreCase(m?.status?.name, MOVEMENT_STATUS_ACTUALIZED) ||
    equalsIgnoreCase(m?.status?.name, MOVEMENT_STATUS_CANCELLED) ||
    (m.tickets?.length ?? 0) > 0 ||
    Math.round(m.scheduledQuantity ?? 0) === 0.0 ||
    equalsIgnoreCase(m?.activityType?.serviceProvider, SERVICE_PROVIDER_GAIN_LOSS);

  const shouldDisableTransitComplete = () => {
    if (batch.movements && batch.movements.length > 0) {
      //disable transit complete if there is any invalid movement
      return batch.movements.filter(m => !isValidMovement(m)).length > 0;
    }
    //disable if no valid movements - possible if all movements have been cancelled
    return true;
  };

  const hideTransitComplete = (batch.transitDealNum ?? 0) <= 0;
  const className = isSelected ? "selected" : "";
  const logisticsSystemColor = isSelected ? "red" : "#ED3EFA";
  const logisticsSystemData =
    batch?.transport?.at(0)?.carriages?.[0]?.name && isSelected
      ? `${batch?.logisticsSystem?.name} (${batch?.transport?.at(0)?.carriages[0]?.name})`
      : batch?.logisticsSystem?.name;

  return (
    <>
      {showBatchRevisions && (
        <BatchRevisions batchId={batch.id} onClose={handleCloseRevisions}></BatchRevisions>
      )}
      <div
        className={`${className} movement-batch-comp-outter k-m-2 k-p-1 k-rounded`}
        onClick={handleBatchSelected}>
        <div className="movement-batch-comp-wrapper">
          <div className="movement-batch-comp-label-wrapper k-white-space-pre ">
            <Label className="k-font-semibold k-user-select-none">{batch?.name}</Label>
            <Button
              fillMode={"flat"}
              icon="copy"
              className="copy-btn"
              color="rgb(0, 120, 212)"
              onClick={e => {
                navigator.clipboard.writeText(batch?.name);
                e.stopPropagation();
              }}
            />
            <Label className="k-user-select-none" style={{ color: `${logisticsSystemColor}` }}>
              {logisticsSystemData}
            </Label>
          </div>
          {isSelected && (
            <div className="movement-batch-comp-btn-wrap">
              <Button
                icon={batch?.transitComplete ? "lock" : "unlock"}
                disabled={shouldDisableTransitComplete()}
                fillMode="flat"
                title="Toggle transit complete"
                onClick={handleToggleTransitComplete}
                hidden={hideTransitComplete}></Button>
              <Button
                icon="clock"
                fillMode="flat"
                title="View Revisions"
                onClick={() => setShowBatchRevisions(true)}></Button>
            </div>
          )}
        </div>
        {loading && <Loader size="small" type={"infinite-spinner"} />}
        {error && <ApolloErrorViewer error={error} />}
        {showTransitCompleteWarningMessage && (
          <Dialog title={"Transit Complete"} onClose={onCancelTransitComplete}>
            <div className="component-title" style={{ padding: "16px" }}>
              This batch has nominations that have been actualized externally. Please confirm
              setting Transit Complete?
            </div>
            <DialogActionsBar>
              <Button icon="cancel" onClick={onCancelTransitComplete}>
                Cancel
              </Button>
              <Button
                themeColor={"primary"}
                icon="check"
                onClick={onConfirmMarkTransitComplete}>
                Confirm
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </>
  );
}
