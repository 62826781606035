import { toString as toKString } from "@progress/kendo-intl";
import { createContext } from "react";
import {
  TBatch,
  TDocumentUploadResult,
  TicketSource,
  TMovement,
  TMovementGroup,
  TNullableString,
  TPicklists,
  TShipCode,
  TTicketInputAdd,
  TVolumeInputAdd
} from "ticketing/ticketing.types";
import {
  equalsIgnoreCase,
  firstDayOfPrevMonth,
  getCancelledMovementStatusId,
  lastDayOfNextMonth,
  maxDates,
  minDates,
  toISODateString,
  toISODateTimeString,
  transformMovements,
  trim
} from "ticketing/utils";
import { findMatchingVolume } from "../movements/volume_util";
import {
  BULK_IMPORT_FIELDS as FIELDS,
  TAllRefData,
  TBulkImportTicket,
  TBulkImportTicketPdfFileField,
  TBulkImportTicketRefField,
  TFieldConfig,
  TRefData,
  TTicketSaveResult
} from "./types";
import { validateTicketVolume } from "./validations";

export const getStartDate = (startDate?: Date | null) => {
  return firstDayOfPrevMonth(!startDate ? new Date() : startDate);
};

export const getEndDate = (endDate?: Date | null) => {
  return lastDayOfNextMonth(!endDate ? new Date() : endDate);
};

export const getMovementsFilter = (tickets: TBulkImportTicket[], picklists?: TPicklists) => {
  const startDate = getStartDate(minDates(...tickets.map(t => t.ticketdate as Date)));
  const endDate = getEndDate(maxDates(...tickets.map(t => t.ticketdate as Date)));
  const deliveryIds = tickets.map(t => t.deliveryid?.initial).filter(Boolean);

  return {
    startDate: { goe: toISODateString(startDate) },
    endDate: { loe: toISODateString(endDate) },
    enterpriseSystemId: picklists?.enterpriseSystemId,
    enterpriseSystemCode: {
      in: deliveryIds?.length ? deliveryIds : ["0000000000"]
    },
    statusId: { ne: getCancelledMovementStatusId(picklists?.movementStatuses) }
  };
};

export const getVolSeq = (value: string) => {
  const matches = value.match(/\d+/);
  return matches != null ? matches[0] : "";
};

export const getRefNames = (tickets: TBulkImportTicket[], refName: string): string[] => {
  const names = new Set(
    tickets
      .filter(t => !!t[refName])
      .map(t => (t[refName] as TBulkImportTicketRefField).initial)
      .filter((n): n is string => Boolean(n))
  );
  //send empty string when we dont have any valid values so that backend can execute query
  return names && names.size > 0 ? [...names] : [""];
};

export const lookupMovements = (
  movements: TMovement[] | undefined,
  deliveryId: TNullableString
): TMovementGroup | null => {
  if (
    Array.isArray(movements) &&
    movements.length &&
    deliveryId &&
    typeof deliveryId === "string"
  ) {
    const movement = movements.find(r => r.enterpriseSystemCode === deliveryId);
    if (movement) {
      return {
        groupId: movement.groupId,
        movements: movements.filter(m => m.groupId === movement.groupId),
        activeMovement: movement
      };
    }
  }
  return null;
};

export const lookupShipCode = (shipCodes: TShipCode[] | undefined, code: TNullableString) => {
  if (
    Array.isArray(shipCodes) &&
    shipCodes.length > 0 &&
    code != null &&
    typeof code === "string"
  ) {
    const possibleShipCodes = shipCodes.filter(r => equalsIgnoreCase(r.code, code));
    return possibleShipCodes?.length === 1 ? possibleShipCodes[0] : undefined;
  }
  return null;
};

export const lookupBatchRefData = (
  batches: TBatch[] | undefined,
  batchName: TNullableString,
  modeOfTransport: TNullableString
) => {
  if (
    Array.isArray(batches) &&
    batches.length > 0 &&
    batchName != null &&
    typeof batchName === "string"
  ) {
    const matchingBatches = batches.filter(
      batch =>
        equalsIgnoreCase(batch.name, batchName) &&
        (modeOfTransport == null ||
          equalsIgnoreCase(modeOfTransport, batch?.modeOfTransport?.name))
    );
    if (matchingBatches?.length === 1) {
      return matchingBatches[0];
    }
  }
  return null;
};

export const lookupRefData = (refData: TRefData[] | undefined, name: TNullableString) => {
  if (
    Array.isArray(refData) &&
    refData.length > 0 &&
    name != null &&
    typeof name === "string"
  ) {
    const found = refData.filter(r => equalsIgnoreCase(r.name, name));
    if (found?.length === 1) {
      return found[0];
    }
  }
  return null;
};

export const localTransformMovements = (movements?: TMovement[]) => {
  if (movements) {
    const localCopy = transformMovements(movements);
    //multi column combox box doesn't format dates, hence adding scheduleDate
    localCopy.forEach(m =>
      Object.assign(m, { scheduleDate: toKString(m.startDate, "MM/dd/yyyy") })
    );
    return localCopy;
  }
  return [];
};

const toVolumeInput = (t: TBulkImportTicket, volumeGroups: string[]): TVolumeInputAdd[] => {
  return volumeGroups
    .filter(v => validateTicketVolume(t, v)?.length === 0)
    .map((v, i) => ({
      sequence: i + 1,
      netVolume: t[`volume${v}net`],
      grossVolume: t[`volume${v}gross`],
      unitOfMeasureId: t[`volume${v}uom`]?.value?.id,
      temperature: t[`volume${v}temp`],
      temperatureUnitOfMeasure: t[`volume${v}tempuom`]?.value?.id
    }));
};

const findDocumentIdByFileName = (
  fileName?: TBulkImportTicketPdfFileField | null,
  documents?: TDocumentUploadResult[] | null
) => {
  const filename = fileName?.value?.name;
  if (filename && documents) {
    return documents.find(r => r?.fileName.endsWith(filename))?.id?.toString();
  }
  return undefined;
};

const parseRailcars = (railcars?: string | null) => {
  const tRailcars = trim(railcars);
  if (tRailcars) {
    return tRailcars.split(",").map(r => ({ railcarNumber: r }));
  }
  return undefined;
};

export const toTicketInput = (
  t: TBulkImportTicket,
  volumeGroups: string[],
  documents?: TDocumentUploadResult[] | null
): TTicketInputAdd => {
  const ticketInput: TTicketInputAdd = {
    ticketNumber: t.ticketnumber,
    startDate: toISODateTimeString(t.ticketdate as Date),
    endDate: toISODateTimeString(t.ticketdate as Date),
    batchId: t.batchname?.value?.id as string,
    productId: t.productname?.value?.id as number,
    facilityId: t.locationname?.value?.id as string,
    carrierId: t.carriername?.value?.id as string,
    logisticsSystemId: t.logisticsname?.value?.id as number,
    modeOfTransportId: t.modeoftransport?.value?.id as number,
    shipFromCodeId: t.shipfromcode?.value?.id,
    shipToCodeId: t.shiptocode?.value?.id,
    source: TicketSource.Import,
    pageNumbers: trim(t.pagerange),
    invoiceComment: trim(t.comments),
    borderCrossingDate:
      t.bordercrossingdate != null
        ? toISODateTimeString(t.bordercrossingdate as Date)
        : undefined,
    poNumber: trim(t.ponumber),
    documentId: findDocumentIdByFileName(t.filename, documents),
    volumes: toVolumeInput(t, volumeGroups),
    railcars: parseRailcars(t.railcars),
    extSourceTicketRef: t.KEY
  };
  return ticketInput;
};

export const toLinkTicketInputs = (
  tickets: TBulkImportTicket[],
  savedTickets: TTicketSaveResult[],
  picklists?: TPicklists
) => {
  //get all distinct movement group Ids...for valid tickets
  const distGroupId = new Set(
    tickets
      .filter(t => (t.errors?.length ?? 0) === 0)
      .filter(t => t.deliveryid?.value?.groupId)
      .map(t => t.deliveryid?.value?.groupId)
  );

  //link tickets to movements
  return [...distGroupId]
    .map(gId => {
      const ticketsOfGroup = tickets
        .filter(t => (t.errors?.length ?? 0) === 0)
        .filter(t => t.deliveryid?.value?.groupId === gId);
      const movementGroup = ticketsOfGroup.at(0)?.deliveryid?.value;
      if (movementGroup) {
        const movementsOfGroup = movementGroup?.movements?.map(m => ({
          movementId: m.id,
          version: m.version
        }));

        const ticketInputs = savedTickets
          .filter(st => ticketsOfGroup.find(t => st.extSourceTicketRef === t.KEY))
          .map(st => ({
            ticketId: st?.id,
            volume: findMatchingVolume(st, movementGroup, picklists?.uomConversions).netVolume
          }));

        if (ticketInputs.length) {
          return {
            movements: movementsOfGroup,
            tickets: ticketInputs
          };
        }
      }
      return null;
    })
    .filter(Boolean);
};
type TMergeField = TBatch | TRefData | TMovement;
export const merge = (
  a: TMergeField[] | undefined,
  b: TMergeField[] | undefined,
  predicate = (aItem: TMergeField, bItem: TMergeField) => aItem === bItem
) => {
  const c = [...(a ?? [])]; // copy to avoid side effects
  // add all items from B to copy C if they're not already present
  b?.forEach(bItem => (c.some(cItem => predicate(bItem, cItem)) ? null : c.push(bItem)));
  return c;
};

export const transformRefData = (data: TAllRefData): TAllRefData => {
  return {
    ...data,
    movements: localTransformMovements(data.movements),
    batches: merge(
      data.batches,
      data.movements?.map(m => m.batch),
      (a, b) => a.id === b.id
    ) as TBatch[],
    products: merge(
      data.products,
      data.movements?.map(m => m.product),
      (a, b) => a.id === b.id
    ) as TRefData[],
    facilities: merge(
      data.facilities,
      data.movements?.map(m => m.titleTransferFacility),
      (a, b) => a.id === b.id
    ) as TRefData[]
  };
};

export const onTicketFieldUpdated = (ticket: TBulkImportTicket, fieldName: string) => {
  if (fieldName === FIELDS.DELIVERY_ID_FIELD_NAME) {
    const movement = ticket.deliveryid?.value;
    if (movement) {
      Object.assign(ticket, {
        batchname: {
          ...ticket.batchname,
          value: movement.activeMovement.batch
        },
        productname: {
          ...ticket.productname,
          value: movement?.activeMovement?.product
        },
        locationname: {
          ...ticket.locationname,
          value: movement?.activeMovement?.titleTransferFacility
        }
      });
    }
  }
};

type BulkImportContextProps = {
  fieldConfig?: TFieldConfig[];
  refData?: TAllRefData;
};

export const BulkImportContext = createContext<BulkImportContextProps>({});
