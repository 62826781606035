const InlineLoadingPanel = (props: { message?: string }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <span className={"k-color-primary"}>
        <span className={"k-icon k-i-loading k-color-primary"}></span>
        {props.message && <span>{props.message}</span>}
      </span>
    </div>
  );
};
export default InlineLoadingPanel;
