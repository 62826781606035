import { useMutation, useQuery } from "@apollo/client";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { addDays, Day, firstDayInWeek, nextDayOfWeek } from "@progress/kendo-date-math";
import {
  Badge,
  BaseTable,
  Loading,
  Sentiments,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  ChevronDown,
  ChevronRight,
  MailClosedSolid,
  MailOpen,
  Search
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  ColumnDef,
  getPaginationRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState
} from "@tanstack/react-table";
import { ROWS_PER_PAGE } from "carbonIQ/CarbonIQConstants";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import CarbonIQEmptyTable from "carbonIQ/emptyTable";
import dayjs from "dayjs";
import ColumnText from "global/elements/columnText";
import GlobalHeader from "global/sections/header";
import { loader } from "graphql.macro";
import { useState } from "react";
import { GqlResponse } from "types";
import { ChronoUnit, INotification, INotificationSearchCriteria } from "../models";
import NotificationSearchModal from "./notificationSearchModal";

const GET_NOTIFICATIONS = loader("../graphql/query-notifications.graphql");
const MARK_NOTIFICATION_AS_READ = loader("../graphql/mutation-mark-notification-read.graphql");
const FETCH_POLICY_NO_CACHE = { fetchPolicy: "no-cache" as MutationFetchPolicy };
type TNotificationsResponse = GqlResponse<INotification[], "notificationsFilterBy">;

export const convertToUTCISODate = (fullDate: Date | null): string => {
  const utcDateString = fullDate!.toISOString();
  return utcDateString.substring(0, utcDateString.indexOf("."));
};

const initSearchCriteria = () => {
  return {
    startDate: firstDayInWeek(new Date(), Day.Monday),
    endDate: nextDayOfWeek(new Date(), Day.Sunday),
    goToType: ChronoUnit.Week
  };
};

const dayJSFormat = (args: string) => {
  return dayjs(args).format("M/D/YYYY[, ]h:mm:ss A");
};

const gridColumns: ColumnDef<INotification>[] = [
  {
    header: "ID",
    accessorKey: "id",
    enableSorting: true,
    enableResizing: false,
    cell: arg => <ColumnText width={"70px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Source",
    accessorKey: "notifier.serviceName",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText width={"200px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Title",
    accessorKey: "title",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText width={"200px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Send Date",
    accessorKey: "createdDate",
    enableResizing: false,
    enableSorting: true,
    cell: arg => (
      <ColumnText width={"200px"}>{dayJSFormat((arg.getValue() as string) + "Z")}</ColumnText>
    )
  }
];

const GetCollapsableRowDetail = ({
  rowData,
  markAsRead
}: {
  rowData: INotification;
  markAsRead: (item: INotification) => void;
}) => {
  return (
    <p className={"row-message-wrap " + (rowData.expand ? "open" : "")}>
      <button
        onClick={() => {
          markAsRead(rowData);
        }}
        className="clickable-row"
      />
      <span className="chevrom-wrap">
        <ChevronRight className="chevron-right" />
        <ChevronDown className="chevron-down" />
      </span>

      <span className={"row-message"}>{rowData.message}</span>
      {rowData.read ? <MailOpen /> : <MailClosedSolid />}
    </p>
  );
};

const gridColumnsGenerate = ({ markAsRead }: { markAsRead: (item: INotification) => void }) => {
  const additionalGridColumn: ColumnDef<INotification> = {
    header: "",
    accessorKey: "read",
    enableSorting: false,
    enableResizing: false,
    cell: arg => <GetCollapsableRowDetail rowData={arg.row.original} markAsRead={markAsRead} />
  };
  return [additionalGridColumn, ...gridColumns];
};

const Notification = () => {
  const [searchCriteria, setSearchCriteria] = useState<INotificationSearchCriteria>(() =>
    initSearchCriteria()
  );
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [notificationsDataState, setNotificationsDataState] = useState<INotification[]>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({}); //manage your own row selection state

  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ, {});

  const markRead = async (isRead: boolean = true, itemId?: number | string) => {
    if (itemId) {
      markNotificationAsRead({
        variables: { notificationId: itemId.toString(), isRead }
      });
    }
  };

  const { loading, error, refetch } = useQuery<TNotificationsResponse>(GET_NOTIFICATIONS, {
    ...FETCH_POLICY_NO_CACHE,
    onCompleted: data => {
      setNotificationsDataState(data?.notificationsFilterBy);
    },
    variables: {
      fromDate: convertToUTCISODate(addDays(searchCriteria.startDate, -1)),
      toDate: convertToUTCISODate(searchCriteria.endDate)
    }
  });

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination,
      rowSelection
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: false
  };

  const markAsReadHandler = (item: INotification) => {
    if (!item.read) {
      markRead(true, item.id);
    }

    const expandedData = notificationsDataState?.map(elm => {
      if (elm.id === item.id) {
        return { ...elm, read: true, expand: !item.expand };
      }
      return elm;
    });

    setNotificationsDataState(expandedData);
  };

  const columns = gridColumnsGenerate({ markAsRead: markAsReadHandler });

  const handleSearchSubmit = (data: INotificationSearchCriteria) => {
    setSearchOpen(!searchOpen);
    setSearchCriteria(data);
    const fromDate = convertToUTCISODate(addDays(data?.startDate, -1));
    const toDate = convertToUTCISODate(data.endDate);
    refetch({ fromDate, toDate });
  };

  const isLoading = [loading].some(elm => elm);

  return (
    <>
      {isLoading && (
        <div className="loading-wrapper">
          <Loading />
        </div>
      )}
      <GlobalHeader
        pageName="Notifications"
        buttonContent={[
          <Badge
            key={1}
            icon={<Search />}
            sentiment={Sentiments.Information}
            variant={Variants.Filled}
            onClick={() => setSearchOpen(!searchOpen)}
          />
        ]}
      />

      <CommonErrorComponent error={error} />
      <div id="Notification" className="notification-page">
        {notificationsDataState && notificationsDataState.length > 0 ? (
          <BaseTable
            columns={columns}
            data={notificationsDataState}
            className="carboniq-data-table clickable-row-table"
            tableOptions={tableOptions}
          />
        ) : (
          <CarbonIQEmptyTable />
        )}

        {searchOpen && (
          <NotificationSearchModal
            onClose={() => setSearchOpen(!searchOpen)}
            onSubmit={(data: INotificationSearchCriteria) => {
              handleSearchSubmit(data);
            }}
          />
        )}
      </div>
    </>
  );
};
export default Notification;
