import { useMutation, useQuery } from "@apollo/client";
import {
  Checkbox,
  Drawer,
  FormField,
  Heading,
  Loading,
  Select,
  Sentiments,
  Sizes,
  TextInput,
  TextTypes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { TCIQOption, TGenericProductsFilter, TProductsFetchData } from "carbonIQ/carbonIQtypes";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import { loader } from "graphql.macro";
import { useState } from "react";
import { GqlResponse } from "types";

const carbonGenericProductsFilterBy = loader(
  "../graphql/query-CarbonGenericProductsBlendFilterBy.graphql"
);

const ciAddNewProduct = loader("../graphql/mutation-CarbonAddNewProduct.graphql");

const ciUpdateProduct = loader("../graphql/mutation-CarbonUpdateProduct.graphql");

const FETCH_POLICY_NO_CACHE = "no-cache";

type TGenericProductsFilterByResponse = GqlResponse<
  TGenericProductsFilter[],
  "ciGenericProductsFilterBy"
>;

const AddOrUpdateProducts = ({
  details,
  onClose,
  onSubmit
}: {
  details: TProductsFetchData;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  const [open, setOpen] = useState(true);
  const handleOnClose = () => {
    onSubmit();
    onClose();
    setOpen(false);
  };

  const [genericProductList, setGenericProductList] = useState<TCIQOption[]>([]);
  const [loading, setLoading] = useState(false);

  // Fetch Generic Products
  const { loading: lCIQDocument, error: eCIQDocument } =
    useQuery<TGenericProductsFilterByResponse>(carbonGenericProductsFilterBy, {
      fetchPolicy: FETCH_POLICY_NO_CACHE,
      onCompleted: data => {
        setGenericProductList(
          data.ciGenericProductsFilterBy.map((prod: TGenericProductsFilter) => ({
            value: prod.name,
            label: prod.name,
            key: prod.id
          }))
        );
      },
      onError: () => {
        setLoading(false);
      }
    });

  // Add New Product
  const [addNewProduct, { loading: addProductLoading, error: addProductError }] = useMutation(
    ciAddNewProduct,
    {
      fetchPolicy: FETCH_POLICY_NO_CACHE,
      onCompleted: () => handleOnClose(),
      onError: () => {
        setLoading(false);
      }
    }
  );

  // Update New Product
  const [updateProduct, { loading: updateProductLoading, error: updateProductError }] =
    useMutation(ciUpdateProduct, {
      fetchPolicy: FETCH_POLICY_NO_CACHE,
      onCompleted: () => handleOnClose(),
      onError: () => {
        setLoading(false);
      }
    });

  const isStateAdd: boolean = !details?.id;

  const [preFillProductDetails, setPreFillProductDetails] = useState<TProductsFetchData>(
    isStateAdd ? { active: true } : details
  );

  const isLoading = [loading, lCIQDocument, updateProductLoading, addProductLoading].some(
    elm => elm
  );

  const onSubmitClick = () => {
    setLoading(true);
    const tempdata = {
      active: !!preFillProductDetails.active,
      genericProductId: preFillProductDetails.genericProduct?.id,
      gsapCode: preFillProductDetails.gsapCode?.trim(),
      gsapName: preFillProductDetails.gsapName?.trim()
    };
    isStateAdd
      ? addNewProduct({
          variables: {
            product: tempdata
          }
        })
      : updateProduct({
          variables: {
            product: {
              ...tempdata,
              id: preFillProductDetails.id,
              version: preFillProductDetails.version
            }
          }
        });
  };

  return (
    <Drawer
      header={
        <Heading className="setting-form-heading" type={TextTypes.H2}>
          {(isStateAdd ? "Add New" : "Update") + " Product"}
        </Heading>
      }
      borders={false}
      closeButton={false}
      sticky
      mask={true}
      open={open}
      size={Sizes.Medium}
      onClose={() => {
        handleOnClose();
      }}
      actions={[
        {
          label: "Cancel",
          action: () => {
            handleOnClose();
          },
          props: {
            variant: Variants.Outlined
          }
        },
        {
          label: "Save",
          action: () => {
            setLoading(true);
            onSubmitClick();
          },
          props: {
            disabled:
              !preFillProductDetails?.genericProduct ||
              !preFillProductDetails?.gsapCode ||
              !preFillProductDetails?.gsapName
          }
        }
      ]}>
      <CommonErrorComponent error={[addProductError, updateProductError, eCIQDocument]} />

      {!isStateAdd && <p>Updating product - Some Warrning message</p>}

      <form>
        <FormField
          size={"medium"}
          id="gsap-code-label"
          mandatory={true}
          label="GSAP Code"
          bottomLeftHelper={{
            content: !preFillProductDetails?.gsapCode ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              setPreFillProductDetails({
                ...preFillProductDetails,
                gsapCode: event.target.value.trim()
              });
            }}
            value={preFillProductDetails?.gsapCode ?? ""}
            invalid={!preFillProductDetails?.gsapCode}
          />
        </FormField>
        <FormField
          size={"medium"}
          id="gsap-name-label"
          label="GSAP Name"
          mandatory={true}
          bottomLeftHelper={{
            content: !preFillProductDetails?.gsapName ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              setPreFillProductDetails({
                ...preFillProductDetails,
                gsapName: event?.target?.value
              });
            }}
            value={preFillProductDetails?.gsapName ?? ""}
            invalid={!preFillProductDetails?.gsapName}
          />
        </FormField>
        <FormField
          size={"medium"}
          id="generic-product-name-label"
          label="Generic Product Name"
          mandatory={true}
          bottomLeftHelper={{
            content: !preFillProductDetails?.genericProduct ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <Select
            options={genericProductList}
            size={"medium"}
            invalid={!preFillProductDetails?.genericProduct}
            id="generic-product-name-input"
            placeholder="Select a product"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            labelInValue={true}
            value={preFillProductDetails?.genericProduct?.name ?? undefined}
            onChange={data => {
              setPreFillProductDetails({
                ...preFillProductDetails,
                genericProduct: !data ? data : { id: data.key, name: data.value }
              });
            }}
          />
        </FormField>
        <Checkbox
          label="Active"
          size={Sizes.Large}
          checked={preFillProductDetails?.active}
          onChange={() => {
            setPreFillProductDetails({
              ...preFillProductDetails,
              active: !preFillProductDetails?.active
            });
          }}
        />
      </form>
      {isLoading && (
        <div className="loading-wrapper">
          <Loading />
        </div>
      )}
    </Drawer>
  );
};

export default AddOrUpdateProducts;
