import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useState } from "react";
import {
  GqlResponse,
  TAlertsOfMovementAtRevision,
} from "ticketing/ticketing.types";
import RevisionsGridWrapper from "./RevisionsGridWrapper";

type Response = GqlResponse<
  TAlertsOfMovementAtRevision[],
  "alertsOfMovementAtRevision"
>;
const GET_ALERT_REVISIONS_OF_MOVEMENT = loader(
  "../../ticketing-graphql/alertsOfMovementAtRevision.graphql"
);

const DescriptionCell = (props: any) => {
  return <td title={props.dataItem.desc}>{props.dataItem.desc}</td>;
};

type RevisionsMovementAlertsProps = {
  movementId: string;
  revisionNumber: string;
};

const childData = [
  { title: "Category", field: "category", width: "140px", key: 1 },
  { title: "Code", field: "code", width: "140px", key: 2 },
  {
    title: "Description",
    field: "desc",
    width: "300px",
    cell: DescriptionCell,
    key: 3
  },
];

const RevisionsMovementAlerts = ({
  movementId,
  revisionNumber,
}: RevisionsMovementAlertsProps) => {
  const { loading, error } = useQuery<Response>(
    GET_ALERT_REVISIONS_OF_MOVEMENT,
    {
      fetchPolicy: "no-cache",
      variables: { movementId, revisionNumber },
      onCompleted: (data) => setAlerts(data.alertsOfMovementAtRevision),
    }
  );
  const [alerts, setAlerts] = useState<TAlertsOfMovementAtRevision[]>([]);

  return (
    <RevisionsGridWrapper
      loading={loading}
      error={error}
      dataContent={alerts}
      columns={childData}
    />
  );
};

export default RevisionsMovementAlerts;
